<template>
    <div>
        <div id='write' class='is-node'>
            <h1><a name="接入指南" class="md-header-anchor"></a><span>接入指南</span></h1>
            <h2><a name="简介" class="md-header-anchor"></a><span>简介</span></h2>
            <p><span>尊敬的用户，欢迎使用至信链司法存证开放平台。以下内容，是您接入服务的一些基本介绍，希望您能够方便、快捷的接入该平台，实现基于区块链的司法业务。</span></p>
            <h2><a name="名词解释" class="md-header-anchor"></a><span>名词解释</span></h2>
            <ul>
                <li><strong><span>AppID与AppKey</span></strong></li>
            </ul>
            <p><span>主要用于对用户的调用行为鉴权和认证，相当于至信链API专用的用户名及密码。</span></p>
            <ul>
                <li><strong><span>公钥（Public Key）与私钥（Private Key）</span></strong></li>
            </ul>
            <p><span>是通过一种算法得到的一个密钥对（即一个公钥和一个私钥），公钥是密钥对中公开的部分，私钥则是非公开的部分。至信链区块链平台，使用公钥与AppID绑定，并使用私钥签名存证数据，进行用户身份的链上认证。</span></p>
            <ul>
                <li><strong><span>区块链</span></strong></li>
            </ul>
            <p><span>分布式数据存储、点对点传输、共识机制、加密算法等计算机技术的新型应用模式。具有验证数据有效性，数据不可篡改等特点。</span></p>
            <ul>
                <li><strong><span>散列算法（Hash算法）</span></strong></li>
            </ul>
            <p><span>把任意长度的输入通过散列算法变换成固定长度的输出，该输出就是散列值。该散列值与元数据一一对应，且很难找到逆向规律。</span></p>
            <ul>
                <li><strong><span>数字签名</span></strong></li>
            </ul>
            <p><span>是一种类似写在纸上的普通的物理签名，但是使用了公钥加密领域的技术实现，用于鉴别数字信息的方法。</span></p>
            <ul>
                <li><strong><span>存证ID</span></strong></li>
            </ul>
            <p><span>由企业或SDK产生的存证唯一标识。</span></p>
            <ul>
                <li><strong><span>存证文件Hash</span></strong></li>
            </ul>
            <p><span>上链存证的文件由散列算法变换后输出的散列值。</span></p>
            <ul>
                <li><strong><span>交易Hash</span></strong></li>
            </ul>
            <p><span>由区块链平台产生的标记记账需要的字符串，至信链平台特指证据记链的凭证。</span></p>
            <h2><a name="接入指南-n37" class="md-header-anchor"></a><span>接入指南</span></h2>
            <ol start=''>
                <li><span>填写相关信息，注册认证成为企业用户，联系管理员进行审核;</span></li>
            </ol>
            <p><span>  </span><img src="./接入步骤.png" referrerpolicy="no-referrer" alt="attr">
                <span>    1.1 首页点击注册用户；</span>
                <span>      </span><img src="./接入指南注册1.png" referrerpolicy="no-referrer" alt="attr">
                <span>    1.2 填写邮箱信息，设置登录密码；</span>
                <span>      </span><img src="./接入指南注册2.png" referrerpolicy="no-referrer" alt="attr">
                <span>    1.3 填写企业实名信息，特殊机构如无法通过认证，请联络管理员进行审核；</span>
                <span>      </span><img src="./接入指南注册3.png" referrerpolicy="no-referrer" alt="attr">
                <span>    1.4. 填写申请人实名信息；</span>
                <span>      </span><img src="./接入指南注册4.png" referrerpolicy="no-referrer" alt="attr">
                <span>    1.5. 提交审核。</span>
                <span>      </span><img src="./接入指南注册5.png" referrerpolicy="no-referrer" alt="attr"></p>
            <ol start='2'>
                <li>
                    <p><span>联系运营人员或管理员对接入进行审核，审核通过后，您可进行身份绑定，获取APPID和APPKEY；</span></p>
                    <p><img src="./接入步骤2.png" referrerpolicy="no-referrer" alt="attr">
                        <img src="./接入步骤2-1.png" referrerpolicy="no-referrer" alt="attr">
                        <span> 2.1 绑定公钥，用户可自行选择如下一种方案进行绑定：</span>
                        <span>   2.1.1 下载开发者工具（至信链公私钥生成器），输入AppID，生成公私钥与签名；</span>
                        <span>     </span><img src="./绑定公钥1.png" referrerpolicy="no-referrer" alt="attr">
                        <span>   2.1.2 通过“公钥绑定”页面拷贝公钥与签名至指定栏，点击“绑定”，私钥请用户妥善保管并注意不要外泄；</span>
                        <span>     </span><img src="./绑定公钥2.png" referrerpolicy="no-referrer" alt="attr">
                        <span>   2.1.3 绑定成功，用户可根据自身需求选择重新绑定公钥、签名；</span>
                        <span>     </span><img src="./绑定公钥3.png" referrerpolicy="no-referrer" alt="attr">
                        <span>   2.1.4 用户也可以通过JAVA-SDK的公私钥生成方法，生成公私钥，并通过2.1.2公钥绑定方法，绑定公钥，该部分请参考JAVA-SDK接入文档。</span></p>
                </li>
                <li>
                    <p><span>用户可选择钱包→充值或联络至信链团队进行采购，获取至信链相关服务。</span></p>
                    <p><img src="./接入步骤3.png" referrerpolicy="no-referrer" alt="attr">
                        <img src="./购买.png" referrerpolicy="no-referrer" alt="attr"></p>
                </li>
                <li>
                    <p><span>用户可在开发方法工具下载中，找到相关SDK文件启动开发</span></p>
                    <p><img src="./工具包下载.png" referrerpolicy="no-referrer" alt="attr"></p>
                </li>
            </ol>
        </div>
    
    
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {

        }
    },
    computed: {},
    mounted() {},
    methods: {

    }
}
</script>

<style lang='scss' scoped>
html {
    overflow-x: initial !important;
}

:root {
    --bg-color: #ffffff;
    --text-color: #333333;
    --select-text-bg-color: #B5D6FC;
    --select-text-font-color: auto;
    --monospace: "Lucida Console", Consolas, "Courier", monospace;
}

html {
    font-size: 14px;
    background-color: var(--bg-color);
    color: var(--text-color);
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
}

body {
    margin: 0px;
    padding: 0px;
    height: auto;
    bottom: 0px;
    top: 0px;
    left: 0px;
    right: 0px;
    font-size: 1rem;
    line-height: 1.42857;
    overflow-x: hidden;
    background: inherit;
    tab-size: 4;
}

iframe {
    margin: auto;
}

a.url {
    word-break: break-all;
}

a:active,
a:hover {
    outline: 0px;
}

.in-text-selection,
::selection {
    // text-shadow: none;
    // background: var(--select-text-bg-color);
    // color: var(--select-text-font-color);
}

#write {
    margin: 0px auto;
    height: auto;
    width: inherit;
    word-break: normal;
    overflow-wrap: break-word;
    position: relative;
    white-space: normal;
    overflow-x: visible;
    padding-top: 40px;
}

#write.first-line-indent p {
    text-indent: 2em;
}

#write.first-line-indent li p,
#write.first-line-indent p * {
    text-indent: 0px;
}

#write.first-line-indent li {
    margin-left: 2em;
}

.for-image #write {
    padding-left: 8px;
    padding-right: 8px;
}

body.typora-export {
    padding-left: 30px;
    padding-right: 30px;
}

.typora-export .footnote-line,
.typora-export li,
.typora-export p {
    white-space: pre-wrap;
}

@media screen and (max-width: 500px) {
    body.typora-export {
        padding-left: 0px;
        padding-right: 0px;
    }
    #write {
        padding-left: 20px;
        padding-right: 20px;
    }
    .CodeMirror-sizer {
        margin-left: 0px !important;
    }
    .CodeMirror-gutters {
        display: none !important;
    }
}

#write li>figure:last-child {
    margin-bottom: 0.5rem;
}

#write ol,
#write ul {
    position: relative;
}

img {
    max-width: 100%;
    vertical-align: middle;
}

button,
input,
select,
textarea {
    color: inherit;
    font: inherit;
}

input[type="checkbox"],
input[type="radio"] {
    line-height: normal;
    padding: 0px;
}

*,
::after,
::before {
    box-sizing: border-box;
}

#write h1,
#write h2,
#write h3,
#write h4,
#write h5,
#write h6,
#write p,
#write pre {
    width: inherit;
}

#write h1,
#write h2,
#write h3,
#write h4,
#write h5,
#write h6,
#write p {
    position: relative;
}

p {
    line-height: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    break-after: avoid-page;
    break-inside: avoid;
    orphans: 2;
}

p {
    orphans: 4;
}

h1 {
    font-size: 2rem;
}

h2 {
    font-size: 1.8rem;
}

h3 {
    font-size: 1.6rem;
}

h4 {
    font-size: 1.4rem;
}

h5 {
    font-size: 1.2rem;
}

h6 {
    font-size: 1rem;
}

.md-math-block,
.md-rawblock,
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.hidden {
    display: none;
}

.md-blockmeta {
    color: rgb(204, 204, 204);
    font-weight: 700;
    font-style: italic;
}

a {
    cursor: pointer;
}

sup.md-footnote {
    padding: 2px 4px;
    background-color: rgba(238, 238, 238, 0.7);
    color: rgb(85, 85, 85);
    border-radius: 4px;
    cursor: pointer;
}

sup.md-footnote a,
sup.md-footnote a:hover {
    color: inherit;
    text-transform: inherit;
    text-decoration: inherit;
}

#write input[type="checkbox"] {
    cursor: pointer;
    width: inherit;
    height: inherit;
}

figure {
    overflow-x: auto;
    margin: 1.2em 0px;
    max-width: calc(100% + 16px);
    padding: 0px;
}

figure>table {
    margin: 0px !important;
}

tr {
    break-inside: avoid;
    break-after: auto;
}

thead {
    display: table-header-group;
}

table {
    border-collapse: collapse;
    border-spacing: 0px;
    width: 100%;
    overflow: auto;
    break-inside: auto;
    text-align: left;
}

table.md-table td {
    min-width: 32px;
}

.CodeMirror-gutters {
    border-right: 0px;
    background-color: inherit;
}

.CodeMirror-linenumber {
    user-select: none;
}

.CodeMirror {
    text-align: left;
}

.CodeMirror-placeholder {
    opacity: 0.3;
}

.CodeMirror pre {
    padding: 0px 4px;
}

.CodeMirror-lines {
    padding: 0px;
}

div.hr:focus {
    cursor: none;
}

#write pre {
    white-space: pre-wrap;
}

#write.fences-no-line-wrapping pre {
    white-space: pre;
}

#write pre.ty-contain-cm {
    white-space: normal;
}

.CodeMirror-gutters {
    margin-right: 4px;
}

.md-fences {
    font-size: 0.9rem;
    display: block;
    break-inside: avoid;
    text-align: left;
    overflow: visible;
    white-space: pre;
    background: inherit;
    position: relative !important;
}

.md-diagram-panel {
    width: 100%;
    margin-top: 10px;
    text-align: center;
    padding-top: 0px;
    padding-bottom: 8px;
    overflow-x: auto;
}

#write .md-fences.mock-cm {
    white-space: pre-wrap;
}

.md-fences.md-fences-with-lineno {
    padding-left: 0px;
}

#write.fences-no-line-wrapping .md-fences.mock-cm {
    white-space: pre;
    overflow-x: auto;
}

.md-fences.mock-cm.md-fences-with-lineno {
    padding-left: 8px;
}

.CodeMirror-line,
twitterwidget {
    break-inside: avoid;
}

.footnotes {
    opacity: 0.8;
    font-size: 0.9rem;
    margin-top: 1em;
    margin-bottom: 1em;
}

.footnotes+.footnotes {
    margin-top: 0px;
}

.md-reset {
    margin: 0px;
    padding: 0px;
    border: 0px;
    outline: 0px;
    vertical-align: top;
    background: 0px 0px;
    text-decoration: none;
    text-shadow: none;
    float: none;
    position: static;
    width: auto;
    height: auto;
    white-space: nowrap;
    cursor: inherit;
    -webkit-tap-highlight-color: transparent;
    line-height: normal;
    font-weight: 400;
    text-align: left;
    box-sizing: content-box;
    direction: ltr;
}

li div {
    padding-top: 0px;
}

blockquote {
    margin: 1rem 0px;
}

li .mathjax-block,
li p {
    margin: 0.5rem 0px;
}

li {
    margin: 0px;
    position: relative;
}

blockquote> :last-child {
    margin-bottom: 0px;
}

blockquote> :first-child,
li> :first-child {
    margin-top: 0px;
}

.footnotes-area {
    color: rgb(136, 136, 136);
    margin-top: 0.714rem;
    padding-bottom: 0.143rem;
    white-space: normal;
}

#write .footnote-line {
    white-space: pre-wrap;
}

@media print {
    body,
    html {
        border: 1px solid transparent;
        height: 99%;
        break-after: avoid;
        break-before: avoid;
    }
    #write {
        margin-top: 0px;
        padding-top: 0px;
        border-color: transparent !important;
    }
    .typora-export * {
        -webkit-print-color-adjust: exact;
    }
    html.blink-to-pdf {
        font-size: 13px;
    }
    .typora-export #write {
        padding-left: 32px;
        padding-right: 32px;
        padding-bottom: 0px;
        break-after: avoid;
    }
    .typora-export #write::after {
        height: 0px;
    }
}

.footnote-line {
    margin-top: 0.714em;
    font-size: 0.7em;
}

a img,
img a {
    cursor: pointer;
}

pre.md-meta-block {
    font-size: 0.8rem;
    min-height: 0.8rem;
    white-space: pre-wrap;
    background: rgb(204, 204, 204);
    display: block;
    overflow-x: hidden;
}

p>.md-image:only-child:not(.md-img-error) img,
p>img:only-child {
    display: block;
    margin: auto;
}

p>.md-image:only-child {
    display: inline-block;
    width: 100%;
}

#write .MathJax_Display {
    margin: 0.8em 0px 0px;
}

.md-math-block {
    width: 100%;
}

.md-math-block:not(:empty)::after {
    display: none;
}

[contenteditable="true"]:active,
[contenteditable="true"]:focus,
[contenteditable="false"]:active,
[contenteditable="false"]:focus {
    outline: 0px;
    box-shadow: none;
}

.md-task-list-item {
    position: relative;
    list-style-type: none;
}

.task-list-item.md-task-list-item {
    padding-left: 0px;
}

.md-task-list-item>input {
    position: absolute;
    top: 0px;
    left: 0px;
    margin-left: -1.2em;
    margin-top: calc(1em - 10px);
    border: none;
}

.math {
    font-size: 1rem;
}

.md-toc {
    min-height: 3.58rem;
    position: relative;
    font-size: 0.9rem;
    border-radius: 10px;
}

.md-toc-content {
    position: relative;
    margin-left: 0px;
}

.md-toc-content::after,
.md-toc::after {
    display: none;
}

.md-toc-item {
    display: block;
    color: rgb(65, 131, 196);
}

.md-toc-item a {
    text-decoration: none;
}

.md-toc-inner:hover {
    text-decoration: underline;
}

.md-toc-inner {
    display: inline-block;
    cursor: pointer;
}

.md-toc-h1 .md-toc-inner {
    margin-left: 0px;
    font-weight: 700;
}

.md-toc-h2 .md-toc-inner {
    margin-left: 2em;
}

.md-toc-h3 .md-toc-inner {
    margin-left: 4em;
}

.md-toc-h4 .md-toc-inner {
    margin-left: 6em;
}

.md-toc-h5 .md-toc-inner {
    margin-left: 8em;
}

.md-toc-h6 .md-toc-inner {
    margin-left: 10em;
}

@media screen and (max-width: 48em) {
    .md-toc-h3 .md-toc-inner {
        margin-left: 3.5em;
    }
    .md-toc-h4 .md-toc-inner {
        margin-left: 5em;
    }
    .md-toc-h5 .md-toc-inner {
        margin-left: 6.5em;
    }
    .md-toc-h6 .md-toc-inner {
        margin-left: 8em;
    }
}

a.md-toc-inner {
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
}

.footnote-line a:not(.reversefootnote) {
    color: inherit;
}

.md-attr {
    display: none;
}

.md-fn-count::after {
    content: ".";
}

code,
pre,
samp,
tt {
    font-family: var(--monospace);
}

kbd {
    margin: 0px 0.1em;
    padding: 0.1em 0.6em;
    font-size: 0.8em;
    color: rgb(36, 39, 41);
    background: rgb(255, 255, 255);
    border: 1px solid rgb(173, 179, 185);
    border-radius: 3px;
    box-shadow: rgba(12, 13, 14, 0.2) 0px 1px 0px, rgb(255, 255, 255) 0px 0px 0px 2px inset;
    white-space: nowrap;
    vertical-align: middle;
}

.md-comment {
    color: rgb(162, 127, 3);
    opacity: 0.8;
    font-family: var(--monospace);
}

code {
    text-align: left;
    vertical-align: initial;
}

a.md-print-anchor {
    white-space: pre !important;
    border-width: initial !important;
    border-style: none !important;
    border-color: initial !important;
    display: inline-block !important;
    position: absolute !important;
    width: 1px !important;
    right: 0px !important;
    outline: 0px !important;
    background: 0px 0px !important;
    text-decoration: initial !important;
    text-shadow: initial !important;
}

.md-inline-math .MathJax_SVG .noError {
    display: none !important;
}

.html-for-mac .inline-math-svg .MathJax_SVG {
    vertical-align: 0.2px;
}

.md-math-block .MathJax_SVG_Display {
    text-align: center;
    margin: 0px;
    position: relative;
    text-indent: 0px;
    max-width: none;
    max-height: none;
    min-height: 0px;
    min-width: 100%;
    width: auto;
    overflow-y: hidden;
    display: block !important;
}

.MathJax_SVG_Display,
.md-inline-math .MathJax_SVG_Display {
    width: auto;
    margin: inherit;
    display: inline-block !important;
}

.MathJax_SVG .MJX-monospace {
    font-family: var(--monospace);
}

.MathJax_SVG .MJX-sans-serif {
    font-family: sans-serif;
}

.MathJax_SVG {
    display: inline;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    zoom: 90%;
    text-indent: 0px;
    text-align: left;
    text-transform: none;
    letter-spacing: normal;
    word-spacing: normal;
    overflow-wrap: normal;
    white-space: nowrap;
    float: none;
    direction: ltr;
    max-width: none;
    max-height: none;
    min-width: 0px;
    min-height: 0px;
    border: 0px;
    padding: 0px;
    margin: 0px;
}

.MathJax_SVG * {
    transition: none 0s ease 0s;
}

.MathJax_SVG_Display svg {
    vertical-align: middle !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}

.os-windows.monocolor-emoji .md-emoji {
    font-family: "Segoe UI Symbol", sans-serif;
}

.md-diagram-panel>svg {
    max-width: 100%;
}

[lang="flow"] svg,
[lang="mermaid"] svg {
    max-width: 100%;
    height: auto;
}

[lang="mermaid"] .node text {
    font-size: 1rem;
}

table tr th {
    border-bottom: 0px;
}

video {
    max-width: 100%;
    display: block;
    margin: 0px auto;
}

iframe {
    max-width: 100%;
    width: 100%;
    border: none;
}

.highlight td,
.highlight tr {
    border: 0px;
}

svg[id^="mermaidChart"] {
    line-height: 1em;
}

mark {
    background: rgb(255, 255, 0);
    color: rgb(0, 0, 0);
}

.md-html-inline .md-plain,
.md-html-inline strong,
mark .md-inline-math,
mark strong {
    color: inherit;
}

mark .md-meta {
    color: rgb(0, 0, 0);
    opacity: 0.3 !important;
}

:root {
    --side-bar-bg-color: #fafafa;
    --control-text-color: #777;
}

@include-when-export url(https://fonts.loli.net/css?family=Open+Sans:400italic,700italic,700,400&subset=latin,latin-ext);
html {
    font-size: 16px;
}

body {
    font-family: "Open Sans", "Clear Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: rgb(51, 51, 51);
    line-height: 1.6;
}

#write {
    // max-width: 860px;
    margin: 0 auto;
    padding: 30px;
    padding-bottom: 100px;
}

#write>ul:first-child,
#write>ol:first-child {
    margin-top: 30px;
}

a {
    color: #4183C4;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    position: relative;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: bold;
    line-height: 1.4;
    cursor: text;
}

h1:hover a.anchor,
h2:hover a.anchor,
h3:hover a.anchor,
h4:hover a.anchor,
h5:hover a.anchor,
h6:hover a.anchor {
    text-decoration: none;
}

h1 tt,
h1 code {
    font-size: inherit;
}

h2 tt,
h2 code {
    font-size: inherit;
}

h3 tt,
h3 code {
    font-size: inherit;
}

h4 tt,
h4 code {
    font-size: inherit;
}

h5 tt,
h5 code {
    font-size: inherit;
}

h6 tt,
h6 code {
    font-size: inherit;
}

h1 {
    padding-bottom: .3em;
    font-size: 2.25em;
    line-height: 1.2;
    border-bottom: 1px solid #eee;
}

h2 {
    padding-bottom: .3em;
    font-size: 1.75em;
    line-height: 1.225;
    border-bottom: 1px solid #eee;
}

h3 {
    font-size: 1.5em;
    line-height: 1.43;
}

h4 {
    font-size: 1.25em;
}

h5 {
    font-size: 1em;
}

h6 {
    font-size: 1em;
    color: #777;
}

p,
blockquote,
ul,
ol,
dl,
table {
    margin: 0.8em 0;
}

li>ol,
li>ul {
    margin: 0 0;
}

hr {
    height: 2px;
    padding: 0;
    margin: 16px 0;
    background-color: #e7e7e7;
    border: 0 none;
    overflow: hidden;
    box-sizing: content-box;
}

li p.first {
    display: inline-block;
}

ul,
ol {
    padding-left: 30px;
}

ul:first-child,
ol:first-child {
    margin-top: 0;
}

ul:last-child,
ol:last-child {
    margin-bottom: 0;
}

blockquote {
    border-left: 4px solid #dfe2e5;
    padding: 0 15px;
    color: #777777;
}

blockquote blockquote {
    padding-right: 0;
}

table {
    padding: 0;
    word-break: initial;
}

table tr {
    border-top: 1px solid #dfe2e5;
    margin: 0;
    padding: 0;
}

table tr:nth-child(2n),
thead {
    background-color: #f8f8f8;
}

table tr th {
    font-weight: bold;
    border: 1px solid #dfe2e5;
    border-bottom: 0;
    margin: 0;
    padding: 6px 13px;
}

table tr td {
    border: 1px solid #dfe2e5;
    margin: 0;
    padding: 6px 13px;
}

table tr th:first-child,
table tr td:first-child {
    margin-top: 0;
}

table tr th:last-child,
table tr td:last-child {
    margin-bottom: 0;
}

.CodeMirror-lines {
    padding-left: 4px;
}

.code-tooltip {
    box-shadow: 0 1px 1px 0 rgba(0, 28, 36, .3);
    border-top: 1px solid #eef2f2;
}

.md-fences,
code,
tt {
    border: 1px solid #e7eaed;
    background-color: #f8f8f8;
    border-radius: 3px;
    padding: 0;
    padding: 2px 4px 0px 4px;
    font-size: 0.9em;
}

code {
    background-color: #f3f4f4;
    padding: 0 2px 0 2px;
}

.md-fences {
    margin-bottom: 15px;
    margin-top: 15px;
    padding-top: 8px;
    padding-bottom: 6px;
}

.md-task-list-item>input {
    margin-left: -1.3em;
}

@media print {
    html {
        font-size: 13px;
    }
    table,
    pre {
        page-break-inside: avoid;
    }
    pre {
        word-wrap: break-word;
    }
}

.md-fences {
    background-color: #f8f8f8;
}

#write pre.md-meta-block {
    padding: 1rem;
    font-size: 85%;
    line-height: 1.45;
    background-color: #f7f7f7;
    border: 0;
    border-radius: 3px;
    color: #777777;
    margin-top: 0 !important;
}

.mathjax-block>.code-tooltip {
    bottom: .375rem;
}

.md-mathjax-midline {
    background: #fafafa;
}

#write>h3.md-focus:before {
    left: -1.5625rem;
    top: .375rem;
}

#write>h4.md-focus:before {
    left: -1.5625rem;
    top: .285714286rem;
}

#write>h5.md-focus:before {
    left: -1.5625rem;
    top: .285714286rem;
}

#write>h6.md-focus:before {
    left: -1.5625rem;
    top: .285714286rem;
}

.md-image>.md-meta {
    /*border: 1px solid #ddd;*/
    border-radius: 3px;
    padding: 2px 0px 0px 4px;
    font-size: 0.9em;
    color: inherit;
}

.md-tag {
    color: #a7a7a7;
    opacity: 1;
}

.md-toc {
    margin-top: 20px;
    padding-bottom: 20px;
}

.sidebar-tabs {
    border-bottom: none;
}

#typora-quick-open {
    border: 1px solid #ddd;
    background-color: #f8f8f8;
}

#typora-quick-open-item {
    background-color: #FAFAFA;
    border-color: #FEFEFE #e5e5e5 #e5e5e5 #eee;
    border-style: solid;
    border-width: 1px;
}

/** focus mode */

.on-focus-mode blockquote {
    border-left-color: rgba(85, 85, 85, 0.12);
}

header,
.context-menu,
.megamenu-content,
footer {
    font-family: "Segoe UI", "Arial", sans-serif;
}

.file-node-content:hover .file-node-icon,
.file-node-content:hover .file-node-open-state {
    visibility: visible;
}

.mac-seamless-mode #typora-sidebar {
    background-color: #fafafa;
    background-color: var(--side-bar-bg-color);
}

.md-lang {
    color: #b4654d;
}

.html-for-mac .context-menu {
    --item-hover-bg-color: #E6F0FE;
}

#md-notification .btn {
    border: 0;
}

.dropdown-menu .divider {
    border-color: #e5e5e5;
}

.ty-preferences .window-content {
    background-color: #fafafa;
}

.ty-preferences .nav-group-item.active {
    color: white;
    background: #999;
}

/** 自定义样式*/

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 16px 0px;
}
@media only screen and (min-width: 1400px) {
  #write {
    max-width: 1024px;
  }
}

@media only screen and (min-width: 1800px) {
  #write {
    max-width: 1200px;
  }
}
</style>