<!--  每次仅仅替换 <div ref="searchBar" class="go-sdk"> 里面的内容，修改图片的引用路径./   -->
<template>
    <div ref="searchBar" class="go-sdk">
       <div class='typora-export-content'>
            <div id='write' class=''>
                <h1 id='接口文档介绍'><span>接口文档介绍</span></h1>
                <h2 id='文档描述'><span>文档描述</span></h2>
                <p><span>本文档描述了至信链 GO SDK  所提供的所有功能及通过 GO SDK 调用至信链司法存证服务的方法</span></p>
                <h3 id='文档历史'><span>文档历史</span></h3>
                <figure>
                    <table>
                        <thead>
                            <tr>
                                <th><span>修订日期</span></th>
                                <th><span>修订内容</span></th>
                                <th><span>修订版本</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><span>2020.08.11</span></td>
                                <td><span>新增接口</span></td>
                                <td><span>v2.0.1</span></td>
                            </tr>
                            <tr>
                                <td><span>2021.01.28</span></td>
                                <td><span>新增代理商相关接口</span></td>
                                <td><span>v2.0.4</span></td>
                            </tr>
                            <tr>
                                <td><span>2021.02.09</span></td>
                                <td><span>增加个人用户及确权接口</span></td>
                                <td><span>v2.1.3</span></td>
                            </tr>
                            <tr>
                                <td><span>2021.05.12</span></td>
                                <td><span>接口优化</span></td>
                                <td><span>v2.1.4</span></td>
                            </tr>
                            <tr>
                                <td><span>2021.06.10</span></td>
                                <td><span>新增长视频录屏支持</span></td>
                                <td><span>v2.1.4.4</span></td>
                            </tr>
                            <tr>
                                <td><span>2021.10.25</span></td>
                                <td><span>SDK 重构</span></td>
                                <td><span>v2.3.0</span></td>
                            </tr>
                        </tbody>
                    </table>
                </figure>
                <p>&nbsp;</p>
                <h3 id='阅读对象'><span>阅读对象</span></h3>
                <p><span>接入至信链的开发者</span></p>
                <h3 id='协议规则'><span>协议规则</span></h3>
                <figure>
                    <table>
                        <thead>
                            <tr>
                                <th><span>分类</span></th>
                                <th><span>说明</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><span>传输方式</span></td>
                                <td><span>为保证传输安全，采用 HTTPS 传输</span></td>
                            </tr>
                            <tr>
                                <td><span>数据格式</span></td>
                                <td><span>参考具体方法定义</span></td>
                            </tr>
                        </tbody>
                    </table>
                </figure>
                <p>&nbsp;</p>
                <h2 id='接入流程'><span>接入流程</span></h2>
                <p><img src="./接入流程.png" referrerpolicy="no-referrer" alt="attvar"></p>
                <p>&nbsp;</p>
                <h1 id='鉴权绑定'><span>鉴权绑定</span></h1>
               <h2><a name="获取 SDK" class="md-header-anchor"></a><span>获取 SDK</span></h2>
                <ol start=''>
                    <li><span>进入到 go 根目录或者当前项目的 vendor 目录中的 github.com/zhixinlian目录下  </span></li>
                    <li><span>运行命令 git clone </span><a href='https://github.com/zhixinlian/zxl-go-sdk.git' target='_blank' class='url'>https://github.com/zhixinlian/zxl-go-sdk.git</a><span>  </span></li>
                    <li><span>选择版本，tag：v2.3.0</span></li>
                </ol>
                <h3 id='使用步骤'><span>使用步骤</span></h3>
                <ol start=''>
                    <li><span>通过至信链线上首页注册账户并完成认证，获取生成的 APPID 与 APPKEY;</span></li>
                    <li><span>获取 SDK</span></li>
                    <li><span>使用 NewZxlImpl 创建 SDK 实例</span></li>
                    <li><span>使用 GenerateKeyPair 方法，生成公私钥对</span></li>
                    <li><span>使用 BindUserCert 方法，绑定证书</span></li>
                    <li><span>使用 CalculateHash 方法，计算需要存证的文件 HASH</span></li>
                    <li><span>使用 EvidenceSave  方法，写入存证 HASH 信息</span></li>
                </ol>
                <h3 id='使用示例'><span>使用示例</span></h3>
                <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="" style="page-break-inside: unset;"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang=""><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"></div><div class="CodeMirror-measure"></div><div style="position: relative; z-index: 1;"></div><div class="CodeMirror-code" role="presentation" style=""><div class="CodeMirror-activeline" style="position: relative;"><div class="CodeMirror-activeline-background CodeMirror-linebackground"></div><div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div><pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">package main</span></pre>
            </div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">import (</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>"fmt"</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>zxl "github.com/zhixinlian/zxl-go-sdk"</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">)</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">func main() {</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>//初始化应用</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>zxl, err := zxl.NewZxlImpl("123456000110000", "appkey")</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>if err != nil {</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>panic(err)</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>}</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>//生成公私钥对</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>pk, sk, err := zxl.GenerateKeyPair()</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>if err != nil {</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>panic(err)</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>}</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>fmt.Println("公钥:", pk)</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>fmt.Println("私钥:", sk)</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" class="cm-tab-wrap-hack" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span></span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>// 绑定证书</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>err = zxl.BindUserCert(pk, sk, 5 * time.Second)</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>if err != nil {</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>t.Error(err)</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>}</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>//计算文件hash</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>hashStr, err := zxl.CalculateHash("G:\\channel.zip")</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>if err != nil {</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>panic(err.Error())</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>}</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>fmt.Print(hashStr)</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" class="cm-tab-wrap-hack" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span></span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>// 写入存证信息</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>result, err := zxl.EvidenceSave(hashStr, "extendInfo", sk, pk, 0)</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>if err != nil {</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>t.Error(err.Error())</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>}</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">}</span></pre>
        </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 880px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 880px;"></div>
    </div>
    </div>
    </pre>
    <p>&nbsp;</p>
    <h2 id='创建 SDK 实例'><span>创建 SDK 实例</span></h2>
    <ul>
        <li>
            <p><span>方法原型</span></p>
            <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang=""><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang=""><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"></div><div class="CodeMirror-measure"></div><div style="position: relative; z-index: 1;"></div><div class="CodeMirror-code" role="presentation"><div class="CodeMirror-activeline" style="position: relative;"><div class="CodeMirror-activeline-background CodeMirror-linebackground"></div><div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div><pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">NewZxlImpl(appId, appKey string) (*zxlImpl, error)</span></pre>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
            <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
            </div>
            </div>
            </pre>
        </li>
        <li>
            <p><span>参数说明</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>参数名</span></th>
                            <th><span>参数类型</span></th>
                            <th><span>默认值</span></th>
                            <th><span>参数描述</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>appId</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>企业 APPID</span></td>
                        </tr>
                        <tr>
                            <td><span>appKey</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>企业APPKEY</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
        <li>
            <p><span>返回值</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>返回值类型</span></th>
                            <th><span>返回值描述</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>NewZxlImpl</span></td>
                            <td><span>SDK 实例</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
    </ul>
    <p>&nbsp;</p>
    <h2 id='证书绑定'><span>证书绑定</span></h2>
    <ul>
        <li>
            <p><span>方法原型</span></p>
            <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang=""><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang=""><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"></div><div class="CodeMirror-measure"></div><div style="position: relative; z-index: 1;"></div><div class="CodeMirror-code" role="presentation"><div class="CodeMirror-activeline" style="position: relative;"><div class="CodeMirror-activeline-background CodeMirror-linebackground"></div><div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div><pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">BindUserCert(pk, sk string, timeout time.Duration) error</span></pre>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
            <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
            </div>
            </div>
            </pre>
        </li>
        <li>
            <p><span>参数说明</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>参数名</span></th>
                            <th><span>参数类型</span></th>
                            <th><span>默认值</span></th>
                            <th><span>参数描述</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>pk</span></td>
                            <td><span>string</span></td>
                            <td>&nbsp;</td>
                            <td><span>公钥</span></td>
                        </tr>
                        <tr>
                            <td><span>sk</span></td>
                            <td><span>string</span></td>
                            <td>&nbsp;</td>
                            <td><span>私钥</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
        <li>
            <p><span>返回值</span></p>
            <p><span>-</span></p>
        </li>
    </ul>
    <h1 id='hash 存证'><span>hash 存证</span></h1>
    <h2 id='计算文件 HASH'><span>计算文件 HASH</span></h2>
    <ul>
        <li>
            <p><span>方法原型</span></p>
            <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang=""><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang=""><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"></div><div class="CodeMirror-measure"></div><div style="position: relative; z-index: 1;"></div><div class="CodeMirror-code" role="presentation"><div class="CodeMirror-activeline" style="position: relative;"><div class="CodeMirror-activeline-background CodeMirror-linebackground"></div><div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div><pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">CalculateHash(path string) (string, error)</span></pre>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
            <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
            </div>
            </div>
            </pre>
        </li>
        <li>
            <p><span>参数说明</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>参数名</span></th>
                            <th><span>参数类型</span></th>
                            <th><span>默认值</span></th>
                            <th><span>参数描述</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>path</span></td>
                            <td><span>string</span></td>
                            <td>&nbsp;</td>
                            <td><span>文件路径</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
        <li>
            <p><span>返回值</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>返回值类型</span></th>
                            <th><span>返回值描述</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>string</span></td>
                            <td><span>指定文件 SM3 算法后的散列</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
    </ul>
    <p>&nbsp;</p>
    <h2 id='数据上链存证'><span>数据上链存证</span></h2>
    <ul>
        <li>
            <p><span>方法原型</span></p>
            <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang=""><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang=""><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"></div><div class="CodeMirror-measure"></div><div style="position: relative; z-index: 1;"></div><div class="CodeMirror-code" role="presentation"><div class="CodeMirror-activeline" style="position: relative;"><div class="CodeMirror-activeline-background CodeMirror-linebackground"></div><div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div><pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">EvidenceSave(evHash, extendInfo, sk, pk string, timeout time.Duration) (*EvSaveResult, error)</span></pre>
            </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 44px;"></div>
            <div class="CodeMirror-gutters" style="display: none; height: 44px;"></div>
            </div>
            </div>
            </pre>
        </li>
        <li>
            <p><span>参数说明</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>参数名</span></th>
                            <th><span>参数类型</span></th>
                            <th><span>默认值</span></th>
                            <th><span>参数描述</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>evHash</span></td>
                            <td><span>string</span></td>
                            <td>&nbsp;</td>
                            <td><span>证据 HASH，length &lt;= 1024</span></td>
                        </tr>
                        <tr>
                            <td><span>extendInfo</span></td>
                            <td><span>string</span></td>
                            <td>&nbsp;</td>
                            <td><span>扩展信息</span></td>
                        </tr>
                        <tr>
                            <td><span>sk</span></td>
                            <td><span>string</span></td>
                            <td>&nbsp;</td>
                            <td><span>私钥</span></td>
                        </tr>
                        <tr>
                            <td><span>pk</span></td>
                            <td><span>string</span></td>
                            <td>&nbsp;</td>
                            <td><span>公钥</span></td>
                        </tr>
                        <tr>
                            <td><span>timeout</span></td>
                            <td><span>time.Duration</span></td>
                            <td>&nbsp;</td>
                            <td><span>超时时间</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
        <li>
            <p><span>返回值</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>返回值类型</span></th>
                            <th><span>返回值描述</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>EvSaveResult</span></td>
                            <td><span>{</span><br><span>&quot;evId&quot;:&quot;记链唯一标识&quot;,</span><br><span>&quot;txHash&quot;:&quot;交易hash&quot;,</span><br><span>&quot;createTime&quot;: &quot;出块时间&quot;,</span><br><span>&quot;ext&quot;: &quot;扩展信息，可通过 key ： queryCertUrl,获取证书&quot;</span><br><span>}</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
            <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang=""><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang=""><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"></div><div class="CodeMirror-measure"></div><div style="position: relative; z-index: 1;"></div><div class="CodeMirror-code" role="presentation" style=""><div class="CodeMirror-activeline" style="position: relative;"><div class="CodeMirror-activeline-background CodeMirror-linebackground"></div><div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div><pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">type EvSaveResult struct {</span></pre>
            </div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>BlockHeight int64  `json:"blockHeight"`</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>EvId &nbsp; &nbsp; &nbsp;  string `json:"evId"`</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>TxHash &nbsp; &nbsp;  string `json:"txHash"`</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>EvHash &nbsp; &nbsp;  string `json:"evHash"`</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>CreateTime  string `json:"createTime"`</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>Ext &nbsp; &nbsp; &nbsp; &nbsp; string `json:"ext"`</span></pre>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">}</span></pre>
            </div>
            </div>
            </div>
            </div>
            </div>
            <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 176px;"></div>
            <div class="CodeMirror-gutters" style="display: none; height: 176px;"></div>
            </div>
            </div>
            </pre>
            <p>&nbsp;</p>
        </li>
    </ul>
    <h2 id='链上信息查询'><span>链上信息查询</span></h2>
    <ul>
        <li>
            <p><span>方法原型</span></p>
            <ul>
                <li>
                    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang=""><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang=""><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"></div><div class="CodeMirror-measure"></div><div style="position: relative; z-index: 1;"></div><div class="CodeMirror-code" role="presentation"><div class="CodeMirror-activeline" style="position: relative;"><div class="CodeMirror-activeline-background CodeMirror-linebackground"></div><div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div><pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">QueryWithEvHash(evHash string, timeout time.Duration) ([]QueryResp, error)</span></pre>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 44px;"></div>
                    <div class="CodeMirror-gutters" style="display: none; height: 44px;"></div>
                    </div>
                    </div>
                    </pre>
                </li>
                <li>
                    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang=""><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang=""><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"></div><div class="CodeMirror-measure"></div><div style="position: relative; z-index: 1;"></div><div class="CodeMirror-code" role="presentation"><div class="CodeMirror-activeline" style="position: relative;"><div class="CodeMirror-activeline-background CodeMirror-linebackground"></div><div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div><pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">QueryWithHash(hash string, timeout time.Duration) ([]QueryResp, error)</span></pre>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 44px;"></div>
                    <div class="CodeMirror-gutters" style="display: none; height: 44px;"></div>
                    </div>
                    </div>
                    </pre>
                </li>
                <li>
                    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang=""><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang=""><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
                    </div>
                    <div class="CodeMirror-measure"></div>
                    <div style="position: relative; z-index: 1;"></div>
                    <div class="CodeMirror-code" role="presentation">
                        <div class="CodeMirror-activeline" style="position: relative;">
                            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
                            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
                            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">QueryWithTxHash(txHash string, timeout time.Duration) ([]QueryResp, error)</span></pre>
                        </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 44px;"></div>
                    <div class="CodeMirror-gutters" style="display: none; height: 44px;"></div>
                    </div>
                    </div>
                    </pre>
                </li>
                <li>
                    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang=""><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang=""><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
                    </div>
                    <div class="CodeMirror-measure"></div>
                    <div style="position: relative; z-index: 1;"></div>
                    <div class="CodeMirror-code" role="presentation">
                        <div class="CodeMirror-activeline" style="position: relative;">
                            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
                            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
                            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">QueryWithEvId(evId string, timeout time.Duration) ([]QueryResp, error)</span></pre>
                        </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 44px;"></div>
                    <div class="CodeMirror-gutters" style="display: none; height: 44px;"></div>
                    </div>
                    </div>
                    </pre>
                </li>
            </ul>
        </li>
        <li>
            <p><span>参数说明</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>参数名</span></th>
                            <th><span>参数类型</span></th>
                            <th><span>默认值</span></th>
                            <th><span>参数描述</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>evHash</span></td>
                            <td><span>string</span></td>
                            <td>&nbsp;</td>
                            <td><span>存证 hash</span></td>
                        </tr>
                        <tr>
                            <td><span>txHash</span></td>
                            <td><span>string</span></td>
                            <td>&nbsp;</td>
                            <td><span>交易 hash</span></td>
                        </tr>
                        <tr>
                            <td><span>evId</span></td>
                            <td><span>string</span></td>
                            <td>&nbsp;</td>
                            <td><span>存证 id</span></td>
                        </tr>
                        <tr>
                            <td><span>hash</span></td>
                            <td><span>string</span></td>
                            <td>&nbsp;</td>
                            <td><span>不明确指定，可以是txHash,evId,evHash中任意一个</span></td>
                        </tr>
                        <tr>
                            <td><span>timeout</span></td>
                            <td><span>time.Duration</span></td>
                            <td>&nbsp;</td>
                            <td><span>超时时间</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
        <li>
            <p><span>返回值</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>返回值类型</span></th>
                            <th><span>返回值描述</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>EvSaveResult</span></td>
                            <td><span>{</span><br><span>&quot;appId&quot;:&quot;应用ID&quot;,</span><br><span>&quot;evId&quot;:&quot;记链唯一标识&quot;,</span><br><span>&quot;evHash&quot;:&quot;证据hash&quot;,</span><br><span>&quot;txHash&quot;:&quot;交易hash&quot;,</span><br><span>&quot;extendInfo&quot;:&quot;扩展信息&quot;,</span><br><span>&quot;createTime&quot;: &quot;出块时间&quot;,</span><br><span>&quot;blockHeight&quot;: &quot;区块高度&quot;,</span><br><span>&quot;ext&quot;: &quot;扩展信息，可通过 key ： queryCertUrl,获取证书&quot;</span><br><span>}</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
    </ul>
    <p>&nbsp;</p>
    <h1 id='视频图片取证'><span>视频/图片取证</span></h1>
    <h2 id='取证服务'><span>取证服务</span></h2>
    <ul>
        <li>
            <p><span>方法原型</span></p>
            <ul>
                <li>
                    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang=""><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang=""><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
                    </div>
                    <div class="CodeMirror-measure"></div>
                    <div style="position: relative; z-index: 1;"></div>
                    <div class="CodeMirror-code" role="presentation">
                        <div class="CodeMirror-activeline" style="position: relative;">
                            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
                            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
                            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">//视频取证接口</span></pre>
                        </div>
                        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">EvidenceObtainVideo(webUrls, title, remark, timeout time.Duration) (string, error)</span></pre>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 66px;"></div>
                    <div class="CodeMirror-gutters" style="display: none; height: 66px;"></div>
                    </div>
                    </div>
                    </pre>
                </li>
                <li>
                    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang=""><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang=""><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
                    </div>
                    <div class="CodeMirror-measure"></div>
                    <div style="position: relative; z-index: 1;"></div>
                    <div class="CodeMirror-code" role="presentation">
                        <div class="CodeMirror-activeline" style="position: relative;">
                            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
                            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
                            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">//图片取证</span></pre>
                        </div>
                        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">EvidenceObtainPic(webUrls, title, remark, timeout time.Duration) (string, error)</span></pre>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 66px;"></div>
                    <div class="CodeMirror-gutters" style="display: none; height: 66px;"></div>
                    </div>
                    </div>
                    </pre>
                </li>
            </ul>
        </li>
        <li>
            <p><span>参数说明</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>参数名</span></th>
                            <th><span>参数类型</span></th>
                            <th><span>默认值</span></th>
                            <th><span>参数描述</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>webUrls</span></td>
                            <td><span>string</span></td>
                            <td>&nbsp;</td>
                            <td><span>取证的url</span></td>
                        </tr>
                        <tr>
                            <td><span>title</span></td>
                            <td><span>string</span></td>
                            <td>&nbsp;</td>
                            <td><span>标题</span></td>
                        </tr>
                        <tr>
                            <td><span>remark</span></td>
                            <td><span>string</span></td>
                            <td>&nbsp;</td>
                            <td><span>描述</span></td>
                        </tr>
                        <tr>
                            <td><span>timeout</span></td>
                            <td><span>time.Duration</span></td>
                            <td>&nbsp;</td>
                            <td><span>超时时间</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
        <li>
            <p><span>返回值</span></p>
            <p><span>具体的orderNo（任务单号）</span></p>
        </li>
    </ul>
    <ul>
        <li>
            <p><span>方法原型</span></p>
            <ul>
                <li>
                    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang=""><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang=""><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
                    </div>
                    <div class="CodeMirror-measure"></div>
                    <div style="position: relative; z-index: 1;"></div>
                    <div class="CodeMirror-code" role="presentation">
                        <div class="CodeMirror-activeline" style="position: relative;">
                            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
                            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
                            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">//长视频取证接口</span></pre>
                        </div>
                        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">NewEvidenceObtainVideo(obtainVideoOption *ObtainVideoOption, timeout time.Duration)</span></pre>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 66px;"></div>
                    <div class="CodeMirror-gutters" style="display: none; height: 66px;"></div>
                    </div>
                    </div>
                    </pre>
                </li>
            </ul>
        </li>
        <li>
            <p><span>参数说明</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>参数名</span></th>
                            <th><span>参数类型</span></th>
                            <th><span>默认值</span></th>
                            <th><span>参数描述</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>obtainVideoOption</span></td>
                            <td><span>*ObtainVideoOption</span></td>
                            <td>&nbsp;</td>
                            <td><span>视频取证的参数</span></td>
                        </tr>
                        <tr>
                            <td><span>timeout</span></td>
                            <td><span>time.Duration</span></td>
                            <td>&nbsp;</td>
                            <td><span>超时时间</span></td>
                        </tr>
                        <tr>
                            <td><span>obtainVideoOption的结构体如下</span></td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody>
                </table>
            </figure>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>参数名</span></th>
                            <th><span>参数类型</span></th>
                            <th><span>默认值</span></th>
                            <th><span>参数描述</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>webUrls</span></td>
                            <td><span>string</span></td>
                            <td>&nbsp;</td>
                            <td><span>取证的url</span></td>
                        </tr>
                        <tr>
                            <td><span>title</span></td>
                            <td><span>string</span></td>
                            <td>&nbsp;</td>
                            <td><span>标题</span></td>
                        </tr>
                        <tr>
                            <td><span>remark</span></td>
                            <td><span>string</span></td>
                            <td>&nbsp;</td>
                            <td><span>描述</span></td>
                        </tr>
                        <tr>
                            <td><span>duration</span></td>
                            <td><span>int</span></td>
                            <td>&nbsp;</td>
                            <td><span>录屏时间，单位:秒，不超过1小时</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
    </ul>
    <ul>
        <li>
            <p><span>返回值</span></p>
            <p><span>具体的orderNo（任务单号）</span></p>
        </li>
    </ul>
    <h2 id='查询取证结果'><span>查询取证结果</span></h2>
    <ul>
        <li>
            <p><span>方法原型</span></p>
            <ul>
                <li>
                    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang=""><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang=""><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
                    </div>
                    <div class="CodeMirror-measure"></div>
                    <div style="position: relative; z-index: 1;"></div>
                    <div class="CodeMirror-code" role="presentation">
                        <div class="CodeMirror-activeline" style="position: relative;">
                            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
                            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
                            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">GetEvidenceStatus(orderNo, timeout time.Duration) (*EvIdData, error)</span></pre>
                        </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
                    <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
                    </div>
                    </div>
                    </pre>
                </li>
            </ul>
        </li>
        <li>
            <p><span>参数说明</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>参数名</span></th>
                            <th><span>参数类型</span></th>
                            <th><span>默认值</span></th>
                            <th><span>参数描述</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>orderNo</span></td>
                            <td><span>string</span></td>
                            <td>&nbsp;</td>
                            <td><span>取证返回的任务单号</span></td>
                        </tr>
                        <tr>
                            <td><span>timeout</span></td>
                            <td><span>time.Duration</span></td>
                            <td>&nbsp;</td>
                            <td><span>超时时间</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
        <li>
            <p><span>返回值</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>返回值类型</span></th>
                            <th><span>返回值描述</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>EvIdData</span></td>
                            <td><span>{</span><br><span>&quot;status&quot;:&quot;当前任务状态[0:执行中&gt;&gt;2成功&gt;&gt;10失败]&quot;,</span><br><span>&quot;evidUrl&quot;:&quot;成功状态下,取证证据下载地址&quot;,</span><br><span>&quot;voucherUrl&quot;:&quot;成功状态下,取证证书下载地址&quot;</span><br><span>}</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
        <li>
            <p><span>示例</span></p>
            <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="go" style="page-break-inside: unset;"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="go"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
            </div>
            <div class="CodeMirror-measure"></div>
            <div style="position: relative; z-index: 1;"></div>
            <div class="CodeMirror-code" role="presentation" style="">
                <div class="CodeMirror-activeline" style="position: relative;">
                    <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
                    <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
                    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp;<span class="cm-variable">zxlsdk</span>, <span class="cm-variable">err</span> :<span class="cm-operator">=</span> <span class="cm-variable">zxl</span><span class="cm-number">.</span><span class="cm-variable">NewZxlImpl</span>(<span class="cm-variable">appId</span>, <span class="cm-variable">appKey</span>)</span></pre>
                </div>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp;<span class="cm-keyword">if</span> <span class="cm-variable">err</span> <span class="cm-operator">!=</span> <span class="cm-atom">nil</span> {</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp;<span class="cm-atom">panic</span>(<span class="cm-variable">err</span>)</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;  }</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp;<span class="cm-variable">obtainVideoOption</span> :<span class="cm-operator">=</span> <span class="cm-variable">zxl</span><span class="cm-number">.</span><span class="cm-variable">ObtainVideoOption</span>{</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp;<span class="cm-variable">Duration</span>: &nbsp; &nbsp; &nbsp; <span class="cm-number">3600</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp;<span class="cm-variable">RepresentAppId</span>: <span class="cm-string">""</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp;<span class="cm-variable">Remark</span>: &nbsp; &nbsp; &nbsp; &nbsp; <span class="cm-string">"测试视频"</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp;<span class="cm-variable">Title</span>: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<span class="cm-string">"测试视频"</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp;<span class="cm-variable">WebUrls</span>: &nbsp; &nbsp; &nbsp; &nbsp;<span class="cm-string">"https://zhixinliantest-1302317679.cos.ap-guangzhou.myqcloud.com/20210611/video/2021-06-11_15-15-18_655459332949.mp4"</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;  }</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp;<span class="cm-comment">//长视频取证</span></span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp;<span class="cm-variable">orderNo</span>, <span class="cm-variable">err</span> :<span class="cm-operator">=</span> <span class="cm-variable">zxlsdk</span><span class="cm-number">.</span><span class="cm-variable">NewEvidenceObtainVideo</span>(<span class="cm-operator">&amp;</span><span class="cm-variable">obtainVideoOption</span>, <span class="cm-number">5</span><span class="cm-operator">*</span><span class="cm-variable">time</span><span class="cm-number">.</span><span class="cm-variable">Second</span>)</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp;<span class="cm-keyword">if</span> <span class="cm-variable">err</span> <span class="cm-operator">!=</span> <span class="cm-atom">nil</span> {</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp;<span class="cm-variable">fmt</span><span class="cm-number">.</span><span class="cm-variable">Println</span>(<span class="cm-variable">err</span>)</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp;<span class="cm-keyword">return</span></span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;  }</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp;<span class="cm-variable">fmt</span><span class="cm-number">.</span><span class="cm-variable">Printf</span>(<span class="cm-string">"取证请求提交，订单号：%s\n"</span>, <span class="cm-variable">orderNo</span>)</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp;<span class="cm-variable">evIdData</span> :<span class="cm-operator">=</span> <span class="cm-operator">&amp;</span><span class="cm-variable">zxl</span><span class="cm-number">.</span><span class="cm-variable">EvIdData</span>{}</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp;<span class="cm-comment">// 查询取证结果</span></span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp;<span class="cm-keyword">for</span> {</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp;<span class="cm-variable">evIdData</span>, <span class="cm-variable">err</span> <span class="cm-operator">=</span> <span class="cm-variable">zxlsdk</span><span class="cm-number">.</span><span class="cm-variable">GetEvidenceStatus</span>(<span class="cm-variable">orderNo</span>, <span class="cm-number">0</span>)</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp;<span class="cm-keyword">if</span> <span class="cm-variable">err</span> <span class="cm-operator">!=</span> <span class="cm-atom">nil</span> {</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<span class="cm-atom">panic</span>(<span class="cm-variable">err</span>)</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp;  }</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp;<span class="cm-variable">fmt</span><span class="cm-number">.</span><span class="cm-variable">Printf</span>(<span class="cm-string">"evIdData is %v\n"</span>, <span class="cm-variable">evIdData</span>)</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp;<span class="cm-keyword">if</span> <span class="cm-variable">evIdData</span><span class="cm-number">.</span><span class="cm-variable">Status</span> <span class="cm-operator">!=</span> <span class="cm-number">0</span> {</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<span class="cm-keyword">break</span></span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp;  }</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp;<span class="cm-variable">time</span><span class="cm-number">.</span><span class="cm-variable">Sleep</span>(<span class="cm-number">1</span> <span class="cm-operator">*</span> <span class="cm-variable">time</span><span class="cm-number">.</span><span class="cm-variable">Second</span>)</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;  }</span></pre>
            </div>
            </div>
            </div>
            </div>
            </div>
            <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 880px;"></div>
            <div class="CodeMirror-gutters" style="display: none; height: 880px;"></div>
            </div>
            </div>
            </pre>
        </li>
    </ul>
    <h1 id='侵权监控服务'><span>侵权监控服务</span></h1>
    <h2 id='创建侵权监控任务'><span>创建侵权监控任务</span></h2>
    <ul>
        <li>
            <p><span>方法原型</span></p>
            <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="go"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="go"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
            </div>
            <div class="CodeMirror-measure"></div>
            <div style="position: relative; z-index: 1;"></div>
            <div class="CodeMirror-code" role="presentation">
                <div class="CodeMirror-activeline" style="position: relative;">
                    <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
                    <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
                    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">SubmitTortTask</span>(<span class="cm-variable">tort</span> <span class="cm-variable">Tort</span>, <span class="cm-variable">timeout</span> <span class="cm-variable">time</span><span class="cm-number">.</span><span class="cm-variable">Duration</span>) (<span class="cm-variable">TortResp</span>, <span class="cm-keyword">error</span>)</span></pre>
                </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
            <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
            </div>
            </div>
            </pre>
        </li>
        <li>
            <p><span>参数说明</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><em><span>参数名</span></em></th>
                            <th><em><span>参数类型</span></em></th>
                            <th><em><span>默认值</span></em></th>
                            <th><em><span>是否必填</span></em></th>
                            <th><em><span>参数描述</span></em></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>tort</span></td>
                            <td><span>Tort</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>侵权监控参数</span></td>
                        </tr>
                        <tr>
                            <td><span>timeOut</span></td>
                            <td><span>time.Duration</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>超时时间</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
    </ul>
    <p>&nbsp;</p>
    <p><span>Tort 结构如下:</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>Url</span></td>
                    <td><span>string</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>资源 url</span></td>
                </tr>
                <tr>
                    <td><span>Title</span></td>
                    <td><span>string</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>标题</span></td>
                </tr>
                <tr>
                    <td><span>Keyword</span></td>
                    <td><span>string</span></td>
                    <td><span>无</span></td>
                    <td><span>否</span></td>
                    <td><span>关键字列表，半角分号（;）分割</span></td>
                </tr>
                <tr>
                    <td><span>Type</span></td>
                    <td><span>constants.TortType</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>资源类型</span></td>
                </tr>
                <tr>
                    <td><span>Source</span></td>
                    <td><span>constants.TortSource</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>数据来源</span></td>
                </tr>
                <tr>
                    <td><span>StartDate</span></td>
                    <td><span>string</span></td>
                    <td><span>无</span></td>
                    <td><span>否</span></td>
                    <td><span>开始时间（2006-01-02），如果不填，从当前时间开始</span></td>
                </tr>
                <tr>
                    <td><span>EndDate</span></td>
                    <td><span>string</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>结束时间（2006-01-02）</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <ul>
        <li>
            <p><span>枚举类型说明</span></p>
            <p><span>TortType（资源类型）：</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>编码</span></th>
                            <th><span>常量</span></th>
                            <th><span>备注</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>1</span></td>
                            <td><span>MATERIAL_PHOTO</span></td>
                            <td><span>图片类型</span></td>
                        </tr>
                        <tr>
                            <td><span>2</span></td>
                            <td><span>MATERIAL_TEXT</span></td>
                            <td><span>文字类型</span></td>
                        </tr>
                        <tr>
                            <td><span>3</span></td>
                            <td><span>MATERIAL_VIDEO</span></td>
                            <td><span>视频类型</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
            <p><span>TortSource（数据来源）：</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>编码</span></th>
                            <th><span>常量</span></th>
                            <th><span>备注</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>1</span></td>
                            <td><span>PROPERTY</span></td>
                            <td><span>房产类监测，type为图片时可用</span></td>
                        </tr>
                        <tr>
                            <td><span>2</span></td>
                            <td><span>ANY</span></td>
                            <td><span>原创类监测，type为图片时可用</span></td>
                        </tr>
                        <tr>
                            <td><span>21</span></td>
                            <td><span>SHORT_VIDEO</span></td>
                            <td><span>短视频监测，type为视频时可用</span></td>
                        </tr>
                        <tr>
                            <td><span>22</span></td>
                            <td><span>LONG_VIDEO</span></td>
                            <td><span>长对短监测:监测范围和短视频一样，type 为长视频时可用</span></td>
                        </tr>
                        <tr>
                            <td><span>23</span></td>
                            <td><span>LONG_TO_LONG_VIDEO</span></td>
                            <td><span>长对长监测:全网视频小网站，type为视频时可用</span></td>
                        </tr>
                        <tr>
                            <td><span>41</span></td>
                            <td><span>NEWS_TEXT</span></td>
                            <td><span>新闻咨询类，监测范围全网</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
        <li>
            <p><span>返回数据（TortResp）</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>字段</span></th>
                            <th><span>类型</span></th>
                            <th><span>描述</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>TaskId</span></td>
                            <td><span>string</span></td>
                            <td><span>确权任务 Id</span></td>
                        </tr>
                        <tr>
                            <td><span>RequestId</span></td>
                            <td><span>string</span></td>
                            <td><span>请求Id</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
        <li>
            <p><span>示例</span></p>
            <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="go" style="page-break-inside: unset;"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="go"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
            </div>
            <div class="CodeMirror-measure"></div>
            <div style="position: relative; z-index: 1;"></div>
            <div class="CodeMirror-code" role="presentation" style="">
                <div class="CodeMirror-activeline" style="position: relative;">
                    <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
                    <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
                    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">zxlSDK</span>, <span class="cm-variable">err</span> :<span class="cm-operator">=</span> <span class="cm-variable">zxl_go_sdk</span><span class="cm-number">.</span><span class="cm-variable">NewZxlImpl</span>(<span class="cm-variable">appId</span>, <span class="cm-variable">appKey</span>)</span></pre>
                </div>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-keyword">if</span> <span class="cm-variable">err</span> <span class="cm-operator">!=</span> <span class="cm-atom">nil</span> {</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">fmt</span><span class="cm-number">.</span><span class="cm-variable">Println</span>(<span class="cm-string">"初始化 SDK 错误"</span>)</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-keyword">return</span></span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">}</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">tort</span> :<span class="cm-operator">=</span> <span class="cm-variable">zxl_go_sdk</span><span class="cm-number">.</span><span class="cm-variable">Tort</span>{</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">Url</span>: <span class="cm-string">"https://inews.gtimg.com/newsapp_bt/0/5001rcns97nr04er/1000?appid=ee22ce76657290e1"</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">Title</span>: <span class="cm-string">"测试图片"</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">Keyword</span>: <span class="cm-string">"月亮;太空"</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">Type</span>: <span class="cm-variable">constants</span><span class="cm-number">.</span><span class="cm-variable">MATERIAL_PHOTO</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">Source</span>: <span class="cm-variable">constants</span><span class="cm-number">.</span><span class="cm-variable">ANY_PIC</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">StartDate</span>: <span class="cm-string">"2021-03-31"</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">EndDate</span>: <span class="cm-string">"2021-04-10"</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">}</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">resp</span>, <span class="cm-variable">err</span> :<span class="cm-operator">=</span> <span class="cm-variable">zxlSDK</span><span class="cm-number">.</span><span class="cm-variable">SubmitTortTask</span>(<span class="cm-variable">tort</span>, <span class="cm-number">5</span> <span class="cm-operator">*</span> <span class="cm-variable">time</span><span class="cm-number">.</span><span class="cm-variable">Second</span>)</span></pre>
            </div>
            </div>
            </div>
            </div>
            </div>
            <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 396px;"></div>
            <div class="CodeMirror-gutters" style="display: none; height: 396px;"></div>
            </div>
            </div>
            </pre>
        </li>
    </ul>
    <p>&nbsp;</p>
    <h2 id='查看监控任务结果'><span>查看监控任务结果</span></h2>
    <ul>
        <li>
            <p><span>方法原型</span></p>
            <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="go"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="go"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
            </div>
            <div class="CodeMirror-measure"></div>
            <div style="position: relative; z-index: 1;"></div>
            <div class="CodeMirror-code" role="presentation">
                <div class="CodeMirror-activeline" style="position: relative;">
                    <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
                    <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
                    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">QueryTortTaskResult</span>(<span class="cm-variable">tortQuery</span> <span class="cm-variable">TortQuery</span>, <span class="cm-variable">timeout</span> <span class="cm-variable">time</span><span class="cm-number">.</span><span class="cm-variable">Duration</span>) (<span class="cm-variable">TortQueryResp</span>, <span class="cm-keyword">error</span>)</span></pre>
                </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 44px;"></div>
            <div class="CodeMirror-gutters" style="display: none; height: 44px;"></div>
            </div>
            </div>
            </pre>
        </li>
        <li>
            <p><span>参数说明</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><em><span>参数名</span></em></th>
                            <th><em><span>参数类型</span></em></th>
                            <th><em><span>默认值</span></em></th>
                            <th><em><span>是否必填</span></em></th>
                            <th><em><span>参数描述</span></em></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>tortQuery</span></td>
                            <td><span>TortQuery</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>查询参数</span></td>
                        </tr>
                        <tr>
                            <td><span>timeOut</span></td>
                            <td><span>time.Duration</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>超时时间</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
    </ul>
    <p><span>TortQuery 结构如下：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>TaskId</span></td>
                    <td><span>string</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>任务Id</span></td>
                </tr>
                <tr>
                    <td><span>Offset</span></td>
                    <td><span>int</span></td>
                    <td><span>0</span></td>
                    <td><span>否</span></td>
                    <td><span>侵权线索列表起始位置，默认为 0</span></td>
                </tr>
                <tr>
                    <td><span>Limit</span></td>
                    <td><span>int</span></td>
                    <td><span>无</span></td>
                    <td><span>否</span></td>
                    <td><span>每次返回侵权线索列表数量，不填则表示返回全部</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <ul>
        <li>
            <p><span>返回数据（TortQueryResp）</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>字段</span></th>
                            <th><span>类型</span></th>
                            <th><span>描述</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>ClueList</span></td>
                            <td><span>[]ClueData</span></td>
                            <td><span>侵权线索列表</span></td>
                        </tr>
                        <tr>
                            <td><span>Count</span></td>
                            <td><span>int</span></td>
                            <td><span>监测线索总数量</span></td>
                        </tr>
                        <tr>
                            <td><span>Status</span></td>
                            <td><span>int</span></td>
                            <td><span>1：未开始，2：监测中 10: 已停止</span></td>
                        </tr>
                        <tr>
                            <td><span>RequestId</span></td>
                            <td><span>string</span></td>
                            <td><span>请求Id</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
    </ul>
    <p><span>ClueData 结构如下：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>ClueId</span></td>
                    <td><span>int</span></td>
                    <td><span>线索Id</span></td>
                </tr>
                <tr>
                    <td><span>PlayUrl</span></td>
                    <td><span>string</span></td>
                    <td><span>侵权作品链接</span></td>
                </tr>
                <tr>
                    <td><span>Title</span></td>
                    <td><span>string</span></td>
                    <td><span>侵权作品标题</span></td>
                </tr>
                <tr>
                    <td><span>PubTime</span></td>
                    <td><span>string</span></td>
                    <td><span>侵权作品发布时间</span></td>
                </tr>
                <tr>
                    <td><span>Platform</span></td>
                    <td><span>string</span></td>
                    <td><span>侵权作品所属平台</span></td>
                </tr>
                <tr>
                    <td><span>Author</span></td>
                    <td><span>string</span></td>
                    <td><span>侵权作品作者</span></td>
                </tr>
                <tr>
                    <td><span>FindTime</span></td>
                    <td><span>string</span></td>
                    <td><span>发现侵权时间</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <p>&nbsp;</p>
    <ul>
        <li>
            <p><span>示例</span></p>
            <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="go"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="go"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
            </div>
            <div class="CodeMirror-measure"></div>
            <div style="position: relative; z-index: 1;"></div>
            <div class="CodeMirror-code" role="presentation" style="">
                <div class="CodeMirror-activeline" style="position: relative;">
                    <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
                    <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
                    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">zxlSDK</span>, <span class="cm-variable">err</span> :<span class="cm-operator">=</span> <span class="cm-variable">zxl_go_sdk</span><span class="cm-number">.</span><span class="cm-variable">NewZxlImpl</span>(<span class="cm-variable">appId</span>, <span class="cm-variable">appKey</span>)</span></pre>
                </div>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-keyword">if</span> <span class="cm-variable">err</span> <span class="cm-operator">!=</span> <span class="cm-atom">nil</span> {</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">fmt</span><span class="cm-number">.</span><span class="cm-variable">Println</span>(<span class="cm-string">"初始化 SDK 错误"</span>)</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-keyword">return</span></span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">}</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">tortQuery</span> :<span class="cm-operator">=</span> <span class="cm-variable">zxl_go_sdk</span><span class="cm-number">.</span><span class="cm-variable">TortQuery</span>{</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">TaskId</span>: <span class="cm-string">""</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">}</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">resp</span>, <span class="cm-variable">err</span> :<span class="cm-operator">=</span> <span class="cm-variable">zxlSDK</span><span class="cm-number">.</span><span class="cm-variable">QueryTortTaskResult</span>(<span class="cm-variable">tortQuery</span>, <span class="cm-number">5</span> <span class="cm-operator">*</span> <span class="cm-variable">time</span><span class="cm-number">.</span><span class="cm-variable">Second</span>)</span></pre>
            </div>
            </div>
            </div>
            </div>
            </div>
            <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 242px;"></div>
            <div class="CodeMirror-gutters" style="display: none; height: 242px;"></div>
            </div>
            </div>
            </pre>
            <p>&nbsp;</p>
        </li>
    </ul>
    <p>&nbsp;</p>
    <h1 id='确权任务'><span>确权任务</span></h1>
    <h2 id='发起数字版权确权请求'><span>发起数字版权确权请求</span></h2>
    <ul>
        <li>
            <p><span>方法原型</span></p>
            <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
            </div>
            <div class="CodeMirror-measure"></div>
            <div style="position: relative; z-index: 1;"></div>
            <div class="CodeMirror-code" role="presentation">
                <div class="CodeMirror-activeline" style="position: relative;">
                    <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
                    <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
                    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">SubmitDciClaim</span>(<span class="cm-variable">dci</span> <span class="cm-variable">DciClaim</span>, <span class="cm-variable">timeout</span> <span class="cm-variable">time</span>.<span class="cm-variable">Duration</span>) (<span class="cm-variable">DciClaimResp</span>, <span class="cm-variable">error</span>)</span></pre>
                </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 44px;"></div>
            <div class="CodeMirror-gutters" style="display: none; height: 44px;"></div>
            </div>
            </div>
            </pre>
        </li>
        <li>
            <p><span>参数说明</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><em><span>参数名</span></em></th>
                            <th><em><span>参数类型</span></em></th>
                            <th><em><span>默认值</span></em></th>
                            <th><em><span>是否必填</span></em></th>
                            <th><em><span>参数描述</span></em></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>dciClaim</span></td>
                            <td><span>DciClaim</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>确权信息</span></td>
                        </tr>
                        <tr>
                            <td><span>timeOut</span></td>
                            <td><span>time.Duration</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>超时时间</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
    </ul>
    <p><span> DciClaim 的结构如下：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>参数名</span></th>
                    <th><span>参数类型</span></th>
                    <th><span>默认值</span></th>
                    <th><span>是否必填</span></th>
                    <th><span>参数描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>DciName</span></td>
                    <td><span>string</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>作品名字</span></td>
                </tr>
                <tr>
                    <td><span>ProposerEmail</span></td>
                    <td><span>string</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>申请人邮箱</span></td>
                </tr>
                <tr>
                    <td><span>ProposerSk</span></td>
                    <td><span>string</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>申请人私钥</span></td>
                </tr>
                <tr>
                    <td><span>DciType</span></td>
                    <td><span>DciType</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>作品类型（见下文 DciType）</span></td>
                </tr>
                <tr>
                    <td><span>DciCreateProperty</span></td>
                    <td><span>DciCreateProperty</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>作品创作属性（见下文 DciCreateProperty）</span></td>
                </tr>
                <tr>
                    <td><span>DciUrl</span></td>
                    <td><span>string</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>作品url</span></td>
                </tr>
                <tr>
                    <td><span>AuthorList</span></td>
                    <td><span>[]DciAuthor</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>作品作者信息</span></td>
                </tr>
                <tr>
                    <td><span>RightList</span></td>
                    <td><span>[]DciRight</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>权利人信息列表</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <p><span>  AuthorList 结构如下：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>参数名</span></th>
                    <th><span>参数类型</span></th>
                    <th><span>默认值</span></th>
                    <th><span>是否必填</span></th>
                    <th><span>备注</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>AuthorType</span></td>
                    <td><span>AuthorType</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>作者类型（见下文AuthorType）</span></td>
                </tr>
                <tr>
                    <td><span>AuthorName</span></td>
                    <td><span>string</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>作者名字</span></td>
                </tr>
                <tr>
                    <td><span>AuthorIdCard</span></td>
                    <td><span>string</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>作者证件号</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <p><span>  RightList 结构如下：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段名</span></th>
                    <th><span>类型</span></th>
                    <th><span>默认值</span></th>
                    <th><span>是否必填</span></th>
                    <th><span>备注</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>Type</span></td>
                    <td><span>RightType</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>权利类型（见下文 RihtType）</span></td>
                </tr>
                <tr>
                    <td><span>RighterInfoList</span></td>
                    <td><span>[]DciRighter</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>权利人列表</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <p><span>  DciRighter 结构如下：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段名</span></th>
                    <th><span>类型</span></th>
                    <th><span>默认值</span></th>
                    <th><span>是否必填</span></th>
                    <th><span>备注</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>RighterEmail</span></td>
                    <td><span>string</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>权利人邮箱</span></td>
                </tr>
                <tr>
                    <td><span>RighterType</span></td>
                    <td><span>RighterType</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>权利人类型（见下文 RighterType）</span></td>
                </tr>
                <tr>
                    <td><span>RighterName</span></td>
                    <td><span>string</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>权利人名字</span></td>
                </tr>
                <tr>
                    <td><span>RighterIdCard</span></td>
                    <td><span>string</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>权利人证件号</span></td>
                </tr>
                <tr>
                    <td><span>RighterGainedWay</span></td>
                    <td><span>int</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>枚举获取途径</span></td>
                </tr>
                <tr>
                    <td><span>RighterSk</span></td>
                    <td><span>string</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>权利人的私钥</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <p><span>  </span></p>
    <ul>
        <li>
            <p><span>枚举类型说明</span></p>
            <p><span>DciType（作品类型）：</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>编码</span></th>
                            <th><span>常量</span></th>
                            <th><span>备注</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>1</span></td>
                            <td><span>DCI_TYPE_TEXT</span></td>
                            <td><span>文字作品</span></td>
                        </tr>
                        <tr>
                            <td><span>2</span></td>
                            <td><span>DCI_TYPE_MUSIC</span></td>
                            <td><span>音乐作品</span></td>
                        </tr>
                        <tr>
                            <td><span>3</span></td>
                            <td><span>DCI_TYPE_ART_ARCH</span></td>
                            <td><span>美术或建筑作品</span></td>
                        </tr>
                        <tr>
                            <td><span>4</span></td>
                            <td><span>DCI_TYPE_FILMING</span></td>
                            <td><span>摄影作品</span></td>
                        </tr>
                        <tr>
                            <td><span>5</span></td>
                            <td><span>DCI_TYPE_AUDIOVISUAL</span></td>
                            <td><span>视听作品</span></td>
                        </tr>
                        <tr>
                            <td><span>6</span></td>
                            <td><span>DCI_TYPE_PIC_MODEL</span></td>
                            <td><span>设计图或示意图等图形模型作品</span></td>
                        </tr>
                        <tr>
                            <td><span>7</span></td>
                            <td><span>DCI_TYPE_SOFTWARE</span></td>
                            <td><span>计算机软件</span></td>
                        </tr>
                        <tr>
                            <td><span>8</span></td>
                            <td><span>DCI_TYPE_OTHER</span></td>
                            <td><span>符合作品特征的其他智力成果</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
            <p><span>GainedWay（权利获取途径）：</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>编码</span></th>
                            <th><span>常量</span></th>
                            <th><span>备注</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>1</span></td>
                            <td><span>GAINED_WAY_ORIGINAL</span></td>
                            <td><span>原始</span></td>
                        </tr>
                        <tr>
                            <td><span>2</span></td>
                            <td><span>GAINED_WAY_ACCEPT</span></td>
                            <td><span>承受</span></td>
                        </tr>
                        <tr>
                            <td><span>3</span></td>
                            <td><span>GAINED_WAY_INHERIT</span></td>
                            <td><span>继承</span></td>
                        </tr>
                        <tr>
                            <td><span>4</span></td>
                            <td><span>GAINED_WAY_OTHER</span></td>
                            <td><span>其他</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
            <p><span>DciCreateProperty（作品创作属性）：</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>编码</span></th>
                            <th><span>常量</span></th>
                            <th><span>备注</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>1</span></td>
                            <td><span>DCI_CREATE_PROPERTY_ORIGINAL</span></td>
                            <td><span>原创</span></td>
                        </tr>
                        <tr>
                            <td><span>2</span></td>
                            <td><span>DCI_CREATE_PROPERTY_ADAPT</span></td>
                            <td><span>改编</span></td>
                        </tr>
                        <tr>
                            <td><span>3</span></td>
                            <td><span>DCI_CREATE_PROPERTY_TRANSLATE</span></td>
                            <td><span>翻译</span></td>
                        </tr>
                        <tr>
                            <td><span>4</span></td>
                            <td><span>DCI_CREATE_PROPERTY_COLLECTION</span></td>
                            <td><span>汇编</span></td>
                        </tr>
                        <tr>
                            <td><span>5</span></td>
                            <td><span>DCI_CREATE_PROPERTY_COMMENT</span></td>
                            <td><span>注释</span></td>
                        </tr>
                        <tr>
                            <td><span>6</span></td>
                            <td><span>DCI_CREATE_PROPERTY_TIDY</span></td>
                            <td><span>整理</span></td>
                        </tr>
                        <tr>
                            <td><span>7</span></td>
                            <td><span>DCI_CREATE_PROPERTY_OTHER</span></td>
                            <td><span>其他</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
            <p><span>RightType（权利类型）：</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>编码</span></th>
                            <th><span>常量</span></th>
                            <th><span>备注</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>1</span></td>
                            <td><span>RIGHT_TYPE_PUBLIC</span></td>
                            <td><span>发表权</span></td>
                        </tr>
                        <tr>
                            <td><span>2</span></td>
                            <td><span>RIGHT_TYPE_SIGN</span></td>
                            <td><span>署名权</span></td>
                        </tr>
                        <tr>
                            <td><span>3</span></td>
                            <td><span>RIGHT_TYPE_MODIFY</span></td>
                            <td><span>修改权</span></td>
                        </tr>
                        <tr>
                            <td><span>4</span></td>
                            <td><span>RIGHT_TYPE_FULL_PROTECT</span></td>
                            <td><span>保护权</span></td>
                        </tr>
                        <tr>
                            <td><span>5</span></td>
                            <td><span>RIGHT_TYPE_COPY</span></td>
                            <td><span>复制权</span></td>
                        </tr>
                        <tr>
                            <td><span>6</span></td>
                            <td><span>RIGHT_TYPE_DISTRIBUTION</span></td>
                            <td><span>发行权</span></td>
                        </tr>
                        <tr>
                            <td><span>7</span></td>
                            <td><span>RIGHT_TYPE_RENT</span></td>
                            <td><span>出租权</span></td>
                        </tr>
                        <tr>
                            <td><span>8</span></td>
                            <td><span>RIGHT_TYPE_DISPLAY</span></td>
                            <td><span>展览权</span></td>
                        </tr>
                        <tr>
                            <td><span>9</span></td>
                            <td><span>RIGHT_TYPE_SHOW</span></td>
                            <td><span>表演权</span></td>
                        </tr>
                        <tr>
                            <td><span>10</span></td>
                            <td><span>RIGHT_TYPE_PLAY</span></td>
                            <td><span>放映权</span></td>
                        </tr>
                        <tr>
                            <td><span>11</span></td>
                            <td><span>RIGHT_TYPE_BROADCAST</span></td>
                            <td><span>广播权</span></td>
                        </tr>
                        <tr>
                            <td><span>12</span></td>
                            <td><span>RIGHT_TYPE_NET_TRANS</span></td>
                            <td><span>信息网络传播权</span></td>
                        </tr>
                        <tr>
                            <td><span>13</span></td>
                            <td><span>RIGHT_TYPE_FILMING</span></td>
                            <td><span>摄制权</span></td>
                        </tr>
                        <tr>
                            <td><span>14</span></td>
                            <td><span>RIGHT_TYPE_ADAPT</span></td>
                            <td><span>改编权</span></td>
                        </tr>
                        <tr>
                            <td><span>15</span></td>
                            <td><span>RIGHT_TYPE_TRANSLATE</span></td>
                            <td><span>翻译权</span></td>
                        </tr>
                        <tr>
                            <td><span>16</span></td>
                            <td><span>RIGHT_TYPE_COLLECTION</span></td>
                            <td><span>汇编权</span></td>
                        </tr>
                        <tr>
                            <td><span>17</span></td>
                            <td><span>RIGHT_TYPE_OTHER</span></td>
                            <td><span>其他权利</span></td>
                        </tr>
                        <tr>
                            <td><span>18</span></td>
                            <td><span>RIGHT_TYPE_ALL</span></td>
                            <td><span>所有</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
            <blockquote>
                <p><span>注意：当前只支持编号 18 的</span><strong><span>所有</span></strong><span>项，其他权利会在后续支持</span></p>
            </blockquote>
            <p><span>RighterType（权利人类型）：</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>编码</span></th>
                            <th><span>常量</span></th>
                            <th><span>备注</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>1</span></td>
                            <td><span>RIGHTER_TYPE_NATURAL</span></td>
                            <td><span>自然人</span></td>
                        </tr>
                        <tr>
                            <td><span>2</span></td>
                            <td><span>RIGHTER_TYPE_LEGAL</span></td>
                            <td><span>法人</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
            <p><span>AuthorType（作者类型）：</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>编码</span></th>
                            <th><span>常量</span></th>
                            <th><span>备注</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>1</span></td>
                            <td><span>AUTHOR_TYPE_NATURAL</span></td>
                            <td><span>自然人</span></td>
                        </tr>
                        <tr>
                            <td><span>2</span></td>
                            <td><span>AUTHOR_TYPE_LEGAL</span></td>
                            <td><span>法人</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
    </ul>
    <p>&nbsp;</p>
    <blockquote>
        <p><span>DciClaim 的结构比较复杂，除了填充作品信息之外，还需要填充</span><strong><span>权利项</span></strong><span>（DciRight）和</span><strong><span>作者</span></strong><span>信息（DciAuthor），权利项中又包含</span><strong><span>权利人</span></strong><span>（DciRighter） 信息，具体用法看示例代码。</span></p>
        <p><span>另外需要注意</span></p>
        <ol start=''>
            <li><span>目前 DciRight 的 type 只能使用 RIGHT_TYPE_ALL 值</span></li>
            <li><span>sk 是 dciClaim 中 proposerEmail 所属账户的私钥</span></li>
        </ol>
    </blockquote>
    <p>&nbsp;</p>
    <ul>
        <li>
            <p><span>返回数据 （DciClaimResp）</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>字段</span></th>
                            <th><span>类型</span></th>
                            <th><span>描述</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>TaskId</span></td>
                            <td><span>String</span></td>
                            <td><span>确权任务 Id</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
        <li>
            <p><span>示例</span></p>
            <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="go" style="page-break-inside: unset;"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="go"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
            </div>
            <div class="CodeMirror-measure"></div>
            <div style="position: relative; z-index: 1;"></div>
            <div class="CodeMirror-code" role="presentation" style="">
                <div class="CodeMirror-activeline" style="position: relative;">
                    <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
                    <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
                    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">zxlSDK</span>, <span class="cm-variable">err</span> :<span class="cm-operator">=</span> <span class="cm-variable">zxl_go_sdk</span><span class="cm-number">.</span><span class="cm-variable">NewZxlImpl</span>(<span class="cm-variable">appId</span>, <span class="cm-variable">appKey</span>)</span></pre>
                </div>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-keyword">if</span> <span class="cm-variable">err</span> <span class="cm-operator">!=</span> <span class="cm-atom">nil</span> {</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-variable">fmt</span><span class="cm-number">.</span><span class="cm-variable">Println</span>(<span class="cm-string">"初始化 SDK 错误"</span>)</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>}</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-variable">righter</span> :<span class="cm-operator">=</span> <span class="cm-variable">zxl_go_sdk</span><span class="cm-number">.</span><span class="cm-variable">DciRighter</span>{</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-variable">RighterName</span>: <span class="cm-string">""</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-variable">RighterIdCard</span>: <span class="cm-string">""</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-variable">RighterEmail</span>: <span class="cm-string">""</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-variable">RighterGainedWay</span>: <span class="cm-variable">constants</span><span class="cm-number">.</span><span class="cm-variable">GAINED_WAY_ORIGINAL</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-variable">RighterSk</span>: <span class="cm-variable">sk</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-variable">RighterType</span>: <span class="cm-variable">constants</span><span class="cm-number">.</span><span class="cm-variable">RIGHTER_TYPE_LEGAL</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>}</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-variable">right</span> :<span class="cm-operator">=</span> <span class="cm-variable">zxl_go_sdk</span><span class="cm-number">.</span><span class="cm-variable">DciRight</span>{</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-variable">Type</span>: <span class="cm-variable">constants</span><span class="cm-number">.</span><span class="cm-variable">RIGHT_TYPE_ALL</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-variable">RighterInfoList</span>: []<span class="cm-variable">zxl_go_sdk</span><span class="cm-number">.</span><span class="cm-variable">DciRighter</span>{<span class="cm-variable">righter</span>},</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>}</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-variable">author</span> :<span class="cm-operator">=</span> <span class="cm-variable">zxl_go_sdk</span><span class="cm-number">.</span><span class="cm-variable">DciAuthor</span>{</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-variable">AuthorName</span>: <span class="cm-string">""</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-variable">AuthorIdCard</span>: <span class="cm-string">""</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-variable">AuthorType</span>: <span class="cm-variable">constants</span><span class="cm-number">.</span><span class="cm-variable">AUTHOR_TYPE_LEGAL</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>}</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-variable">dciClaim</span> :<span class="cm-operator">=</span> <span class="cm-variable">zxl_go_sdk</span><span class="cm-number">.</span><span class="cm-variable">DciClaim</span>{</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-variable">DciName</span>: <span class="cm-string">"图片作品1"</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-variable">DciUrl</span>: <span class="cm-string">"https://www.sina.com.cn/"</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-variable">ProposerEmail</span>: <span class="cm-string">""</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp;<span class="cm-variable">ProposerSk</span>: <span class="cm-variable">sk</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-variable">DciType</span>: <span class="cm-variable">constants</span><span class="cm-number">.</span><span class="cm-variable">DCI_TYPE_FILMING</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-variable">DciCreateProperty</span>: <span class="cm-variable">constants</span><span class="cm-number">.</span><span class="cm-variable">DCI_CREATE_PROPERTY_ADAPT</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-variable">RightInfoList</span>: []<span class="cm-variable">zxl_go_sdk</span><span class="cm-number">.</span><span class="cm-variable">DciRight</span>{<span class="cm-variable">right</span>},</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-variable">AuthorList</span>: []<span class="cm-variable">zxl_go_sdk</span><span class="cm-number">.</span><span class="cm-variable">DciAuthor</span>{<span class="cm-variable">author</span>},</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>}</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-variable">resp</span>, <span class="cm-variable">err</span> :<span class="cm-operator">=</span> <span class="cm-variable">zxlSDK</span><span class="cm-number">.</span><span class="cm-variable">SubmitDciClaim</span>(<span class="cm-variable">dciClaim</span>, <span class="cm-number">10</span> <span class="cm-operator">*</span> <span class="cm-variable">time</span><span class="cm-number">.</span><span class="cm-variable">Second</span>)</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-keyword">if</span> <span class="cm-variable">err</span> <span class="cm-operator">!=</span> <span class="cm-atom">nil</span> {</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-variable">fmt</span><span class="cm-number">.</span><span class="cm-variable">Printf</span>(<span class="cm-string">"提交确权请求出错 %+v"</span>, <span class="cm-variable">err</span>)</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-keyword">return</span></span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>}</span></pre>
            </div>
            </div>
            </div>
            </div>
            </div>
            <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 902px;"></div>
            <div class="CodeMirror-gutters" style="display: none; height: 902px;"></div>
            </div>
            </div>
            </pre>
        </li>
    </ul>
    <p>&nbsp;</p>
    <h2 id='用户查询确权结果'><span>用户查询确权结果</span></h2>
    <ul>
        <li>
            <p><span>方法原型</span></p>
            <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
            </div>
            <div class="CodeMirror-measure"></div>
            <div style="position: relative; z-index: 1;"></div>
            <div class="CodeMirror-code" role="presentation">
                <div class="CodeMirror-activeline" style="position: relative;">
                    <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
                    <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
                    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">QueryDciClaimResult</span>(<span class="cm-variable">dciQuery</span> <span class="cm-variable">DciClaimQuery</span>, <span class="cm-variable">timeout</span> <span class="cm-variable">time</span>.<span class="cm-variable">Duration</span>) (<span class="cm-variable">DciClaimQueryResp</span>, <span class="cm-variable">error</span>)</span></pre>
                </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 44px;"></div>
            <div class="CodeMirror-gutters" style="display: none; height: 44px;"></div>
            </div>
            </div>
            </pre>
        </li>
        <li>
            <p><span>参数说明（DciClaimQuery）</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><em><span>参数名</span></em></th>
                            <th><em><span>参数类型</span></em></th>
                            <th><em><span>默认值</span></em></th>
                            <th><em><span>是否必填</span></em></th>
                            <th><em><span>参数描述</span></em></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>TaskId</span></td>
                            <td><span>String</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>确权任务ID</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
    </ul>
    <p>&nbsp;</p>
    <ul>
        <li>
            <p><span>返回数据（DciClaimQueryResp）</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>字段</span></th>
                            <th><span>类型</span></th>
                            <th><span>描述</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>Status</span></td>
                            <td><span>int</span></td>
                            <td><span>确权任务状态值（2 处理中，4 搜索出来的图片url，疑似侵权中止流程 7 成功, 10 失败）</span></td>
                        </tr>
                        <tr>
                            <td><span>DciId</span></td>
                            <td><span>string</span></td>
                            <td><span>确权 id</span></td>
                        </tr>
                        <tr>
                            <td><span>Url</span></td>
                            <td><span>string</span></td>
                            <td><span>证书地址</span></td>
                        </tr>
                        <tr>
                            <td><span>TortSearchList</span></td>
                            <td><span>[]TortSearch</span></td>
                            <td><span>如果作品发生侵权，就会返回被侵权的作品地址</span></td>
                        </tr>
                        <tr>
                            <td><span>RecordTimestamp</span></td>
                            <td><span>int</span></td>
                            <td><span>确权时间戳</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
            <p><span>TortSearch 结构如下：</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>字段</span></th>
                            <th><span>类型</span></th>
                            <th><span>描述</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>Url</span></td>
                            <td><span>String</span></td>
                            <td><span>被确权作品 url</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
    </ul>
    <p><span>       </span></p>
    <ul>
        <li>
            <p><span>示例</span></p>
            <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java" style="page-break-inside: unset;"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
            </div>
            <div class="CodeMirror-measure"></div>
            <div style="position: relative; z-index: 1;"></div>
            <div class="CodeMirror-code" role="presentation" style="">
                <div class="CodeMirror-activeline" style="position: relative;">
                    <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
                    <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
                    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">zxlSDK</span>, <span class="cm-variable">err</span> :<span class="cm-operator">=</span> <span class="cm-variable">zxl_go_sdk</span>.<span class="cm-variable">NewZxlImpl</span>(<span class="cm-variable">appId</span>, <span class="cm-variable">appKey</span>)</span></pre>
                </div>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-keyword">if</span> <span class="cm-variable">err</span> <span class="cm-operator">!=</span> <span class="cm-variable">nil</span> {</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">fmt</span>.<span class="cm-variable">Println</span>(<span class="cm-string">"初始化 SDK 错误"</span>)</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">}</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">dciQuery</span> :<span class="cm-operator">=</span> <span class="cm-variable">zxl_go_sdk</span>.<span class="cm-variable">DciClaimQuery</span>{</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">TaskId</span>: <span class="cm-string">"2e845212-65b0-4b37-9770-d68394b5b8ee_5"</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">}</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">resp</span>, <span class="cm-variable">err</span> :<span class="cm-operator">=</span> <span class="cm-variable">zxlSDK</span>.<span class="cm-variable">QueryDciClaimResult</span>(<span class="cm-variable">dciQuery</span>, <span class="cm-number">10</span> <span class="cm-operator">*</span> <span class="cm-variable">time</span>.<span class="cm-variable">Second</span>)</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-keyword">if</span> <span class="cm-variable">err</span> <span class="cm-operator">!=</span> <span class="cm-variable">nil</span> {</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">fmt</span>.<span class="cm-variable">Printf</span>(<span class="cm-string">"确权查询出错 %+v"</span>, <span class="cm-variable">err</span>)</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-keyword">return</span></span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">}</span></pre>
            </div>
            </div>
            </div>
            </div>
            </div>
            <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 308px;"></div>
            <div class="CodeMirror-gutters" style="display: none; height: 308px;"></div>
            </div>
            </div>
            </pre>
        </li>
    </ul>
    <h1 id='Kv 服务'><span>Kv 服务</span></h1>
    <h2 id='Kv 存储'><span>Kv 存储</span></h2>
    <h3 id='方法原型-1'><span>方法原型</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">KvSave</span>(<span class="cm-variable">req</span> <span class="cm-variable">KvSaveReq</span>, <span class="cm-variable">timeout</span> <span class="cm-variable">time</span>.<span class="cm-variable">Duration</span>) (<span class="cm-operator">*</span><span class="cm-variable">KvSaveResp</span>, <span class="cm-variable">error</span>)</span></pre>
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
    </div>
    </div>
    </pre>
    <h3 id='参数说明-1'><span>参数说明</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>req</span></td>
                    <td><span>KvSaveReq</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>kv 存储请求</span></td>
                </tr>
                <tr>
                    <td><span>timeout</span></td>
                    <td><span>time.Duration</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>请求超时时间</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <p><span>KvSaveReq 结构如下：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>KvKey</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>kv 数据的 key</span></td>
                </tr>
                <tr>
                    <td><span>KvValue</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>kv 数据的 value</span></td>
                </tr>
                <tr>
                    <td><span>Sk</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>存储人的私钥（如果是代理用户，就是代理用户的私钥）</span></td>
                </tr>
                <tr>
                    <td><span>RepresentAppId</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>否</span></td>
                    <td><span>代理用户 appId</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <blockquote>
        <p><span>注：KvKey 要使用 appId: 开始，如果是代理用户，就使用 representAppId: 开头，</span>
            <span>例如 201107000400001:xxxxx</span></p>
    </blockquote>
    <h3 id='返回数据-1'><span>返回数据</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>resp</span></td>
                    <td><span>KvSaveResp</span></td>
                    <td><span>Kv 存储结果</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <p><span>KvSaveResp 结构如下：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>KvKey</span></td>
                    <td><span>String</span></td>
                    <td><span>kv 的 key</span></td>
                </tr>
                <tr>
                    <td><span>RequestId</span></td>
                    <td><span>String</span></td>
                    <td><span>请求的 requestId</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='示例-1'><span>示例</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="go"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="go"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation" style="">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">key</span> :<span class="cm-operator">=</span> <span class="cm-variable">appId</span> <span class="cm-operator">+</span> <span class="cm-string">":"</span> <span class="cm-operator">+</span> <span class="cm-string">""</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">req</span> :<span class="cm-operator">=</span> <span class="cm-variable">zxl_go_sdk</span><span class="cm-number">.</span><span class="cm-variable">KvSaveReq</span>{</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp;<span class="cm-variable">KvKey</span>: <span class="cm-variable">key</span>,</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp;<span class="cm-variable">KvValue</span>: <span class="cm-string">""</span>,</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp;<span class="cm-variable">Sk</span>: <span class="cm-variable">sK</span>,</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">}</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">resp</span>, <span class="cm-variable">err</span> :<span class="cm-operator">=</span> <span class="cm-variable">sdk</span><span class="cm-number">.</span><span class="cm-variable">KvSave</span>(<span class="cm-variable">req</span>, <span class="cm-number">5</span> <span class="cm-operator">*</span> <span class="cm-variable">time</span><span class="cm-number">.</span><span class="cm-variable">Second</span>)</span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 220px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 220px;"></div>
    </div>
    </div>
    </pre>
    <h2 id='查询 Kv'><span>查询 Kv</span></h2>
    <h3 id='方法原型-2'><span>方法原型</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">KvQuery</span>(<span class="cm-variable">req</span> <span class="cm-variable">KvQueryReq</span>, <span class="cm-variable">timeout</span> <span class="cm-variable">time</span>.<span class="cm-variable">Duration</span>) (<span class="cm-operator">*</span><span class="cm-variable">KvQueryResp</span>, <span class="cm-variable">error</span>) </span></pre>
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
    </div>
    </div>
    </pre>
    <h3 id='参数说明-2'><span>参数说明</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>req</span></td>
                    <td><span>KvQueryReq</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>Kv 查询请求</span></td>
                </tr>
                <tr>
                    <td><span>timeout</span></td>
                    <td><span>time.Duration</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>请求超时时间</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <p><span>KvQueryReq 结构如下：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>KvKey</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>kv 数据的 key</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='返回数据-2'><span>返回数据</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>resp</span></td>
                    <td><span>KvQueryResp</span></td>
                    <td><span>kv 数据查询结果</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <p><span>KvQueryResp 结构如下：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>KvKey</span></td>
                    <td><span>String</span></td>
                    <td><span>kv 数据的 key</span></td>
                </tr>
                <tr>
                    <td><span>KvValue</span></td>
                    <td><span>String</span></td>
                    <td><span>kv 数据的 value</span></td>
                </tr>
                <tr>
                    <td><span>CreateTime</span></td>
                    <td><span>String</span></td>
                    <td><span>kv 数据上链时间</span></td>
                </tr>
                <tr>
                    <td><span>Status</span></td>
                    <td><span>Integer</span></td>
                    <td><span>kv 数据状态（1：未上链，3：上链成功，4：上链失败）</span></td>
                </tr>
                <tr>
                    <td><span>RequestId</span></td>
                    <td><span>String</span></td>
                    <td><span>请求 requestId</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='示例-2'><span>示例</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation" style="">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">key</span> :<span class="cm-operator">=</span> <span class="cm-string">""</span></span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">queryReq</span> :<span class="cm-operator">=</span> <span class="cm-variable">zxl_go_sdk</span>.<span class="cm-variable">KvQueryReq</span>{</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <span class="cm-variable">KvKey</span>: <span class="cm-variable">key</span>,</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  }</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">queryResp</span>, <span class="cm-variable">err</span> :<span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">KvQuery</span>(<span class="cm-variable">queryReq</span>, <span class="cm-number">5</span> <span class="cm-operator">*</span> <span class="cm-variable">time</span>.<span class="cm-variable">Second</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 132px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 132px;"></div>
    </div>
    </div>
    </pre>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <h1 id='代理商服务'><span>代理商服务</span></h1>
    <h2 id='法人代理用户注册'><span>法人代理用户注册</span></h2>
    <ul>
        <li>
            <p><span>方法原型</span></p>
            <ul>
                <li>
                    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang=""><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang=""><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
                    </div>
                    <div class="CodeMirror-measure"></div>
                    <div style="position: relative; z-index: 1;"></div>
                    <div class="CodeMirror-code" role="presentation">
                        <div class="CodeMirror-activeline" style="position: relative;">
                            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
                            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
                            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">RegisterUser(agentUser AgentUser, timeout time.Duration) (bool, error)</span></pre>
                        </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 44px;"></div>
                    <div class="CodeMirror-gutters" style="display: none; height: 44px;"></div>
                    </div>
                    </div>
                    </pre>
                </li>
            </ul>
        </li>
        <li>
            <p><span>参数说明</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>参数名</span></th>
                            <th><span>参数类型</span></th>
                            <th><span>默认值</span></th>
                            <th><span>参数描述</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>agentUser</span></td>
                            <td><span>AgentUser</span></td>
                            <td><span>无</span></td>
                            <td><span>注册用户信息</span></td>
                        </tr>
                        <tr>
                            <td><span>timeout</span></td>
                            <td><span>time.Duration</span></td>
                            <td><span>无</span></td>
                            <td><span>超时时间</span></td>
                        </tr>
                        <tr>
                            <td><span>接口参数传入</span><code>AgentUser</code><span>类型对象：</span></td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody>
                </table>
            </figure>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><em><span>参数名</span></em></th>
                            <th><em><span>参数类型</span></em></th>
                            <th><em><span>默认值</span></em></th>
                            <th><em><span>是否必填</span></em></th>
                            <th><em><span>参数描述</span></em></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>RepresentEmail</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>代理企业注册邮箱</span></td>
                        </tr>
                        <tr>
                            <td><span>Pwd</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>代理企业注册密码</span></td>
                        </tr>
                        <tr>
                            <td><span>EpName</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>代理企业名称</span></td>
                        </tr>
                        <tr>
                            <td><span>CreditCode</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>代理企业信用代码</span></td>
                        </tr>
                        <tr>
                            <td><span>LicenseFile</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>代理企业营业执照图片路径</span></td>
                        </tr>
                        <tr>
                            <td><span>Representative</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>代理企业法人代表姓名</span></td>
                        </tr>
                        <tr>
                            <td><span>LetterFile</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>否</span></td>
                            <td><span>至信链委托公函图片路径</span></td>
                        </tr>
                        <tr>
                            <td><span>Category</span></td>
                            <td><span>int</span></td>
                            <td><span>无</span></td>
                            <td><span>否</span></td>
                            <td><span>代理企业行业信息，枚举</span></td>
                        </tr>
                        <tr>
                            <td><span>Contact</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>联系人姓名</span></td>
                        </tr>
                        <tr>
                            <td><span>Title</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>否</span></td>
                            <td><span>联系人职位</span></td>
                        </tr>
                        <tr>
                            <td><span>Mobile</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>联系人手机号</span></td>
                        </tr>
                        <tr>
                            <td><span>Idcard</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>联系人身份证号码</span></td>
                        </tr>
                        <tr>
                            <td><span>CardFrontFile</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>联系人身份证正面图片路径</span></td>
                        </tr>
                        <tr>
                            <td><span>CardBackendFile</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>联系人身份证反面图片路径</span></td>
                        </tr>
                        <tr>
                            <td><span>UserType</span></td>
                            <td><span>int</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>用户类型，填入：1（法人）</span></td>
                        </tr>
                        <tr>
                            <td><span>PlatformName</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>否</span></td>
                            <td><span>接入平台名称</span></td>
                        </tr>
                        <tr>
                            <td><span>PlatformUrl</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>否</span></td>
                            <td><span>接入平台地址</span></td>
                        </tr>
                        <tr>
                            <td><span>BusinessType</span></td>
                            <td><span>int</span></td>
                            <td><span>4</span></td>
                            <td><span>否</span></td>
                            <td><span>平台业务类型 (1:金融类 2:版权类 3:其他类 4:未填写)，默认是未填写</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
            <p><span>     </span></p>
        </li>
        <li>
            <p><span>返回值</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>返回值类型</span></th>
                            <th><span>返回值描述</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>bool</span></td>
                            <td><span>注册成功与否，成功true，失败抛出对应异常信息</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
        <li>
            <p><span>示例</span></p>
            <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="go" style="page-break-inside: unset;"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="go"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
            </div>
            <div class="CodeMirror-measure"></div>
            <div style="position: relative; z-index: 1;"></div>
            <div class="CodeMirror-code" role="presentation" style="">
                <div class="CodeMirror-activeline" style="position: relative;">
                    <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
                    <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
                    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
                </div>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业用户注册</span></span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">zxlSDK</span>, <span class="cm-variable">err</span> :<span class="cm-operator">=</span> <span class="cm-variable">zxl_go_sdk</span><span class="cm-number">.</span><span class="cm-variable">NewZxlImpl</span>(<span class="cm-variable">appId</span>, <span class="cm-variable">appKey</span>)</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-keyword">if</span> <span class="cm-variable">err</span> <span class="cm-operator">!=</span> <span class="cm-atom">nil</span> {</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">fmt</span><span class="cm-number">.</span><span class="cm-variable">Println</span>(<span class="cm-string">"初始化 SDK 错误"</span>)</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">}</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">user</span> :<span class="cm-operator">=</span> <span class="cm-variable">zxl_go_sdk</span><span class="cm-number">.</span><span class="cm-variable">AgentUser</span>{</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">EpName</span>: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<span class="cm-string">"XX集团有限公司"</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">Pwd</span>: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <span class="cm-string">"123456aaa"</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">RepresentEmail</span>: &nbsp;<span class="cm-string">"zxin3@admin.com"</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">Representative</span>: &nbsp;<span class="cm-string">"XXX"</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">CreditCode</span>: &nbsp; &nbsp; &nbsp;<span class="cm-string">"914400002311176XXX"</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">Contact</span>: &nbsp; &nbsp; &nbsp; &nbsp; <span class="cm-string">"XXX"</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">Idcard</span>: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<span class="cm-string">"140502197311051XXX"</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">Mobile</span>: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<span class="cm-string">"18600213476"</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">PlatformName</span>: &nbsp; &nbsp;<span class="cm-string">"小说平台"</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">PlatformUrl</span>: &nbsp; &nbsp; <span class="cm-string">"https://www.xiaoshuo.com"</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">BusinessType</span>: &nbsp; &nbsp;<span class="cm-variable">constants</span><span class="cm-number">.</span><span class="cm-variable">BUSINESS_COPYRIGHT</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">UserType</span>: &nbsp; &nbsp; &nbsp; &nbsp;<span class="cm-variable">constants</span><span class="cm-number">.</span><span class="cm-variable">USER_LEGAL_PERSON</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">CardFrontFile</span>: &nbsp; <span class="cm-string">"/root/workspace/zx-demo/idcard_front.jpeg"</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">CardBackendFile</span>: <span class="cm-string">"/root/workspace/zx-demo/idcard_front.jpeg"</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">LicenseFile</span>: &nbsp; &nbsp; <span class="cm-string">"/root/workspace/zx-demo/license.jpeg"</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">}</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">result</span>, <span class="cm-variable">err</span> :<span class="cm-operator">=</span> <span class="cm-variable">zxlSDK</span><span class="cm-number">.</span><span class="cm-variable">RegisterUser</span>(<span class="cm-variable">user</span>, <span class="cm-number">5</span><span class="cm-operator">*</span><span class="cm-variable">time</span><span class="cm-number">.</span><span class="cm-variable">Second</span>)</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-keyword">if</span> <span class="cm-variable">err</span> <span class="cm-operator">!=</span> <span class="cm-atom">nil</span> {</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">fmt</span><span class="cm-number">.</span><span class="cm-variable">Println</span>(<span class="cm-string">"注册用户错误: "</span> <span class="cm-operator">+</span> <span class="cm-variable">err</span><span class="cm-number">.</span><span class="cm-variable">Error</span>())</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-keyword">return</span></span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">}</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
            </div>
            </div>
            </div>
            </div>
            </div>
            <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 682px;"></div>
            <div class="CodeMirror-gutters" style="display: none; height: 682px;"></div>
            </div>
            </div>
            </pre>
        </li>
    </ul>
    <p>&nbsp;</p>
    <h2 id='自然人代理用户注册'><span>自然人代理用户注册</span></h2>
    <ul>
        <li>
            <p><span>方法原型</span></p>
            <ul>
                <li>
                    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang=""><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang=""><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
                    </div>
                    <div class="CodeMirror-measure"></div>
                    <div style="position: relative; z-index: 1;"></div>
                    <div class="CodeMirror-code" role="presentation">
                        <div class="CodeMirror-activeline" style="position: relative;">
                            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
                            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
                            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">RegisterUser(agentUser AgentUser, timeout time.Duration) (bool, error)</span></pre>
                        </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 44px;"></div>
                    <div class="CodeMirror-gutters" style="display: none; height: 44px;"></div>
                    </div>
                    </div>
                    </pre>
                </li>
            </ul>
        </li>
        <li>
            <p><span>参数说明</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>参数名</span></th>
                            <th><span>参数类型</span></th>
                            <th><span>默认值</span></th>
                            <th><span>参数描述</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>agentUser</span></td>
                            <td><span>AgentUser</span></td>
                            <td><span>无</span></td>
                            <td><span>注册用户信息</span></td>
                        </tr>
                        <tr>
                            <td><span>timeout</span></td>
                            <td><span>time.Duration</span></td>
                            <td><span>无</span></td>
                            <td><span>超时时间</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
            <p><span>接口参数传入</span><code>AgentUser</code><span>类型对象：</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><em><span>参数名</span></em></th>
                            <th><em><span>参数类型</span></em></th>
                            <th><em><span>默认值</span></em></th>
                            <th><em><span>是否必填</span></em></th>
                            <th><em><span>参数描述</span></em></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>RepresentEmail</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>代理用户注册邮箱</span></td>
                        </tr>
                        <tr>
                            <td><span>Pwd</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>代理用户注册密码</span></td>
                        </tr>
                        <tr>
                            <td><span>PersonName</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>代理用户姓名</span></td>
                        </tr>
                        <tr>
                            <td><span>Mobile</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>联系人手机号</span></td>
                        </tr>
                        <tr>
                            <td><span>Idcard</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>联系人身份证号码</span></td>
                        </tr>
                        <tr>
                            <td><span>CardFrontFile</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>联系人身份证正面图片路径</span></td>
                        </tr>
                        <tr>
                            <td><span>CardBackendFile</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>联系人身份证反面图片路径</span></td>
                        </tr>
                        <tr>
                            <td><span>UserType</span></td>
                            <td><span>int</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>用户类型，填入：2（自然人）</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
            <p><span>     </span></p>
        </li>
        <li>
            <p><span>返回值</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>返回值类型</span></th>
                            <th><span>返回值描述</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>bool</span></td>
                            <td><span>注册成功与否，成功true，失败抛出对应异常信息</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
        <li>
            <p><span>示例</span></p>
            <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="go" style="page-break-inside: unset;"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="go"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
            </div>
            <div class="CodeMirror-measure"></div>
            <div style="position: relative; z-index: 1;"></div>
            <div class="CodeMirror-code" role="presentation" style="">
                <div class="CodeMirror-activeline" style="position: relative;">
                    <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
                    <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
                    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
                </div>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 自然人用户注册</span></span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">zxlSDK</span>, <span class="cm-variable">err</span> :<span class="cm-operator">=</span> <span class="cm-variable">zxl_go_sdk</span><span class="cm-number">.</span><span class="cm-variable">NewZxlImpl</span>(<span class="cm-variable">appId</span>, <span class="cm-variable">appKey</span>)</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-keyword">if</span> <span class="cm-variable">err</span> <span class="cm-operator">!=</span> <span class="cm-atom">nil</span> {</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">fmt</span><span class="cm-number">.</span><span class="cm-variable">Println</span>(<span class="cm-string">"初始化 SDK 错误"</span>)</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">}</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">user</span> :<span class="cm-operator">=</span> <span class="cm-variable">zxl_go_sdk</span><span class="cm-number">.</span><span class="cm-variable">AgentUser</span>{</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">PersonName</span>: &nbsp; &nbsp; &nbsp;<span class="cm-string">"XXX"</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">Pwd</span>: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <span class="cm-string">"123456aaa"</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">RepresentEmail</span>: &nbsp;<span class="cm-string">"zxin_person3@admin.com"</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">Idcard</span>: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<span class="cm-string">"320681199209029XXX"</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">Mobile</span>: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<span class="cm-string">"18600213476"</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">UserType</span>: &nbsp; &nbsp; &nbsp; &nbsp;<span class="cm-variable">constants</span><span class="cm-number">.</span><span class="cm-variable">USER_NATURAL_PERSON</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">CardFrontFile</span>: &nbsp; <span class="cm-string">"/root/workspace/zx-demo/idcard_front.jpeg"</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">CardBackendFile</span>: <span class="cm-string">"/root/workspace/zx-demo/idcard_front.jpeg"</span>,</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">}</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">result</span>, <span class="cm-variable">err</span> :<span class="cm-operator">=</span> <span class="cm-variable">zxlSDK</span><span class="cm-number">.</span><span class="cm-variable">RegisterUser</span>(<span class="cm-variable">user</span>, <span class="cm-number">5</span><span class="cm-operator">*</span><span class="cm-variable">time</span><span class="cm-number">.</span><span class="cm-variable">Second</span>)</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-keyword">if</span> <span class="cm-variable">err</span> <span class="cm-operator">!=</span> <span class="cm-atom">nil</span> {</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">fmt</span><span class="cm-number">.</span><span class="cm-variable">Println</span>(<span class="cm-string">"注册用户错误: "</span> <span class="cm-operator">+</span> <span class="cm-variable">err</span><span class="cm-number">.</span><span class="cm-variable">Error</span>())</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-keyword">return</span></span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">}</span></pre>
                <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
            </div>
            </div>
            </div>
            </div>
            </div>
            <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 528px;"></div>
            <div class="CodeMirror-gutters" style="display: none; height: 528px;"></div>
            </div>
            </div>
            </pre>
        </li>
    </ul>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <h2 id='查询代理用户审核结果'><span>查询代理用户审核结果</span></h2>
    <ul>
        <li>
            <p><span>方法原型</span></p>
            <ul>
                <li>
                    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang=""><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang=""><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
                    </div>
                    <div class="CodeMirror-measure"></div>
                    <div style="position: relative; z-index: 1;"></div>
                    <div class="CodeMirror-code" role="presentation">
                        <div class="CodeMirror-activeline" style="position: relative;">
                            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
                            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
                            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">SelectEpInfo(email string, timeout time.Duration) (ReviewData, error)</span></pre>
                        </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
                    <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
                    </div>
                    </div>
                    </pre>
                </li>
            </ul>
        </li>
        <li>
            <p><span>参数说明</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><em><span>参数名</span></em></th>
                            <th><em><span>参数类型</span></em></th>
                            <th><em><span>默认值</span></em></th>
                            <th><em><span>是否必填</span></em></th>
                            <th><em><span>参数描述</span></em></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>email</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>代理用户注册账户的邮箱账号</span></td>
                        </tr>
                        <tr>
                            <td><span>timeout</span></td>
                            <td><span>time.Duration</span></td>
                            <td>&nbsp;</td>
                            <td><span>超时时间</span></td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
        <li>
            <p><span>返回值</span></p>
            <p><span>返回</span><code>ReviewData</code><span>类型对象：</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>字段</span></th>
                            <th><span>类型</span></th>
                            <th><span>描述</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>AppId</span></td>
                            <td><span>string</span></td>
                            <td><span>应用Id,当前字段的意义取决于state字段</span></td>
                        </tr>
                        <tr>
                            <td><span>AppKey</span></td>
                            <td><span>string</span></td>
                            <td><span>应用key</span></td>
                        </tr>
                        <tr>
                            <td><span>State</span></td>
                            <td><span>int</span></td>
                            <td><span>认证是否通过：[1:待审核 2:认证通过 3:认证不通过]，状态值为2时appId字段才有意义</span></td>
                        </tr>
                        <tr>
                            <td><span>Reason</span></td>
                            <td><span>string</span></td>
                            <td><span>认证不通过的原因，若通过则为空串</span></td>
                        </tr>
                        <tr>
                            <td><span>AgentCode</span></td>
                            <td><span>string</span></td>
                            <td><span>代理商代码，仅在用户是代理商时才有意义</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
    </ul>
    <p>&nbsp;</p>
    <h2 id='上传代理用户的证书'><span>上传代理用户的证书</span></h2>
    <ul>
        <li>
            <p><span>方法原型</span></p>
            <ul>
                <li>
                    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang=""><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang=""><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
                    </div>
                    <div class="CodeMirror-measure"></div>
                    <div style="position: relative; z-index: 1;"></div>
                    <div class="CodeMirror-code" role="presentation">
                        <div class="CodeMirror-activeline" style="position: relative;">
                            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
                            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
                            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">BindRepresentUserCert(representAppId, representAppKey, representPk, representSk string) (bool, error)</span></pre>
                        </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 44px;"></div>
                    <div class="CodeMirror-gutters" style="display: none; height: 44px;"></div>
                    </div>
                    </div>
                    </pre>
                </li>
            </ul>
        </li>
        <li>
            <p><span>参数说明</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><em><span>参数名</span></em></th>
                            <th><em><span>参数类型</span></em></th>
                            <th><em><span>默认值</span></em></th>
                            <th><em><span>是否必填</span></em></th>
                            <th><em><span>参数描述</span></em></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>representAppId</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>代理用户appId</span></td>
                        </tr>
                        <tr>
                            <td><span>representAppKey</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>代理用户appKey</span></td>
                        </tr>
                        <tr>
                            <td><span>representPk</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>代理用户生成的公钥</span></td>
                        </tr>
                        <tr>
                            <td><span>representSk</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>代理用户生成的私钥</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
            <p><span>     </span></p>
        </li>
        <li>
            <p><span>返回值</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>返回值类型</span></th>
                            <th><span>返回值描述</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>bool</span></td>
                            <td><span>上传结果，成功true，失败false</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
    </ul>
    <p>&nbsp;</p>
    <h2 id='更新代理用户证书'><span>更新代理用户证书</span></h2>
    <ul>
        <li>
            <p><span>方法原型</span></p>
            <ul>
                <li>
                    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang=""><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang=""><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
                    </div>
                    <div class="CodeMirror-measure"></div>
                    <div style="position: relative; z-index: 1;"></div>
                    <div class="CodeMirror-code" role="presentation">
                        <div class="CodeMirror-activeline" style="position: relative;">
                            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
                            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
                            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">UpdateRepresentUserCert(representAppId, representAppKey, representPk, representSk string) (bool, error)</span></pre>
                        </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 44px;"></div>
                    <div class="CodeMirror-gutters" style="display: none; height: 44px;"></div>
                    </div>
                    </div>
                    </pre>
                </li>
            </ul>
        </li>
        <li>
            <p><span>参数说明</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><em><span>参数名</span></em></th>
                            <th><em><span>参数类型</span></em></th>
                            <th><em><span>默认值</span></em></th>
                            <th><em><span>是否必填</span></em></th>
                            <th><em><span>参数描述</span></em></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>representAppId</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>代理用户appId</span></td>
                        </tr>
                        <tr>
                            <td><span>representAppKey</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>代理用户appKey</span></td>
                        </tr>
                        <tr>
                            <td><span>representPk</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>代理用户生成的公钥</span></td>
                        </tr>
                        <tr>
                            <td><span>representSk</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>代理用户生成的私钥</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
            <p><span>     </span></p>
        </li>
        <li>
            <p><span>返回值</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>返回值类型</span></th>
                            <th><span>返回值描述</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>bool</span></td>
                            <td><span>更新结果，成功true，失败false</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
    </ul>
    <p>&nbsp;</p>
    <h2 id='代理商模式下的用户存证'><span>代理商模式下的用户存证</span></h2>
    <ul>
        <li>
            <p><span>方法原型</span></p>
            <ul>
                <li>
                    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang=""><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang=""><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
                    </div>
                    <div class="CodeMirror-measure"></div>
                    <div style="position: relative; z-index: 1;"></div>
                    <div class="CodeMirror-code" role="presentation">
                        <div class="CodeMirror-activeline" style="position: relative;">
                            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
                            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
                            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">RepresentSave(evHash, extendInfo, representSk, representAppId string, timeout time.Duration) (*EvSaveResult, error)</span></pre>
                        </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 44px;"></div>
                    <div class="CodeMirror-gutters" style="display: none; height: 44px;"></div>
                    </div>
                    </div>
                    </pre>
                </li>
            </ul>
        </li>
        <li>
            <p><span>参数说明</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><em><span>参数名</span></em></th>
                            <th><em><span>参数类型</span></em></th>
                            <th><em><span>默认值</span></em></th>
                            <th><em><span>是否必填</span></em></th>
                            <th><em><span>参数描述</span></em></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>evHash</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>存证hash</span></td>
                        </tr>
                        <tr>
                            <td><span>extendInfo</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>拓展信息</span></td>
                        </tr>
                        <tr>
                            <td><span>representSk</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>代理用户私钥（用于对上链数据签名）</span></td>
                        </tr>
                        <tr>
                            <td><span>representPk</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>代理用户公钥</span></td>
                        </tr>
                        <tr>
                            <td><span>representAppId</span></td>
                            <td><span>string</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>代理用户的应用id</span></td>
                        </tr>
                        <tr>
                            <td><span>timeout</span></td>
                            <td><span>time.Duration</span></td>
                            <td>&nbsp;</td>
                            <td><span>超时时间</span></td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
        <li>
            <p><span>返回值</span></p>
            <p><span>返回</span><code>EvSaveResult</code><span>类型对象，</span><code>EvSaveResult</code><span>对象说明：</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>字段</span></th>
                            <th><span>类型</span></th>
                            <th><span>描述</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>EvId</span></td>
                            <td><span>string</span></td>
                            <td><span>存证Id</span></td>
                        </tr>
                        <tr>
                            <td><span>TxHash</span></td>
                            <td><span>string</span></td>
                            <td><span>交易hash</span></td>
                        </tr>
                        <tr>
                            <td><span>BlockHeight</span></td>
                            <td><span>string</span></td>
                            <td><span>区块高度</span></td>
                        </tr>
                        <tr>
                            <td><span>CreateTime</span></td>
                            <td><span>string</span></td>
                            <td><span>创建时间</span></td>
                        </tr>
                        <tr>
                            <td><span>Ext</span></td>
                            <td><span>string</span></td>
                            <td><span>扩展信息</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
    </ul>
    <h1 id='代理商模式下视频图片取证'><span>代理商模式下视频/图片取证</span></h1>
    <h2 id='代理商取证服务'><span>代理商取证服务</span></h2>
    <ul>
        <li>
            <p><span>方法原型</span></p>
            <ul>
                <li>
                    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang=""><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang=""><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
                    </div>
                    <div class="CodeMirror-measure"></div>
                    <div style="position: relative; z-index: 1;"></div>
                    <div class="CodeMirror-code" role="presentation">
                        <div class="CodeMirror-activeline" style="position: relative;">
                            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
                            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
                            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">//视频取证接口</span></pre>
                        </div>
                        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">RepresentEvidenceObtainVideo(webUrls, title, remark, representAppId string, timeout time.Duration) (string, error)</span></pre>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 66px;"></div>
                    <div class="CodeMirror-gutters" style="display: none; height: 66px;"></div>
                    </div>
                    </div>
                    </pre>
                </li>
                <li>
                    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang=""><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang=""><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
                    </div>
                    <div class="CodeMirror-measure"></div>
                    <div style="position: relative; z-index: 1;"></div>
                    <div class="CodeMirror-code" role="presentation">
                        <div class="CodeMirror-activeline" style="position: relative;">
                            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
                            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
                            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">//图片取证</span></pre>
                        </div>
                        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">RepresentEvidenceObtainPic(webUrls, title, remark, representAppId string, timeout time.Duration) (string, error)</span></pre>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 66px;"></div>
                    <div class="CodeMirror-gutters" style="display: none; height: 66px;"></div>
                    </div>
                    </div>
                    </pre>
                </li>
            </ul>
        </li>
        <li>
            <p><span>参数说明</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>参数名</span></th>
                            <th><span>参数类型</span></th>
                            <th><span>默认值</span></th>
                            <th><span>参数描述</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>webUrls</span></td>
                            <td><span>string</span></td>
                            <td>&nbsp;</td>
                            <td><span>取证的url</span></td>
                        </tr>
                        <tr>
                            <td><span>title</span></td>
                            <td><span>string</span></td>
                            <td>&nbsp;</td>
                            <td><span>标题</span></td>
                        </tr>
                        <tr>
                            <td><span>remark</span></td>
                            <td><span>string</span></td>
                            <td>&nbsp;</td>
                            <td><span>描述</span></td>
                        </tr>
                        <tr>
                            <td><span>representAppId</span></td>
                            <td><span>string</span></td>
                            <td>&nbsp;</td>
                            <td><span>代理用户id，传入&quot;&quot;时则表示代理商本身做存取证</span></td>
                        </tr>
                        <tr>
                            <td><span>timeout</span></td>
                            <td><span>time.Duration</span></td>
                            <td>&nbsp;</td>
                            <td><span>超时时间</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
        <li>
            <p><span>返回值 </span></p>
            <p><span>具体的orderNo（任务单号）</span></p>
        </li>
    </ul>
    <ul>
        <li>
            <p><span>方法原型</span></p>
            <ul>
                <li>
                    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang=""><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang=""><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
                    </div>
                    <div class="CodeMirror-measure"></div>
                    <div style="position: relative; z-index: 1;"></div>
                    <div class="CodeMirror-code" role="presentation">
                        <div class="CodeMirror-activeline" style="position: relative;">
                            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
                            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
                            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">//长视频取证接口</span></pre>
                        </div>
                        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">NewEvidenceObtainVideo(obtainVideoOption *ObtainVideoOption, timeout time.Duration)</span></pre>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 66px;"></div>
                    <div class="CodeMirror-gutters" style="display: none; height: 66px;"></div>
                    </div>
                    </div>
                    </pre>
                </li>
            </ul>
        </li>
        <li>
            <p><span>参数说明</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>参数名</span></th>
                            <th><span>参数类型</span></th>
                            <th><span>默认值</span></th>
                            <th><span>参数描述</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>obtainVideoOption</span></td>
                            <td><span>*ObtainVideoOption</span></td>
                            <td>&nbsp;</td>
                            <td><span>视频取证的参数</span></td>
                        </tr>
                        <tr>
                            <td><span>timeout</span></td>
                            <td><span>time.Duration</span></td>
                            <td>&nbsp;</td>
                            <td><span>超时时间</span></td>
                        </tr>
                        <tr>
                            <td><span>obtainVideoOption的结构体如下</span></td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody>
                </table>
            </figure>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>参数名</span></th>
                            <th><span>参数类型</span></th>
                            <th><span>默认值</span></th>
                            <th><span>参数描述</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>webUrls</span></td>
                            <td><span>string</span></td>
                            <td>&nbsp;</td>
                            <td><span>取证的url</span></td>
                        </tr>
                        <tr>
                            <td><span>title</span></td>
                            <td><span>string</span></td>
                            <td>&nbsp;</td>
                            <td><span>标题</span></td>
                        </tr>
                        <tr>
                            <td><span>representAppId</span></td>
                            <td><span>string</span></td>
                            <td>&nbsp;</td>
                            <td><span>代理用户id，传入&quot;&quot;时则表示代理商本身做存取证</span></td>
                        </tr>
                        <tr>
                            <td><span>remark</span></td>
                            <td><span>string</span></td>
                            <td>&nbsp;</td>
                            <td><span>描述</span></td>
                        </tr>
                        <tr>
                            <td><span>duration</span></td>
                            <td><span>int</span></td>
                            <td>&nbsp;</td>
                            <td><span>录屏时间，单位:秒，不超过1小时</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
    </ul>
    <ul>
        <li>
            <p><span>返回值</span></p>
            <p><span>具体的orderNo（任务单号）</span></p>
        </li>
    </ul>
    <h2 id='代理商查询取证结果'><span>代理商查询取证结果</span></h2>
    <ul>
        <li>
            <p><span>方法原型</span></p>
            <ul>
                <li>
                    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang=""><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang=""><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
                    </div>
                    <div class="CodeMirror-measure"></div>
                    <div style="position: relative; z-index: 1;"></div>
                    <div class="CodeMirror-code" role="presentation">
                        <div class="CodeMirror-activeline" style="position: relative;">
                            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
                            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
                            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">RepresentGetEvidenceStatus(orderNo, representAppId string, timeout time.Duration) (*EvIdData, error)</span></pre>
                        </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 44px;"></div>
                    <div class="CodeMirror-gutters" style="display: none; height: 44px;"></div>
                    </div>
                    </div>
                    </pre>
                </li>
            </ul>
        </li>
        <li>
            <p><span>参数说明</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>参数名</span></th>
                            <th><span>参数类型</span></th>
                            <th><span>默认值</span></th>
                            <th><span>参数描述</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>orderNo</span></td>
                            <td><span>string</span></td>
                            <td>&nbsp;</td>
                            <td><span>取证返回的任务单号</span></td>
                        </tr>
                        <tr>
                            <td><span>representAppId</span></td>
                            <td><span>string</span></td>
                            <td>&nbsp;</td>
                            <td><span>可为代理商id或代理用户id</span></td>
                        </tr>
                        <tr>
                            <td><span>timeout</span></td>
                            <td><span>time.Duration</span></td>
                            <td>&nbsp;</td>
                            <td><span>超时时间</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
        <li>
            <p><span>返回值</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>返回值类型</span></th>
                            <th><span>返回值描述</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>EvIdData</span></td>
                            <td><span>{</span><br><span>&quot;status&quot;:&quot;当前任务状态[0:执行中&gt;&gt;2成功&gt;&gt;10失败]&quot;,</span><br><span>&quot;evidUrl&quot;:&quot;成功状态下,取证证据下载地址&quot;,</span><br><span>&quot;voucherUrl&quot;:&quot;成功状态下,取证证书下载地址&quot;</span><br><span>}</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
    </ul>
    <p>&nbsp;</p>
    </div>
    </div>
    </div>
</template>

<script>
export default {
    props: {
        navMenuArr: {
            type: Array
        }
    },
    components: {},
    data() {
        return {
            box: '',
        }
    },
    computed: {},
    mounted() {

    },
    activated() {
        this.$nextTick(() => {
            this.box = this.$refs.searchBar
            console.log("this.box", this.box)
            this.box.onscroll = this.scrollToTop
            console.log("navMenuArr", this.navMenuArr)
        })


    },
    methods: {
        scrollToTop() {
            let that = this
            let menuAnchor = []
            // 遍历数据，找到id name
            this.navMenuArr.forEach((item, index) => {
                menuAnchor.push({
                    name: item.name,
                    id: item.id
                })
                if (item.children && item.children.length) {
                    item.children.forEach((innerItem, innerIndex) => {
                        menuAnchor.push({
                            name: innerItem.name,
                            id: innerItem.id
                        })
                    })
                }
            })
            var Height = $(window).height()
            $('.go-sdk').find('.md-header-anchor').each(function(ind) {
                var Top = $(this).offset().top + 100 //元素距离顶部距离
                var scroll = $(document).scrollTop() //滚动高度
                if (Top - Height - scroll <= 0) {
                    let _name = $(this).attr("name")
                    let findArr = menuAnchor.filter((item) => {
                        return item.name == _name
                    })
                    if (findArr.length > 0) {
                        that.$emit("getActiveId", findArr[0].id)
                    }
                }
            })
        }
    }
}
</script>

<style lang='scss' scoped>
html {
    overflow-x: initial !important;
}

:root {
    --bg-color: #ffffff;
    --text-color: #333333;
    --select-text-bg-color: #B5D6FC;
    --select-text-font-color: auto;
    --monospace: "Lucida Console", Consolas, "Courier", monospace;
}

html {
    font-size: 14px;
    background-color: var(--bg-color);
    color: var(--text-color);
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
}

body {
    margin: 0px;
    padding: 0px;
    height: auto;
    bottom: 0px;
    top: 0px;
    left: 0px;
    right: 0px;
    font-size: 1rem;
    line-height: 1.42857143;
    overflow-x: hidden;
    background-image: inherit;
    background-size: inherit;
    background-attachment: inherit;
    background-origin: inherit;
    background-clip: inherit;
    background-color: inherit;
    tab-size: 4;
    background-position: inherit inherit;
    background-repeat: inherit inherit;
}

iframe {
    margin: auto;
}

a.url {
    word-break: break-all;
}

a:active,
a:hover {
    outline: 0px;
}

.in-text-selection,
::selection {
    // text-shadow: none;
    // background: var(--select-text-bg-color);
    // color: var(--select-text-font-color);
}

#write {
    margin: 0px auto;
    height: auto;
    width: inherit;
    word-break: normal;
    word-wrap: break-word;
    position: relative;
    white-space: normal;
    overflow-x: visible;
    padding-top: 40px;
}

#write.first-line-indent p {
    text-indent: 2em;
}

#write.first-line-indent li p,
#write.first-line-indent p * {
    text-indent: 0px;
}

#write.first-line-indent li {
    margin-left: 2em;
}

.for-image #write {
    padding-left: 8px;
    padding-right: 8px;
}

body.typora-export {
    padding-left: 30px;
    padding-right: 30px;
}

.typora-export .footnote-line,
.typora-export li,
.typora-export p {
    white-space: pre-wrap;
}

.typora-export .task-list-item input {
    pointer-events: none;
}

@media screen and (max-width: 500px) {
    body.typora-export {
        padding-left: 0px;
        padding-right: 0px;
    }
    #write {
        padding-left: 20px;
        padding-right: 20px;
    }
    .CodeMirror-sizer {
        margin-left: 0px !important;
    }
    .CodeMirror-gutters {
        display: none !important;
    }
}

#write li>figure:last-child {
    margin-bottom: 0.5rem;
}

#write ol,
#write ul {
    position: relative;
}

img {
    max-width: 100%;
    vertical-align: middle;
}

button,
input,
select,
textarea {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-variant-caps: inherit;
    font-weight: inherit;
    font-stretch: inherit;
    line-height: inherit;
}

input[type="checkbox"],
input[type="radio"] {
    line-height: normal;
    padding: 0px;
}

*,
::after,
::before {
    box-sizing: border-box;
}

#write h1,
#write h2,
#write h3,
#write h4,
#write h5,
#write h6,
#write p,
#write pre {
    width: inherit;
}

#write h1,
#write h2,
#write h3,
#write h4,
#write h5,
#write h6,
#write p {
    position: relative;
}

p {
    line-height: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    break-after: avoid-page;
    break-inside: avoid;
    orphans: 4;
}

p {
    orphans: 4;
}

h1 {
    font-size: 2rem;
}

h2 {
    font-size: 1.8rem;
}

h3 {
    font-size: 1.6rem;
}

h4 {
    font-size: 1.4rem;
}

h5 {
    font-size: 1.2rem;
}

h6 {
    font-size: 1rem;
}

.md-math-block,
.md-rawblock,
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.hidden {
    display: none;
}

.md-blockmeta {
    color: rgb(204, 204, 204);
    font-weight: 700;
    font-style: italic;
}

a {
    cursor: pointer;
}

sup.md-footnote {
    padding: 2px 4px;
    background-color: rgba(238, 238, 238, 0.701961);
    color: rgb(85, 85, 85);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    cursor: pointer;
}

sup.md-footnote a,
sup.md-footnote a:hover {
    color: inherit;
    text-transform: inherit;
    text-decoration: inherit;
}

#write input[type="checkbox"] {
    cursor: pointer;
    width: inherit;
    height: inherit;
}

figure {
    overflow-x: auto;
    margin: 1.2em 0px;
    max-width: calc(100% + 16px);
    padding: 0px;
}

figure>table {
    margin: 0px;
}

tr {
    break-inside: avoid;
    break-after: auto;
}

thead {
    display: table-header-group;
}

table {
    border-collapse: collapse;
    border-spacing: 0px;
    width: 100%;
    overflow: auto;
    break-inside: auto;
    text-align: left;
}

table.md-table td {
    min-width: 32px;
}

.CodeMirror-gutters {
    border-right-width: 0px;
    background-color: inherit;
}

.CodeMirror-linenumber {}

.CodeMirror {
    text-align: left;
}

.CodeMirror-placeholder {
    opacity: 0.3;
}

.CodeMirror pre {
    padding: 0px 4px;
}

.CodeMirror-lines {
    padding: 0px;
}

div.hr:focus {
    cursor: none;
}

#write pre {
    white-space: pre-wrap;
}

#write.fences-no-line-wrapping pre {
    white-space: pre;
}

#write pre.ty-contain-cm {
    white-space: normal;
}

.CodeMirror-gutters {
    margin-right: 4px;
}

.md-fences {
    font-size: 0.9rem;
    display: block;
    break-inside: avoid;
    text-align: left;
    overflow: visible;
    white-space: pre;
    background-image: inherit;
    background-size: inherit;
    background-attachment: inherit;
    background-origin: inherit;
    background-clip: inherit;
    background-color: inherit;
    position: relative !important;
    background-position: inherit inherit;
    background-repeat: inherit inherit;
}

.md-diagram-panel {
    width: 100%;
    margin-top: 10px;
    text-align: center;
    padding-top: 0px;
    padding-bottom: 8px;
    overflow-x: auto;
}

#write .md-fences.mock-cm {
    white-space: pre-wrap;
}

.md-fences.md-fences-with-lineno {
    padding-left: 0px;
}

#write.fences-no-line-wrapping .md-fences.mock-cm {
    white-space: pre;
    overflow-x: auto;
}

.md-fences.mock-cm.md-fences-with-lineno {
    padding-left: 8px;
}

.CodeMirror-line,
twitterwidget {
    break-inside: avoid;
}

.footnotes {
    opacity: 0.8;
    font-size: 0.9rem;
    margin-top: 1em;
    margin-bottom: 1em;
}

.footnotes+.footnotes {
    margin-top: 0px;
}

.md-reset {
    margin: 0px;
    padding: 0px;
    border: 0px;
    outline: 0px;
    vertical-align: top;
    text-decoration: none;
    text-shadow: none;
    float: none;
    position: static;
    width: auto;
    height: auto;
    white-space: nowrap;
    cursor: inherit;
    line-height: normal;
    font-weight: 400;
    text-align: left;
    box-sizing: content-box;
    direction: ltr;
    background-position: 0px 0px;
    background-repeat: initial initial;
}

li div {
    padding-top: 0px;
}

blockquote {
    margin: 1rem 0px;
}

li .mathjax-block,
li p {
    margin: 0.5rem 0px;
}

li {
    margin: 0px;
    position: relative;
}

blockquote> :last-child {
    margin-bottom: 0px;
}

blockquote> :first-child,
li> :first-child {
    margin-top: 0px;
}

.footnotes-area {
    color: rgb(136, 136, 136);
    margin-top: 0.714rem;
    padding-bottom: 0.143rem;
    white-space: normal;
}

#write .footnote-line {
    white-space: pre-wrap;
}

@media print {
    body,
    html {
        border: 1px solid transparent;
        height: 99%;
        break-after: avoid-page;
        break-before: avoid-page;
        font-variant-ligatures: no-common-ligatures;
    }
    #write {
        margin-top: 0px;
        padding-top: 0px;
        border-color: transparent !important;
    }
    .typora-export * {
        -webkit-print-color-adjust: exact;
    }
    html.blink-to-pdf {
        font-size: 13px;
    }
    .typora-export #write {
        break-after: avoid-page;
    }
    .typora-export #write::after {
        height: 0px;
    }
    .is-mac table {
        break-inside: avoid;
    }
}

.footnote-line {
    margin-top: 0.714em;
    font-size: 0.7em;
}

a img,
img a {
    cursor: pointer;
}

pre.md-meta-block {
    font-size: 0.8rem;
    min-height: 0.8rem;
    white-space: pre-wrap;
    background-color: rgb(204, 204, 204);
    display: block;
    overflow-x: hidden;
    background-position: initial initial;
    background-repeat: initial initial;
}

p>.md-image:only-child:not(.md-img-error) img,
p>img:only-child {
    display: block;
    margin: auto;
}

#write.first-line-indent p>.md-image:only-child:not(.md-img-error) img {
    left: -2em;
    position: relative;
}

p>.md-image:only-child {
    display: inline-block;
    width: 100%;
}

#write .MathJax_Display {
    margin: 0.8em 0px 0px;
}

.md-math-block {
    width: 100%;
}

.md-math-block:not(:empty)::after {
    display: none;
}

[contenteditable="true"]:active,
[contenteditable="true"]:focus,
[contenteditable="false"]:active,
[contenteditable="false"]:focus {
    outline: 0px;
    box-shadow: none;
}

.md-task-list-item {
    position: relative;
    list-style-type: none;
}

.task-list-item.md-task-list-item {
    padding-left: 0px;
}

.md-task-list-item>input {
    position: absolute;
    top: 0px;
    left: 0px;
    margin-left: -1.2em;
    margin-top: calc(1em - 10px);
    border: none;
}

.math {
    font-size: 1rem;
}

.md-toc {
    min-height: 3.58rem;
    position: relative;
    font-size: 0.9rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.md-toc-content {
    position: relative;
    margin-left: 0px;
}

.md-toc-content::after,
.md-toc::after {
    display: none;
}

.md-toc-item {
    display: block;
    color: rgb(65, 131, 196);
}

.md-toc-item a {
    text-decoration: none;
}

.md-toc-inner:hover {
    text-decoration: underline;
}

.md-toc-inner {
    display: inline-block;
    cursor: pointer;
}

.md-toc-h1 .md-toc-inner {
    margin-left: 0px;
    font-weight: 700;
}

.md-toc-h2 .md-toc-inner {
    margin-left: 2em;
}

.md-toc-h3 .md-toc-inner {
    margin-left: 4em;
}

.md-toc-h4 .md-toc-inner {
    margin-left: 6em;
}

.md-toc-h5 .md-toc-inner {
    margin-left: 8em;
}

.md-toc-h6 .md-toc-inner {
    margin-left: 10em;
}

@media screen and (max-width: 48em) {
    .md-toc-h3 .md-toc-inner {
        margin-left: 3.5em;
    }
    .md-toc-h4 .md-toc-inner {
        margin-left: 5em;
    }
    .md-toc-h5 .md-toc-inner {
        margin-left: 6.5em;
    }
    .md-toc-h6 .md-toc-inner {
        margin-left: 8em;
    }
}

a.md-toc-inner {
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
}

.footnote-line a:not(.reversefootnote) {
    color: inherit;
}

.md-attr {
    display: none;
}

.md-fn-count::after {
    content: ".";
}

code,
pre,
samp,
tt {
    font-family: var(--monospace);
}

kbd {
    margin: 0px 0.1em;
    padding: 0.1em 0.6em;
    font-size: 0.8em;
    color: rgb(36, 39, 41);
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(173, 179, 185);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    box-shadow: rgba(12, 13, 14, 0.2) 0px 1px 0px, rgb(255, 255, 255) 0px 0px 0px 2px inset;
    white-space: nowrap;
    vertical-align: middle;
    background-position: initial initial;
    background-repeat: initial initial;
}

.md-comment {
    color: rgb(162, 127, 3);
    opacity: 0.8;
    font-family: var(--monospace);
}

code {
    text-align: left;
}

a.md-print-anchor {
    white-space: pre !important;
    border: none !important;
    display: inline-block !important;
    position: absolute !important;
    width: 1px !important;
    right: 0px !important;
    outline: 0px !important;
    text-shadow: initial !important;
    background-position: 0px 0px !important;
    background-repeat: initial initial !important;
}

.md-inline-math .MathJax_SVG .noError {
    display: none !important;
}

.html-for-mac .inline-math-svg .MathJax_SVG {
    vertical-align: 0.2px;
}

.md-math-block .MathJax_SVG_Display {
    text-align: center;
    margin: 0px;
    position: relative;
    text-indent: 0px;
    max-width: none;
    max-height: none;
    min-height: 0px;
    min-width: 100%;
    width: auto;
    overflow-y: hidden;
    display: block !important;
}

.MathJax_SVG_Display,
.md-inline-math .MathJax_SVG_Display {
    width: auto;
    margin: inherit;
    display: inline-block !important;
}

.MathJax_SVG .MJX-monospace {
    font-family: var(--monospace);
}

.MathJax_SVG .MJX-sans-serif {
    font-family: sans-serif;
}

.MathJax_SVG {
    display: inline;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    zoom: 90%;
    text-indent: 0px;
    text-align: left;
    text-transform: none;
    letter-spacing: normal;
    word-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    float: none;
    direction: ltr;
    max-width: none;
    max-height: none;
    min-width: 0px;
    min-height: 0px;
    border: 0px;
    padding: 0px;
    margin: 0px;
}

.MathJax_SVG * {
    transition: none;
}

.MathJax_SVG_Display svg {
    vertical-align: middle !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}

.os-windows.monocolor-emoji .md-emoji {
    font-family: "Segoe UI Symbol", sans-serif;
}

.md-diagram-panel>svg {
    max-width: 100%;
}

[lang="flow"] svg,
[lang="mermaid"] svg {
    max-width: 100%;
    height: auto;
}

[lang="mermaid"] .node text {
    font-size: 1rem;
}

table tr th {
    border-bottom-width: 0px;
}

video {
    max-width: 100%;
    display: block;
    margin: 0px auto;
}

iframe {
    max-width: 100%;
    width: 100%;
    border: none;
}

.highlight td,
.highlight tr {
    border: 0px;
}

svg[id^="mermaidChart"] {
    line-height: 1em;
}

mark {
    background-color: rgb(255, 255, 0);
    color: rgb(0, 0, 0);
    background-position: initial initial;
    background-repeat: initial initial;
}

.md-html-inline .md-plain,
.md-html-inline strong,
mark .md-inline-math,
mark strong {
    color: inherit;
}

mark .md-meta {
    color: rgb(0, 0, 0);
    opacity: 0.3 !important;
}

@media print {
    .typora-export h1,
    .typora-export h2,
    .typora-export h3,
    .typora-export h4,
    .typora-export h5,
    .typora-export h6 {
        break-inside: avoid;
    }
}

.CodeMirror {
    height: auto;
}

.CodeMirror.cm-s-inner {
    background-image: inherit;
    background-size: inherit;
    background-attachment: inherit;
    background-origin: inherit;
    background-clip: inherit;
    background-color: inherit;
    background-position: inherit inherit;
    background-repeat: inherit inherit;
}

.CodeMirror-scroll {
    overflow-y: hidden;
    overflow-x: auto;
    z-index: 3;
}

.CodeMirror-gutter-filler,
.CodeMirror-scrollbar-filler {
    background-color: rgb(255, 255, 255);
}

.CodeMirror-gutters {
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: rgb(221, 221, 221);
    background-image: inherit;
    background-size: inherit;
    background-attachment: inherit;
    background-origin: inherit;
    background-clip: inherit;
    background-color: inherit;
    white-space: nowrap;
    background-position: inherit inherit;
    background-repeat: inherit inherit;
}

.CodeMirror-linenumber {
    padding: 0px 3px 0px 5px;
    text-align: right;
    color: rgb(153, 153, 153);
}

.cm-s-inner .cm-keyword {
    color: rgb(119, 0, 136);
}

.cm-s-inner .cm-atom,
.cm-s-inner.cm-atom {
    color: rgb(34, 17, 153);
}

.cm-s-inner .cm-number {
    color: rgb(17, 102, 68);
}

.cm-s-inner .cm-def {
    color: rgb(0, 0, 255);
}

.cm-s-inner .cm-variable {
    color: rgb(0, 0, 0);
}

.cm-s-inner .cm-variable-2 {
    color: rgb(0, 85, 170);
}

.cm-s-inner .cm-variable-3 {
    color: rgb(0, 136, 85);
}

.cm-s-inner .cm-string {
    color: rgb(170, 17, 17);
}

.cm-s-inner .cm-property {
    color: rgb(0, 0, 0);
}

.cm-s-inner .cm-operator {
    color: rgb(152, 26, 26);
}

.cm-s-inner .cm-comment,
.cm-s-inner.cm-comment {
    color: rgb(170, 85, 0);
}

.cm-s-inner .cm-string-2 {
    color: rgb(255, 85, 0);
}

.cm-s-inner .cm-meta {
    color: rgb(85, 85, 85);
}

.cm-s-inner .cm-qualifier {
    color: rgb(85, 85, 85);
}

.cm-s-inner .cm-builtin {
    color: rgb(51, 0, 170);
}

.cm-s-inner .cm-bracket {
    color: rgb(153, 153, 119);
}

.cm-s-inner .cm-tag {
    color: rgb(17, 119, 0);
}

.cm-s-inner .cm-attribute {
    color: rgb(0, 0, 204);
}

.cm-s-inner .cm-header,
.cm-s-inner.cm-header {
    color: rgb(0, 0, 255);
}

.cm-s-inner .cm-quote,
.cm-s-inner.cm-quote {
    color: rgb(0, 153, 0);
}

.cm-s-inner .cm-hr,
.cm-s-inner.cm-hr {
    color: rgb(153, 153, 153);
}

.cm-s-inner .cm-link,
.cm-s-inner.cm-link {
    color: rgb(0, 0, 204);
}

.cm-negative {
    color: rgb(221, 68, 68);
}

.cm-positive {
    color: rgb(34, 153, 34);
}

.cm-header,
.cm-strong {
    font-weight: 700;
}

.cm-del {
    text-decoration: line-through;
}

.cm-em {
    font-style: italic;
}

.cm-link {
    text-decoration: underline;
}

.cm-error {
    color: red;
}

.cm-invalidchar {
    color: red;
}

.cm-constant {
    color: rgb(38, 139, 210);
}

.cm-defined {
    color: rgb(181, 137, 0);
}

div.CodeMirror span.CodeMirror-matchingbracket {
    color: rgb(0, 255, 0);
}

div.CodeMirror span.CodeMirror-nonmatchingbracket {
    color: rgb(255, 34, 34);
}

.cm-s-inner .CodeMirror-activeline-background {
    background-image: inherit;
    background-size: inherit;
    background-attachment: inherit;
    background-origin: inherit;
    background-clip: inherit;
    background-color: inherit;
    background-position: inherit inherit;
    background-repeat: inherit inherit;
}

.CodeMirror {
    position: relative;
    overflow: hidden;
}

.CodeMirror-scroll {
    height: 100%;
    outline: 0px;
    position: relative;
    box-sizing: content-box;
    background-image: inherit;
    background-size: inherit;
    background-attachment: inherit;
    background-origin: inherit;
    background-clip: inherit;
    background-color: inherit;
    background-position: inherit inherit;
    background-repeat: inherit inherit;
}

.CodeMirror-sizer {
    position: relative;
}

.CodeMirror-gutter-filler,
.CodeMirror-hscrollbar,
.CodeMirror-scrollbar-filler,
.CodeMirror-vscrollbar {
    position: absolute;
    z-index: 6;
    display: none;
}

.CodeMirror-vscrollbar {
    right: 0px;
    top: 0px;
    overflow: hidden;
}

.CodeMirror-hscrollbar {
    bottom: 0px;
    left: 0px;
    overflow: hidden;
}

.CodeMirror-scrollbar-filler {
    right: 0px;
    bottom: 0px;
}

.CodeMirror-gutter-filler {
    left: 0px;
    bottom: 0px;
}

.CodeMirror-gutters {
    position: absolute;
    left: 0px;
    top: 0px;
    padding-bottom: 30px;
    z-index: 3;
}

.CodeMirror-gutter {
    white-space: normal;
    height: 100%;
    box-sizing: content-box;
    padding-bottom: 30px;
    margin-bottom: -32px;
    display: inline-block;
}

.CodeMirror-gutter-wrapper {
    position: absolute;
    z-index: 4;
    border: none !important;
    background-position: 0px 0px !important;
    background-repeat: initial initial !important;
}

.CodeMirror-gutter-background {
    position: absolute;
    top: 0px;
    bottom: 0px;
    z-index: 4;
}

.CodeMirror-gutter-elt {
    position: absolute;
    cursor: default;
    z-index: 4;
}

.CodeMirror-lines {
    cursor: text;
}

.CodeMirror pre {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-width: 0px;
    font-family: inherit;
    font-size: inherit;
    margin: 0px;
    white-space: pre;
    word-wrap: normal;
    color: inherit;
    z-index: 2;
    position: relative;
    overflow: visible;
    background-position: 0px 0px;
    background-repeat: initial initial;
}

.CodeMirror-wrap pre {
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: normal;
}

.CodeMirror-code pre {
    border-right-width: 30px;
    border-right-style: solid;
    border-right-color: transparent;
    width: fit-content;
}

.CodeMirror-wrap .CodeMirror-code pre {
    border-right-style: none;
    width: auto;
}

.CodeMirror-linebackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    z-index: 0;
}

.CodeMirror-linewidget {
    position: relative;
    z-index: 2;
    overflow: auto;
}

.CodeMirror-wrap .CodeMirror-scroll {
    overflow-x: hidden;
}

.CodeMirror-measure {
    position: absolute;
    width: 100%;
    height: 0px;
    overflow: hidden;
    visibility: hidden;
}

.CodeMirror-measure pre {
    position: static;
}

.CodeMirror div.CodeMirror-cursor {
    position: absolute;
    visibility: hidden;
    border-right-style: none;
    width: 0px;
}

.CodeMirror div.CodeMirror-cursor {
    visibility: hidden;
}

.CodeMirror-focused div.CodeMirror-cursor {
    visibility: inherit;
}

.cm-searching {
    background-color: rgba(255, 255, 0, 0.4);
    background-position: initial initial;
    background-repeat: initial initial;
}

@media print {
    .CodeMirror div.CodeMirror-cursor {
        visibility: hidden;
    }
}

:root {
    --side-bar-bg-color: #fafafa;
    --control-text-color: #777;
}

@include-when-export url(https://fonts.loli.net/css?family=Open+Sans:400italic,700italic,700,400&subset=latin,latin-ext);
/* open-sans-regular - latin-ext_latin */

/* open-sans-italic - latin-ext_latin */

/* open-sans-700 - latin-ext_latin */

/* open-sans-700italic - latin-ext_latin */

html {
    font-size: 16px;
}

body {
    font-family: "Open Sans", "Clear Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: rgb(51, 51, 51);
    line-height: 1.6;
}

#write {
    max-width: 860px;
    margin: 0 auto;
    padding: 30px;
    padding-bottom: 100px;
}

@media only screen and (min-width: 1400px) {
    #write {
        max-width: 1024px;
    }
}

@media only screen and (min-width: 1800px) {
    #write {
        max-width: 1200px;
    }
}

#write>ul:first-child,
#write>ol:first-child {
    margin-top: 30px;
}

a {
    color: #4183C4;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    position: relative;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: bold;
    line-height: 1.4;
    cursor: text;
}

h1:hover a.anchor,
h2:hover a.anchor,
h3:hover a.anchor,
h4:hover a.anchor,
h5:hover a.anchor,
h6:hover a.anchor {
    text-decoration: none;
}

h1 tt,
h1 code {
    font-size: inherit;
}

h2 tt,
h2 code {
    font-size: inherit;
}

h3 tt,
h3 code {
    font-size: inherit;
}

h4 tt,
h4 code {
    font-size: inherit;
}

h5 tt,
h5 code {
    font-size: inherit;
}

h6 tt,
h6 code {
    font-size: inherit;
}

h1 {
    font-size: 2.25em;
    line-height: 1.2;
    border-bottom: 1px solid #eee;
}

h2 {
    font-size: 1.75em;
    line-height: 1.225;
    border-bottom: 1px solid #eee;
}

/*@media print {
    .typora-export h1,
    .typora-export h2 {
        border-bottom: none;
        padding-bottom: initial;
    }

    .typora-export h1::after,
    .typora-export h2::after {
        content: "";
        display: block;
        height: 100px;
        margin-top: -96px;
        border-top: 1px solid #eee;
    }
}*/

h3 {
    font-size: 1.5em;
    line-height: 1.43;
}

h4 {
    font-size: 1.25em;
}

h5 {
    font-size: 1em;
}

h6 {
    font-size: 1em;
    color: #777;
}

p,
blockquote,
ul,
ol,
dl,
table {
    margin: 0.8em 0;
}

li>ol,
li>ul {
    margin: 0 0;
}

hr {
    height: 2px;
    padding: 0;
    margin: 16px 0;
    background-color: #e7e7e7;
    border: 0 none;
    overflow: hidden;
    box-sizing: content-box;
}

li p.first {
    display: inline-block;
}

ul,
ol {
    padding-left: 30px;
}

ul:first-child,
ol:first-child {
    margin-top: 0;
}

ul:last-child,
ol:last-child {
    margin-bottom: 0;
}

blockquote {
    border-left: 4px solid #dfe2e5;
    padding: 0 15px;
    color: #777777;
}

blockquote blockquote {
    padding-right: 0;
}

table {
    padding: 0;
    word-break: initial;
}

table tr {
    border-top: 1px solid #dfe2e5;
    margin: 0;
    padding: 0;
}

table tr:nth-child(2n),
thead {
    background-color: #f8f8f8;
}

table tr th {
    font-weight: bold;
    border: 1px solid #dfe2e5;
    border-bottom: 0;
    margin: 0;
    padding: 6px 13px;
}

table tr td {
    border: 1px solid #dfe2e5;
    margin: 0;
    padding: 6px 13px;
}

table tr th:first-child,
table tr td:first-child {
    margin-top: 0;
}

table tr th:last-child,
table tr td:last-child {
    margin-bottom: 0;
}

.CodeMirror-lines {
    padding-left: 4px;
}

.code-tooltip {
    box-shadow: 0 1px 1px 0 rgba(0, 28, 36, .3);
    border-top: 1px solid #eef2f2;
}

.md-fences,
code,
tt {
    border: 1px solid #e7eaed;
    background-color: #f8f8f8;
    border-radius: 3px;
    padding: 0;
    padding: 2px 4px 0px 4px;
    font-size: 0.9em;
}

code {
    background-color: #f3f4f4;
    padding: 0 2px 0 2px;
}

.md-fences {
    margin-bottom: 15px;
    margin-top: 15px;
    padding-top: 8px;
    padding-bottom: 6px;
}

.md-task-list-item>input {
    margin-left: -1.3em;
}

@media print {
    html {
        font-size: 13px;
    }
    table,
    pre {
        page-break-inside: avoid;
    }
    pre {
        word-wrap: break-word;
    }
}

.md-fences {
    background-color: #f8f8f8;
}

#write pre.md-meta-block {
    padding: 1rem;
    font-size: 85%;
    line-height: 1.45;
    background-color: #f7f7f7;
    border: 0;
    border-radius: 3px;
    color: #777777;
    margin-top: 0 !important;
}

.mathjax-block>.code-tooltip {
    bottom: .375rem;
}

.md-mathjax-midline {
    background: #fafafa;
}

#write>h3.md-focus:before {
    left: -1.5625rem;
    top: .375rem;
}

#write>h4.md-focus:before {
    left: -1.5625rem;
    top: .285714286rem;
}

#write>h5.md-focus:before {
    left: -1.5625rem;
    top: .285714286rem;
}

#write>h6.md-focus:before {
    left: -1.5625rem;
    top: .285714286rem;
}

.md-image>.md-meta {
    /*border: 1px solid #ddd;*/
    border-radius: 3px;
    padding: 2px 0px 0px 4px;
    font-size: 0.9em;
    color: inherit;
}

.md-tag {
    color: #a7a7a7;
    opacity: 1;
}

.md-toc {
    margin-top: 20px;
    padding-bottom: 20px;
}

.sidebar-tabs {
    border-bottom: none;
}

#typora-quick-open {
    border: 1px solid #ddd;
    background-color: #f8f8f8;
}

#typora-quick-open-item {
    background-color: #FAFAFA;
    border-color: #FEFEFE #e5e5e5 #e5e5e5 #eee;
    border-style: solid;
    border-width: 1px;
}

/** focus mode */

.on-focus-mode blockquote {
    border-left-color: rgba(85, 85, 85, 0.12);
}

header,
.context-menu,
.megamenu-content,
footer {
    font-family: "Segoe UI", "Arial", sans-serif;
}

.file-node-content:hover .file-node-icon,
.file-node-content:hover .file-node-open-state {
    visibility: visible;
}

.mac-seamless-mode #typora-sidebar {
    background-color: #fafafa;
    background-color: var(--side-bar-bg-color);
}

.md-lang {
    color: #b4654d;
}

.html-for-mac .context-menu {
    --item-hover-bg-color: #E6F0FE;
}

#md-notification .btn {
    border: 0;
}

.dropdown-menu .divider {
    border-color: #e5e5e5;
}

.ty-preferences .window-content {
    background-color: #fafafa;
}

.ty-preferences .nav-group-item.active {
    color: white;
    background: #999;
}

/* go-sdk 自定义样式  go-sdk 外出嵌套一层divs */

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 16px 0px;
}

#write {
    margin: 0 auto !important;
    padding: 30px !important;
    max-width: inherit !important;
}

li p {
    margin-bottom: 8px;
}
</style>
