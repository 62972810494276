<template>
    <div ref="searchBar" class="go-sdk">
          <div class='typora-export-content'>
            <div id='write' class=''>
                <p><span> </span></p>
                <h1 id='接口文档介绍'><span>接口文档介绍</span></h1>
                <p>&nbsp;</p>
                <h2 id='文档概述'><span>文档概述</span></h2>
                <p><strong><span>功能描述</span></strong></p>
                <p><span>本文档主要描述了接入至信链的流程及至信链JAVA SDK提供的所有功能，旨在引导开发人员，通过JAVA SDK接入至信链司法存证平台，并调用至信链JAVA SDK所提供的服务。</span></p>
                <p><strong><span>阅读对象</span></strong></p>
                <p><span>接入至信链的开发人员</span></p>
                <h2 id='变更历史'><span>变更历史</span></h2>
                <figure>
                    <table>
                        <thead>
                            <tr>
                                <th><span>修订日期</span></th>
                                <th><span>修订内容</span></th>
                                <th><span>修订版本</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><span>2019.07.24</span></td>
                                <td><span>初稿</span></td>
                                <td><span>v1.0</span></td>
                            </tr>
                            <tr>
                                <td><span>2019.07.25</span></td>
                                <td><span>SDK多APPID支持</span></td>
                                <td><span>v1.1</span></td>
                            </tr>
                            <tr>
                                <td><span>2019.10.14</span></td>
                                <td><span>查询存证信息增加三合一查询</span></td>
                                <td><span>v1.2</span></td>
                            </tr>
                            <tr>
                                <td><span>2020.03.02</span></td>
                                <td><span>修改调用示例</span></td>
                                <td><span>v2.0</span></td>
                            </tr>
                            <tr>
                                <td><span>2020.11.19</span></td>
                                <td><span>文档整理</span></td>
                                <td><span>v2.0.1</span></td>
                            </tr>
                            <tr>
                                <td><span>2020.12.02</span></td>
                                <td><span>增加代理商相关接口</span></td>
                                <td><span>v2.0.2</span></td>
                            </tr>
                            <tr>
                                <td><span>2020.12.23</span></td>
                                <td><span>增加 侵权相关方法，增加存证方法 url 参数</span></td>
                                <td><span>v2.0.5</span></td>
                            </tr>
                            <tr>
                                <td><span>2021.01.10</span></td>
                                <td><span>更改文档结构，区分各功能点</span></td>
                                <td><span>v2.0.6</span></td>
                            </tr>
                            <tr>
                                <td><span>2021.01.23</span></td>
                                <td><span>增加代理用户取证功能</span></td>
                                <td><span>v2.1.2.2</span></td>
                            </tr>
                            <tr>
                                <td><span>2021.02.09</span></td>
                                <td><span>增加自然人用户注册及存证接口</span></td>
                                <td><span>v2.1.3</span></td>
                            </tr>
                            <tr>
                                <td><span>2021.05.12</span></td>
                                <td><span>接口优化</span></td>
                                <td><span>v2.1.4</span></td>
                            </tr>
                        </tbody>
                    </table>
                </figure>
                <p>&nbsp;</p>
                <h2 id='接入流程'><span>接入流程</span></h2>
                <p><span>注册至信链帐号</span></p>
                <ul>
                    <li><span>通过</span><a href='https://www.zxinchain.com/'><span>至信链线上首页注册</span></a><span>账户并完成认证及审核.</span></li>
                    <li><span>进入</span><a href='https://open.zxinchain.com/bind'><span>至信链账号中心至信链身份页</span></a><span>，下载公私钥生成工具</span></li>
                    <li><span>将APPID按照指引在公私钥生成工具中生成公私钥，在</span><a href='https://open.zxinchain.com/bind'><span>公私钥绑定页面</span></a><span>粘贴公钥及签名信息完成绑定  (</span>
                        <font color=red><strong><em><span>绑定后记录下公私钥--后续开发中会使用</span></em></strong></font><span>)</span></li>
                </ul>
                <p><span>项目导包</span></p>
                <ul>
                    <li><span>项目导入zxl-sdk-x.jar包(x代表jar版本号)</span></li>
                </ul>
                <p><span>初始化SDK对象</span></p>
                <ul>
                    <li>
                        <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang=""><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang=""><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"></div><div class="CodeMirror-measure"></div><div style="position: relative; z-index: 1;"></div><div class="CodeMirror-code" role="presentation"><div class="CodeMirror-activeline" style="position: relative;"><div class="CodeMirror-activeline-background CodeMirror-linebackground"></div><div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div><pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">//默认初始化配置</span></pre>
            </div>
        </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
    </div>
    </div>
    </pre>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang=""><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang=""><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"></div><div class="CodeMirror-measure"></div><div style="position: relative; z-index: 1;"></div><div class="CodeMirror-code" role="presentation"><div class="CodeMirror-activeline" style="position: relative;"><div class="CodeMirror-activeline-background CodeMirror-linebackground"></div><div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div><pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">public ZXLSDK(String appId, String appKey)</span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
    </div>
    </div>
    </pre>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang=""><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang=""><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"></div><div class="CodeMirror-measure"></div><div style="position: relative; z-index: 1;"></div><div class="CodeMirror-code" role="presentation"><div class="CodeMirror-activeline" style="position: relative;"><div class="CodeMirror-activeline-background CodeMirror-linebackground"></div><div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div><pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">//自定义配置初始化，推荐用户使用自定义配置接入</span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
    </div>
    </div>
    </pre>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang=""><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang=""><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"></div><div class="CodeMirror-measure"></div><div style="position: relative; z-index: 1;"></div><div class="CodeMirror-code" role="presentation"><div class="CodeMirror-activeline" style="position: relative;"><div class="CodeMirror-activeline-background CodeMirror-linebackground"></div><div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div><pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">public ZXLSDK(String appId, String appKey, ZxlConnectionConfig config)</span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
    </div>
    </div>
    </pre>
    </li>
    <li>
        <p><span>可通过JAVA构造方法初始SDK对象，可使用默认配置，也可以用户自定义</span><code>ZxlConnectionConfig</code><span>,传入构造方法初始化</span></p>
    </li>
    </ul>
    <p><span>存证hash计算</span></p>
    <ul>
        <li><span>统一使用</span><code>calculateHash(String path)</code><span>方法，计算存证hash</span></li>
    </ul>
    <p><span>存证流程</span></p>
    <ul>
        <li><span>流程可参照如下流程图</span></li>
    </ul>
    <p><strong><span>前置条件</span></strong></p>
    <p><span>任何操作前都需要先完成一次</span><strong><span>初始化操作</span></strong></p>
    <p><img src="./Xnip2020-12-11_09-29-15.jpg" referrerpolicy="no-referrer"></p>
    <p><strong><span>存证操作</span></strong></p>
    <p><img src="./Xnip2020-12-11_09-54-15.jpg" referrerpolicy="no-referrer"></p>
    <p>&nbsp;</p>
    <h1 id='鉴权绑定'><span>鉴权绑定</span></h1>
    <h2 id='创建ZXLSDK对象'><span>创建ZXLSDK对象</span></h2>
    <h3 id='描述'><span>描述</span></h3>
    <p><span>是使用本SDK提供的功能对象为ZXLSDK对象，请先创建出此对象</span></p>
    <p><span>创建ZXLSDK对象方式：</span></p>
    <ol start=''>
        <li>
            <p><span>默认配置</span></p>
            <p><span>默认使用hosts文件中配置的路由为config.properties中的路由配置(即使用config.properties中的接入路由，指定接入环境[</span><strong><span>生产|测试</span></strong><span>])</span></p>
        </li>
        <li>
            <p><span>用户自定义</span></p>
            <p><span>用户自定义配置，将配置传入SDK构造方法</span></p>
        </li>
    </ol>
    <h3 id='使用默认配置创建'><span>使用默认配置创建</span></h3>
    <h4 id='构造方法-1'><span>构造方法</span></h4>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"></div><div class="CodeMirror-measure"></div><div style="position: relative; z-index: 1;"></div><div class="CodeMirror-code" role="presentation"><div class="CodeMirror-activeline" style="position: relative;"><div class="CodeMirror-activeline-background CodeMirror-linebackground"></div><div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div><pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZXLSDK</span>(<span class="cm-variable-3">String</span> <span class="cm-variable">appId</span>, <span class="cm-variable-3">String</span> <span class="cm-variable">appKey</span>);</span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
    </div>
    </div>
    </pre>
    <h4 id='构造方法参数-1'><span>构造方法参数</span></h4>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>appId</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>企业APPID</span></td>
                </tr>
                <tr>
                    <td><span>appKey</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>企业APPKEY</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h4 id='示例-1'><span>示例</span></h4>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"></div><div class="CodeMirror-measure"></div><div style="position: relative; z-index: 1;"></div><div class="CodeMirror-code" role="presentation" style=""><div class="CodeMirror-activeline" style="position: relative;"><div class="CodeMirror-activeline-background CodeMirror-linebackground"></div><div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div><pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPID &nbsp; </span></span></pre>
    </div>
    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appId</span> <span class="cm-operator">=</span> <span class="cm-string">"200515000110001"</span>;</span></pre>
    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPKEY</span></span></pre>
    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appKey</span> <span class="cm-operator">=</span> <span class="cm-string">"0e4bce1b0ef8471fb9140b849e776f48"</span>;</span></pre>
    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 实例化sdk对象</span></span></pre>
    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZXLSDK</span> <span class="cm-variable">sdk</span> <span class="cm-operator">=</span> <span class="cm-keyword">new</span> <span class="cm-variable">ZXLSDK</span>(<span class="cm-variable">appId</span>,<span class="cm-variable">appKey</span>);</span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 132px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 132px;"></div>
    </div>
    </div>
    </pre>
    <h3 id='用户自定义配置创建推荐使用'><span>用户自定义配置创建[推荐使用]</span></h3>
    <h4 id='构造方法-2'><span>构造方法</span></h4>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"></div><div class="CodeMirror-measure"></div><div style="position: relative; z-index: 1;"></div><div class="CodeMirror-code" role="presentation"><div class="CodeMirror-activeline" style="position: relative;"><div class="CodeMirror-activeline-background CodeMirror-linebackground"></div><div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div><pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZXLSDK</span>(<span class="cm-variable-3">String</span> <span class="cm-variable">appId</span>, <span class="cm-variable-3">String</span> <span class="cm-variable">appKey</span>, <span class="cm-variable">ZxlConnectionConfig</span> <span class="cm-variable">testConfig</span>);</span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
    </div>
    </div>
    </pre>
    <h4 id='构造方法参数-2'><span>构造方法参数</span></h4>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>appId</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>企业APPID</span></td>
                </tr>
                <tr>
                    <td><span>appKey</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>企业APPKEY</span></td>
                </tr>
                <tr>
                    <td><span>testConfig</span></td>
                    <td><span>ZxlConnectionConfig</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>用户自定义配置接入路由信息等</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h4 id='zxlconnectionconfig对象说明'><span>ZxlConnectionConfig对象说明</span></h4>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>属性名</span></em></th>
                    <th><em><span>属性类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>host</span></td>
                    <td><span>String</span></td>
                    <td><code>https://sdk.zxinchain.com</code></td>
                    <td><span>至信链接入管理地址</span></td>
                </tr>
                <tr>
                    <td><span>connectTime</span></td>
                    <td><span>Integer</span></td>
                    <td><code>30000</code></td>
                    <td><span>连接超时时间</span></td>
                </tr>
                <tr>
                    <td><span>connectionRequestTimeOut</span></td>
                    <td><span>Integer</span></td>
                    <td><code>10000</code></td>
                    <td><span>连接请求超时时间</span></td>
                </tr>
                <tr>
                    <td><span>socketTimeOut</span></td>
                    <td><span>Integer</span></td>
                    <td><code>6000</code></td>
                    <td><span>服务响应超时时间</span></td>
                </tr>
                <tr>
                    <td><span>defaultTrustStore</span></td>
                    <td><span>boolean</span></td>
                    <td><code>true</code></td>
                    <td><span>是否使用默认证书库</span></td>
                </tr>
                <tr>
                    <td><span>proxy</span></td>
                    <td><span>boolean</span></td>
                    <td><code>false</code></td>
                    <td><span>是否开启代理</span></td>
                </tr>
                <tr>
                    <td><span>proxyHost</span></td>
                    <td><span>String</span></td>
                    <td><code>0.0.0.0</code></td>
                    <td><span>代理地址</span></td>
                </tr>
                <tr>
                    <td><span>proxyPort</span></td>
                    <td><span>Integer</span></td>
                    <td><code>10081</code></td>
                    <td><span>代理端口</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h4 id='示例-2'><span>示例</span></h4>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"></div><div class="CodeMirror-measure"></div><div style="position: relative; z-index: 1;"></div><div class="CodeMirror-code" role="presentation" style=""><div class="CodeMirror-activeline" style="position: relative;"><div class="CodeMirror-activeline-background CodeMirror-linebackground"></div><div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div><pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 1、配置对应的host路由(选择环境,以体验环境为例)</span></span></pre>
    </div>
    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZxlConnectionConfig</span> <span class="cm-variable">config</span> <span class="cm-operator">=</span> <span class="cm-variable">ZxlConnectionConfig</span>.<span class="cm-variable">builder</span>()</span></pre>
    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">host</span>(<span class="cm-string">"https://sdk.zxinchain.com"</span>)</span></pre>
    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">build</span>();</span></pre>
    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPID &nbsp; </span></span></pre>
    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appId</span> <span class="cm-operator">=</span> <span class="cm-string">"200515000110001"</span>;</span></pre>
    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPKEY</span></span></pre>
    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appKey</span> <span class="cm-operator">=</span> <span class="cm-string">"0e4bce1b0ef8471fb9140b849e776f48"</span>;</span></pre>
    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 2、实例化sdk对象</span></span></pre>
    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZXLSDK</span> <span class="cm-variable">sdk</span> <span class="cm-operator">=</span> <span class="cm-keyword">new</span> <span class="cm-variable">ZXLSDK</span>(<span class="cm-variable">appId</span>,<span class="cm-variable">appKey</span>,<span class="cm-variable">config</span>);</span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 220px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 220px;"></div>
    </div>
    </div>
    </pre>
    <p>&nbsp;</p>
    <h2 id='生成公私钥对'><span>生成公私钥对</span></h2>
    <h3 id='方法原型-1'><span>方法原型</span></h3>
    <p><span>用户正常情况下只会生成一次公私钥，公私钥可更新，即生成新的公私钥后再进行证书绑定更新即可；另外，用户需要妥善保存私钥，防止泄露，如若出现密钥泄漏情况，建议及时更新钥对。</span></p>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">KeyPair</span> <span class="cm-def">generateKeyPair</span>()</span></pre>
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
    </div>
    </div>
    </pre>
    <p><span>实例化ZXLSDK对象后，可通过类调用其生成公私钥对的方法，返回公私钥对包装类型</span><code>KeyPair</code><span>,使用对象的</span><code>GET</code><span>方法获得对应属性的值，如：</span></p>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 获得公钥</span></span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">pk</span> <span class="cm-operator">=</span> <span class="cm-variable">keyPair</span>.<span class="cm-variable">getPublicKey</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 获得私钥</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">sk</span> <span class="cm-operator">=</span> <span class="cm-variable">keyPair</span>.<span class="cm-variable">getPrivateKey</span>();</span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 88px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 88px;"></div>
    </div>
    </div>
    </pre>
    <h3 id='参数说明-1'><span>参数说明</span></h3>
    <p><span>此方法无参数</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>无</span></td>
                    <td><span>无</span></td>
                    <td><span>无</span></td>
                    <td><span>无</span></td>
                    <td><span>无</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='返回数据-1'><span>返回数据</span></h3>
    <p><span>返回</span><code>KeyPair</code><span>类型的包装类型。</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>publicKey</span></td>
                    <td><span>String</span></td>
                    <td><span>公钥</span></td>
                </tr>
                <tr>
                    <td><span>privateKey</span></td>
                    <td><span>String</span></td>
                    <td><span>私钥</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='示例-3'><span>示例</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java" style="page-break-inside: unset;"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation" style="">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPID &nbsp; </span></span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appId</span> <span class="cm-operator">=</span> <span class="cm-string">"200515000110001"</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPKEY</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appKey</span> <span class="cm-operator">=</span> <span class="cm-string">"0e4bce1b0ef8471fb9140b849e776f48"</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 1、配置对应的host接入服务(生产/测试)路由</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZxlConnectionConfig</span> <span class="cm-variable">config</span> <span class="cm-operator">=</span> <span class="cm-variable">ZxlConnectionConfig</span>.<span class="cm-variable">builder</span>()</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">host</span>(<span class="cm-string">"https://sdk.zxinchain.com"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">build</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 实例化sdk对象</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZXLSDK</span> <span class="cm-variable">sdk</span> <span class="cm-operator">=</span> <span class="cm-keyword">new</span> <span class="cm-variable">ZXLSDK</span>(<span class="cm-variable">appId</span>,<span class="cm-variable">appKey</span>,<span class="cm-variable">config</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 获得公私钥</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">KeyPair</span> <span class="cm-variable">keyPair</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">generateKeyPair</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 公钥</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">pk</span> <span class="cm-operator">=</span> <span class="cm-variable">keyPair</span>.<span class="cm-variable">getPublicKey</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 私钥</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">sk</span> <span class="cm-operator">=</span> <span class="cm-variable">keyPair</span>.<span class="cm-variable">getPrivateKey</span>();</span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 352px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 352px;"></div>
    </div>
    </div>
    </pre>
    <p>&nbsp;</p>
    <h2 id='证书绑定'><span>证书绑定</span></h2>
    <h3 id='方法原型-2'><span>方法原型</span></h3>
    <p><span>方法传入一对公私钥，进行证书绑定，返回绑定结果。</span></p>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">boolean</span> <span class="cm-def">bindUserCert</span>(<span class="cm-variable-3">String</span> <span class="cm-variable">pk</span>, <span class="cm-variable-3">String</span> <span class="cm-variable">sk</span>)</span></pre>
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
    </div>
    </div>
    </pre>
    <h3 id='参数说明-2'><span>参数说明</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>pk</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>公钥</span></td>
                </tr>
                <tr>
                    <td><span>sk</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>私钥</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='返回数据-2'><span>返回数据</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>boolean</span></td>
                    <td><span>boolean</span></td>
                    <td><span>证书绑定结果，true表示成功，失败会抛出相应的错误异常信息</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='示例-4'><span>示例</span></h3>
    <ul>
        <li><span>用户也可以在web页面进行证书绑定操作；当前操作一般完成一次即可，无需每次存证都进行公私钥的绑定与更新。(若私钥泄露可进行更新操作，见</span><a href='#4.2.3更新证书'><span>4.2.3更新证书</span></a></li>
    </ul>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java" style="page-break-inside: unset;"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation" style="">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPID &nbsp; </span></span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appId</span> <span class="cm-operator">=</span> <span class="cm-string">"200515000110001"</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPKEY</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appKey</span> <span class="cm-operator">=</span> <span class="cm-string">"0e4bce1b0ef8471fb9140b849e776f48"</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 1、配置对应的host接入服务(生产/测试)路由</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZxlConnectionConfig</span> <span class="cm-variable">config</span> <span class="cm-operator">=</span> <span class="cm-variable">ZxlConnectionConfig</span>.<span class="cm-variable">builder</span>()</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">host</span>(<span class="cm-string">"https://sdk.zxinchain.com"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">build</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 实例化sdk对象</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZXLSDK</span> <span class="cm-variable">sdk</span> <span class="cm-operator">=</span> <span class="cm-keyword">new</span> <span class="cm-variable">ZXLSDK</span>(<span class="cm-variable">appId</span>,<span class="cm-variable">appKey</span>,<span class="cm-variable">config</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 绑定公私钥时,需要公私钥，这里用生成公私钥的方式获得</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">KeyPair</span> <span class="cm-variable">keyPair</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">generateKeyPair</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">pk</span> <span class="cm-operator">=</span> <span class="cm-variable">keyPair</span>.<span class="cm-variable">getPublicKey</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">sk</span> <span class="cm-operator">=</span> <span class="cm-variable">keyPair</span>.<span class="cm-variable">getPrivateKey</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 绑定后,需用户保存公私钥,再上链时需用到</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 若后续公私钥有更新，需要使用最新的公私钥更新证书，见 证书更新接口</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">boolean</span> <span class="cm-variable">updateFlag</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">bindUserCert</span>(<span class="cm-variable">pk</span>,<span class="cm-variable">sk</span>);</span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 374px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 374px;"></div>
    </div>
    </div>
    </pre>
    <p><span>#### </span></p>
    <h2 id='证书更新'><span>证书更新</span></h2>
    <h3 id='方法原型-3'><span>方法原型</span></h3>
    <p><span>用户使用最新的公私钥更新证书，返回是否更新成功。</span></p>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">boolean</span> <span class="cm-def">updateUserCert</span>(<span class="cm-variable-3">String</span> <span class="cm-variable">pk</span>, <span class="cm-variable-3">String</span> <span class="cm-variable">sk</span>)</span></pre>
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
    </div>
    </div>
    </pre>
    <h3 id='参数说明-3'><span>参数说明</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>pk</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>公钥</span></td>
                </tr>
                <tr>
                    <td><span>sk</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>私钥</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='返回数据-3'><span>返回数据</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>boolean</span></td>
                    <td><span>boolean</span></td>
                    <td><span>证书更新结果，true表示成功，失败会抛出相应的错误异常信息</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='示例-5'><span>示例</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java" style="page-break-inside: unset;"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation" style="">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPID &nbsp; </span></span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appId</span> <span class="cm-operator">=</span> <span class="cm-string">"200515000110001"</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPKEY</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appKey</span> <span class="cm-operator">=</span> <span class="cm-string">"0e4bce1b0ef8471fb9140b849e776f48"</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZxlConnectionConfig</span> <span class="cm-variable">config</span> <span class="cm-operator">=</span> <span class="cm-variable">ZxlConnectionConfig</span>.<span class="cm-variable">builder</span>()</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">host</span>(<span class="cm-string">"https://sdk.zxinchain.com"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">build</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 实例化sdk对象</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZXLSDK</span> <span class="cm-variable">sdk</span> <span class="cm-operator">=</span> <span class="cm-keyword">new</span> <span class="cm-variable">ZXLSDK</span>(<span class="cm-variable">appId</span>,<span class="cm-variable">appKey</span>,<span class="cm-variable">config</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 公私钥对获取</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">KeyPair</span> <span class="cm-variable">keyPair</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">generateKeyPair</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">pk</span> <span class="cm-operator">=</span> <span class="cm-variable">keyPair</span>.<span class="cm-variable">getPublicKey</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">sk</span> <span class="cm-operator">=</span> <span class="cm-variable">keyPair</span>.<span class="cm-variable">getPrivateKey</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 更新后,用户需要保存最新的私钥，后续上链需要用到</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 更新证书</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">boolean</span> <span class="cm-variable">updateFlag</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">updateUserCert</span>(<span class="cm-variable">pk</span>,<span class="cm-variable">sk</span>);</span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 352px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 352px;"></div>
    </div>
    </div>
    </pre>
    <p>&nbsp;</p>
    <h1 id='hash存证'><span>hash存证</span></h1>
    <h2 id='文件hash计算'><span>文件hash计算</span></h2>
    <h3 id='方法原型-4'><span>方法原型</span></h3>
    <p><span>采用SM3算法计算指定路径文件hash值，方法原型：</span></p>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-def">calculateFileHash</span>(<span class="cm-variable-3">String</span> <span class="cm-variable">path</span>)</span></pre>
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
    </div>
    </div>
    </pre>
    <h3 id='参数说明-4'><span>参数说明</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>path</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>无</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='返回数据-4'><span>返回数据</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>string</span></td>
                    <td><span>String</span></td>
                    <td><span>文件hash值</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='示例-6'><span>示例</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation" style="">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPID &nbsp; </span></span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appId</span> <span class="cm-operator">=</span> <span class="cm-string">"200515000110001"</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPKEY</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appKey</span> <span class="cm-operator">=</span> <span class="cm-string">"0e4bce1b0ef8471fb9140b849e776f48"</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZxlConnectionConfig</span> <span class="cm-variable">config</span> <span class="cm-operator">=</span> <span class="cm-variable">ZxlConnectionConfig</span>.<span class="cm-variable">builder</span>()</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">host</span>(<span class="cm-string">"https://sdk.zxinchain.com"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">build</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 实例化sdk对象</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZXLSDK</span> <span class="cm-variable">sdk</span> <span class="cm-operator">=</span> <span class="cm-keyword">new</span> <span class="cm-variable">ZXLSDK</span>(<span class="cm-variable">appId</span>,<span class="cm-variable">appKey</span>,<span class="cm-variable">config</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 路径为存证文件在物理机上的地址</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">evidencePath</span> <span class="cm-operator">=</span> <span class="cm-string">"D:\work\sm2sm3chaincode.txt"</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 文件hash计算</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">evHash</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">calculateFileHash</span>(<span class="cm-variable">evidencePath</span>);</span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 286px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 286px;"></div>
    </div>
    </div>
    </pre>
    <p><span>#### </span></p>
    <h2 id='数据上链存证'><span>数据上链存证</span></h2>
    <h3 id='方法原型a'><span>方法原型A</span></h3>
    <p><span>hash存证场景，不对原文进行报送。</span></p>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">SaveEvData</span> <span class="cm-def">evidenceSave</span>(<span class="cm-variable-3">String</span> <span class="cm-variable">evHash</span>, <span class="cm-variable-3">String</span> <span class="cm-variable">extendInfo</span>, <span class="cm-variable-3">String</span> <span class="cm-variable">sk</span>, <span class="cm-variable-3">String</span> <span class="cm-variable">pk</span>)</span></pre>
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 44px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 44px;"></div>
    </div>
    </div>
    </pre>
    <h3 id='参数说明-5'><span>参数说明</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>evHash</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>存证hash</span></td>
                </tr>
                <tr>
                    <td><span>extendInfo</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>扩展信息</span></td>
                </tr>
                <tr>
                    <td><span>sk</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>私钥</span></td>
                </tr>
                <tr>
                    <td><span>pk</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>公钥</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='方法原型b'><span>方法原型B</span></h3>
    <p><span>此种方案额外报送原文url地址，适用于需要至信链代为出示原文的场景。</span></p>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">SaveEvData</span> <span class="cm-def">evidenceSave</span>(<span class="cm-variable-3">String</span> <span class="cm-variable">evHash</span>, <span class="cm-variable-3">String</span> <span class="cm-variable">url</span>, <span class="cm-variable-3">String</span> <span class="cm-variable">extendInfo</span>, <span class="cm-variable-3">String</span> <span class="cm-variable">sk</span>, <span class="cm-variable-3">String</span> <span class="cm-variable">pk</span>)</span></pre>
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 44px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 44px;"></div>
    </div>
    </div>
    </pre>
    <h3 id='参数说明-6'><span>参数说明</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>evHash</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>存证hash</span></td>
                </tr>
                <tr>
                    <td><span>extendInfo</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>扩展信息</span></td>
                </tr>
                <tr>
                    <td><span>sk</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>私钥</span></td>
                </tr>
                <tr>
                    <td><span>pk</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>公钥</span></td>
                </tr>
                <tr>
                    <td><span>url</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>否</span></td>
                    <td><span>证据文件 url 地址</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <p><span>### </span></p>
    <h3 id='返回数据-5'><span>返回数据</span></h3>
    <p><span>返回</span><code>SaveEvData</code><span>对象，对象信息如下表：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>evId</span></td>
                    <td><span>String</span></td>
                    <td><span>存证Id</span></td>
                </tr>
                <tr>
                    <td><span>txHash</span></td>
                    <td><span>String</span></td>
                    <td><span>交易hash</span></td>
                </tr>
                <tr>
                    <td><span>blockHeight</span></td>
                    <td><span>String</span></td>
                    <td><span>区块高度</span></td>
                </tr>
                <tr>
                    <td><span>createTime</span></td>
                    <td><span>String</span></td>
                    <td><span>创建时间</span></td>
                </tr>
                <tr>
                    <td><span>ext</span></td>
                    <td><span>String</span></td>
                    <td><span>扩展信息，可通过 key=queryCertUrl,获取证书</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='示例-7'><span>示例</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java" style="page-break-inside: unset;"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation" style="">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPID &nbsp; </span></span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appId</span> <span class="cm-operator">=</span> <span class="cm-string">"200515000110001"</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPKEY</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appKey</span> <span class="cm-operator">=</span> <span class="cm-string">"0e4bce1b0ef8471fb9140b849e776f48"</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZxlConnectionConfig</span> <span class="cm-variable">config</span> <span class="cm-operator">=</span> <span class="cm-variable">ZxlConnectionConfig</span>.<span class="cm-variable">builder</span>()</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">host</span>(<span class="cm-string">"https://sdk.zxinchain.com"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">build</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 实例化sdk对象</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZXLSDK</span> <span class="cm-variable">sdk</span> <span class="cm-operator">=</span> <span class="cm-keyword">new</span> <span class="cm-variable">ZXLSDK</span>(<span class="cm-variable">appId</span>,<span class="cm-variable">appKey</span>,<span class="cm-variable">config</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 上链前需要先对存证文件进行hash计算,计算文件hash接口见 文件hash计算接口</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 路径是存证文件在物理机上的地址</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">evidencePath</span> <span class="cm-operator">=</span> <span class="cm-string">"D:\work\sm2sm3chaincode.txt"</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 文件hash计算</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">evHash</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">calculateFileHash</span>(<span class="cm-variable">evidencePath</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">extendInfo</span> <span class="cm-operator">=</span> <span class="cm-string">"用户的扩展信息"</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 存证成功后会返回对应的信息</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">SaveEvData</span> <span class="cm-variable">data</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">evidenceSave</span>(<span class="cm-variable">evHash</span>,<span class="cm-variable">extendInfo</span>,<span class="cm-variable">sk</span>,<span class="cm-variable">pk</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 获得各个属性字段值</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">evId</span> <span class="cm-operator">=</span> <span class="cm-variable">data</span>.<span class="cm-variable">getEvId</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">txHash</span> <span class="cm-operator">=</span> <span class="cm-variable">data</span>.<span class="cm-variable">getTxHash</span>();</span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 440px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 440px;"></div>
    </div>
    </div>
    </pre>
    <p><span>#### </span></p>
    <h2 id='链上信息查询'><span>链上信息查询</span></h2>
    <h3 id='方法原型-5'><span>方法原型</span></h3>
    <p><span>目前提供了4种获取链上存证信息的方法：</span></p>
    <ol start=''>
        <li>
            <p><span name="根据存证hash查询"><span>根据存证hash查询</span></span>
            </p>
            <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
            </div>
            <div class="CodeMirror-measure"></div>
            <div style="position: relative; z-index: 1;"></div>
            <div class="CodeMirror-code" role="presentation">
                <div class="CodeMirror-activeline" style="position: relative;">
                    <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
                    <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
                    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">List</span><span class="cm-operator">&lt;</span><span class="cm-variable">QueryEvData</span><span class="cm-operator">&gt;</span> <span class="cm-def">queryWithEvHash</span>(<span class="cm-variable-3">String</span> <span class="cm-variable">evHash</span>)</span></pre>
                </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
            <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
            </div>
            </div>
            </pre>
        </li>
        <li>
            <p><span name="根据存证id查询"><span>根据存证id查询</span></span>
            </p>
            <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
            </div>
            <div class="CodeMirror-measure"></div>
            <div style="position: relative; z-index: 1;"></div>
            <div class="CodeMirror-code" role="presentation">
                <div class="CodeMirror-activeline" style="position: relative;">
                    <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
                    <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
                    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">List</span><span class="cm-operator">&lt;</span><span class="cm-variable">QueryEvData</span><span class="cm-operator">&gt;</span> <span class="cm-def">queryWithEvId</span>(<span class="cm-variable-3">String</span> <span class="cm-variable">evId</span>)</span></pre>
                </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
            <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
            </div>
            </div>
            </pre>
        </li>
        <li>
            <p><span name="根据交易hash查询"><span>根据交易hash查询</span></span>
            </p>
            <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
            </div>
            <div class="CodeMirror-measure"></div>
            <div style="position: relative; z-index: 1;"></div>
            <div class="CodeMirror-code" role="presentation">
                <div class="CodeMirror-activeline" style="position: relative;">
                    <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
                    <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
                    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">List</span><span class="cm-operator">&lt;</span><span class="cm-variable">QueryEvData</span><span class="cm-operator">&gt;</span> <span class="cm-def">queryWithTxHash</span>(<span class="cm-variable-3">String</span> <span class="cm-variable">txHash</span>)</span></pre>
                </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
            <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
            </div>
            </div>
            </pre>
        </li>
        <li>
            <p><span name="根据任意hash值查询(evId,evHash,txHash)"><span>根据任意hash值查询(evId,evHash,txHash)</span></span>
            </p>
            <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
            </div>
            <div class="CodeMirror-measure"></div>
            <div style="position: relative; z-index: 1;"></div>
            <div class="CodeMirror-code" role="presentation">
                <div class="CodeMirror-activeline" style="position: relative;">
                    <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
                    <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
                    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">List</span><span class="cm-operator">&lt;</span><span class="cm-variable">QueryEvData</span><span class="cm-operator">&gt;</span> <span class="cm-def">queryWithHash</span>(<span class="cm-variable-3">String</span> <span class="cm-variable">hash</span>)</span></pre>
                </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
            <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
            </div>
            </div>
            </pre>
        </li>
    </ol>
    <h3 id='参数说明-7'><span>参数说明</span></h3>
    <ol start=''>
        <li>
            <p><span>根据存证hash查询</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><em><span>参数名</span></em></th>
                            <th><em><span>参数类型</span></em></th>
                            <th><em><span>默认值</span></em></th>
                            <th><em><span>是否必填</span></em></th>
                            <th><em><span>参数描述</span></em></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>evHash</span></td>
                            <td><span>String</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>存证hash</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
        <li>
            <p><span>根据存证id查询</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><em><span>参数名</span></em></th>
                            <th><em><span>参数类型</span></em></th>
                            <th><em><span>默认值</span></em></th>
                            <th><em><span>是否必填</span></em></th>
                            <th><em><span>参数描述</span></em></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>evId</span></td>
                            <td><span>String</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>存证id</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
        <li>
            <p><span>根据交易hash查询</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><em><span>参数名</span></em></th>
                            <th><em><span>参数类型</span></em></th>
                            <th><em><span>默认值</span></em></th>
                            <th><em><span>是否必填</span></em></th>
                            <th><em><span>参数描述</span></em></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>txHash</span></td>
                            <td><span>String</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>交易hash</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
        <li>
            <p><span>根据任意hash值查询(evId,evHash,txHash)</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><em><span>参数名</span></em></th>
                            <th><em><span>参数类型</span></em></th>
                            <th><em><span>默认值</span></em></th>
                            <th><em><span>是否必填</span></em></th>
                            <th><em><span>参数描述</span></em></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>hash</span></td>
                            <td><span>String</span></td>
                            <td><span>无</span></td>
                            <td><span>是</span></td>
                            <td><span>三者(evId,evHash,txHash)其一hash值</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
    </ol>
    <h3 id='返回数据-6'><span>返回数据</span></h3>
    <p><span>4种方式的返回类型一致，均返回</span><code>List&lt;QueryEvData&gt;</code><span>，</span><code>QueryEvData</code><span>类型：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>appId</span></td>
                    <td><span>String</span></td>
                    <td><span>AppId</span></td>
                </tr>
                <tr>
                    <td><span>txHash</span></td>
                    <td><span>String</span></td>
                    <td><span>交易hash</span></td>
                </tr>
                <tr>
                    <td><span>evId</span></td>
                    <td><span>String</span></td>
                    <td><span>存证Id</span></td>
                </tr>
                <tr>
                    <td><span>evHash</span></td>
                    <td><span>String</span></td>
                    <td><span>存证hash</span></td>
                </tr>
                <tr>
                    <td><span>blockHeight</span></td>
                    <td><span>String</span></td>
                    <td><span>区块高度</span></td>
                </tr>
                <tr>
                    <td><span>extendInfo</span></td>
                    <td><span>String</span></td>
                    <td><span>扩展信息</span></td>
                </tr>
                <tr>
                    <td><span>createTime</span></td>
                    <td><span>String</span></td>
                    <td><span>创建时间</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='示例-8'><span>示例</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java" style="page-break-inside: unset;"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation" style="">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPID &nbsp; </span></span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appId</span> <span class="cm-operator">=</span> <span class="cm-string">"200515000110001"</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPKEY</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appKey</span> <span class="cm-operator">=</span> <span class="cm-string">"0e4bce1b0ef8471fb9140b849e776f48"</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZxlConnectionConfig</span> <span class="cm-variable">config</span> <span class="cm-operator">=</span> <span class="cm-variable">ZxlConnectionConfig</span>.<span class="cm-variable">builder</span>()</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">host</span>(<span class="cm-string">"https://sdk.zxinchain.com"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">build</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 实例化sdk对象</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZXLSDK</span> <span class="cm-variable">sdk</span> <span class="cm-operator">=</span> <span class="cm-keyword">new</span> <span class="cm-variable">ZXLSDK</span>(<span class="cm-variable">appId</span>,<span class="cm-variable">appKey</span>,<span class="cm-variable">config</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 根据存证hash查询</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">List</span><span class="cm-operator">&lt;</span><span class="cm-variable">QueryEvData</span><span class="cm-operator">&gt;</span> <span class="cm-variable">evHashLst</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">queryWithEvHash</span>(<span class="cm-variable">evHash</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 根据存证id查询</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">List</span><span class="cm-operator">&lt;</span><span class="cm-variable">QueryEvData</span><span class="cm-operator">&gt;</span> <span class="cm-variable">evIdLst</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">queryWithEvId</span>(<span class="cm-variable">evId</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 根据交易hash查询</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">List</span><span class="cm-operator">&lt;</span><span class="cm-variable">QueryEvData</span><span class="cm-operator">&gt;</span> <span class="cm-variable">txLst</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">queryWithTxHash</span>(<span class="cm-variable">txHash</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 根据任意hash值查询(evId,evHash,txHash)</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">List</span><span class="cm-operator">&lt;</span><span class="cm-variable">QueryEvData</span><span class="cm-operator">&gt;</span> <span class="cm-variable">hashLst</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">queryWithHash</span>(<span class="cm-variable">hash</span>)</span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 374px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 374px;"></div>
    </div>
    </div>
    </pre>
    <p><span>#### </span></p>
    <h1 id='取证服务'><span>取证服务</span></h1>
    <h3 id='取证服务流程'><span>取证服务流程</span></h3>
    <p><img src="./Xnip2021-01-05_20-30-50.jpg" referrerpolicy="no-referrer"></p>
    <p>&nbsp;</p>
    <h2 id='截图取证服务'><span>截图取证服务</span></h2>
    <p><span>用户想要获取 指定 URL 的证据，并通过 </span><strong><span>截图</span></strong><span> 的方式 将证据保存以供后期调用</span></p>
    <p><span>返回的 </span><strong><span>订单号</span></strong><span> 用于【获取取证状态及结果】接口调用</span></p>
    <h3 id='图片取证'><span>图片取证</span></h3>
    <h4 id='方法原型-6'><span>方法原型</span></h4>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-def">evidenceObtainPic</span>(<span class="cm-variable-3">String</span> <span class="cm-variable">webUrls</span>, <span class="cm-variable-3">String</span> <span class="cm-variable">title</span>, <span class="cm-variable-3">String</span> <span class="cm-variable">remark</span>)</span></pre>
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
    </div>
    </div>
    </pre>
    <h4 id='参数说明-8'><span>参数说明</span></h4>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>webUrls</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>取证地址</span></td>
                </tr>
                <tr>
                    <td><span>title</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>证据标题</span></td>
                </tr>
                <tr>
                    <td><span>remark</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>否(可为null)</span></td>
                    <td><span>证据备注</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h4 id='返回数据-7'><span>返回数据</span></h4>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>string</span></td>
                    <td><span>String</span></td>
                    <td><span>订单号,根据订单号可查询取证结果</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h4 id='示例-9'><span>示例</span></h4>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><span><span>​</span>x</span></div><div class="CodeMirror-measure"></div><div style="position: relative; z-index: 1;"></div><div class="CodeMirror-code" role="presentation" style=""><div class="CodeMirror-activeline" style="position: relative;"><div class="CodeMirror-activeline-background CodeMirror-linebackground"></div><div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div><pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPID &nbsp; </span></span></pre>
    </div>
    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appId</span> <span class="cm-operator">=</span> <span class="cm-string">"200515000110001"</span>;</span></pre>
    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPKEY</span></span></pre>
    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appKey</span> <span class="cm-operator">=</span> <span class="cm-string">"0e4bce1b0ef8471fb9140b849e776f48"</span>;</span></pre>
    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZxlConnectionConfig</span> <span class="cm-variable">config</span> <span class="cm-operator">=</span> <span class="cm-variable">ZxlConnectionConfig</span>.<span class="cm-variable">builder</span>()</span></pre>
    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">host</span>(<span class="cm-string">"https://sdk.zxinchain.com"</span>)</span></pre>
    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">build</span>();</span></pre>
    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 实例化sdk对象</span></span></pre>
    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZXLSDK</span> <span class="cm-variable">sdk</span> <span class="cm-operator">=</span> <span class="cm-keyword">new</span> <span class="cm-variable">ZXLSDK</span>(<span class="cm-variable">appId</span>,<span class="cm-variable">appKey</span>,<span class="cm-variable">config</span>);</span></pre>
    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 图片取证接口</span></span></pre>
    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">orderNo</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">evidenceObtainPic</span>(<span class="cm-variable-3">String</span> <span class="cm-variable">webUrls</span>, <span class="cm-variable-3">String</span> <span class="cm-variable">title</span>, <span class="cm-variable-3">String</span> <span class="cm-variable">remark</span>);</span></pre>
    <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 286px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 286px;"></div>
    </div>
    </div>
    </pre>
    <p>&nbsp;</p>
    <h3 id='获取取证任务状态及结果-1'><span>获取取证任务状态及结果</span></h3>
    <p><span>用户通过 orderNo 查询前期调用取证接口的结果</span></p>
    <h4 id='方法原型-7'><span>方法原型</span></h4>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">EvIdData</span> <span class="cm-def">getEvidenceStatus</span>(<span class="cm-variable-3">String</span> <span class="cm-variable">orderNo</span>)</span></pre>
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
    </div>
    </div>
    </pre>
    <h4 id='参数说明-9'><span>参数说明</span></h4>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>orderNo</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>订单编号(由视频/图片取证产生)</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h4 id='返回数据-8'><span>返回数据</span></h4>
    <p><span>返回</span><code>EvIdData</code><span>对象，对象说明如下：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>status</span></td>
                    <td><span>int</span></td>
                    <td><span>当前任务状态[0:执行中&gt;&gt;2成功&gt;&gt;10失败]</span></td>
                </tr>
                <tr>
                    <td><span>evidUrl</span></td>
                    <td><span>String</span></td>
                    <td><span>成功状态下，取证证据下载地址</span></td>
                </tr>
                <tr>
                    <td><span>voucherUrl</span></td>
                    <td><span>String</span></td>
                    <td><span>成功状态下，取证证书下载地址</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h4 id='示例-10'><span>示例</span></h4>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation" style="">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPID &nbsp; </span></span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appId</span> <span class="cm-operator">=</span> <span class="cm-string">"200515000110001"</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPKEY</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appKey</span> <span class="cm-operator">=</span> <span class="cm-string">"0e4bce1b0ef8471fb9140b849e776f48"</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZxlConnectionConfig</span> <span class="cm-variable">config</span> <span class="cm-operator">=</span> <span class="cm-variable">ZxlConnectionConfig</span>.<span class="cm-variable">builder</span>()</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">host</span>(<span class="cm-string">"https://sdk.zxinchain.com"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">build</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 实例化sdk对象</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZXLSDK</span> <span class="cm-variable">sdk</span> <span class="cm-operator">=</span> <span class="cm-keyword">new</span> <span class="cm-variable">ZXLSDK</span>(<span class="cm-variable">appId</span>,<span class="cm-variable">appKey</span>,<span class="cm-variable">config</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 获取取证证书及任务状态结果 &gt;&gt; 当前结果中返回：1、证书任务状态|2、取证证据下载地址|3、取证证书下载地址</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 其中只有当任务状态为1(即成功)时,才会有对应的证据/证书下载地址</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">EvIdData</span> <span class="cm-variable">evIdData</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">getEvidenceStatus</span>(<span class="cm-variable">orderNo</span>);</span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 286px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 286px;"></div>
    </div>
    </div>
    </pre>
    <p>&nbsp;</p>
    <h2 id='视频取证服务'><span>视频取证服务</span></h2>
    <h3 id='视频取证'><span>视频取证</span></h3>
    <p><span>用户想要获取 指定 URL 的证据，并通过录屏的方式 将证据保存以便后期调用</span></p>
    <p><span>返回的 订单号 用于【获取取证任务状态及结果】 接口调用</span></p>
    <h4 id='方法原型-8'><span>方法原型</span></h4>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-def">evidenceObtainVideo</span>(<span class="cm-variable-3">String</span> <span class="cm-variable">webUrls</span>, <span class="cm-variable-3">String</span> <span class="cm-variable">title</span>, <span class="cm-variable-3">String</span> <span class="cm-variable">remark</span>, <span class="cm-variable-3">int</span> <span class="cm-variable">duration</span>)</span></pre>
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 44px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 44px;"></div>
    </div>
    </div>
    </pre>
    <h4 id='参数说明-10'><span>参数说明</span></h4>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>webUrls</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>取证地址</span></td>
                </tr>
                <tr>
                    <td><span>title</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>证据标题</span></td>
                </tr>
                <tr>
                    <td><span>remark</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>否(可为null)</span></td>
                    <td><span>证据备注</span></td>
                </tr>
                <tr>
                    <td><span>duration</span></td>
                    <td><span>int</span></td>
                    <td><span>无</span></td>
                    <td><span>否(可为null)</span></td>
                    <td><span>录屏时长 单位:秒</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h4 id='返回数据-9'><span>返回数据</span></h4>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>string</span></td>
                    <td><span>String</span></td>
                    <td><span>订单号,根据订单号可查询取证结果</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h4 id='示例-11'><span>示例</span></h4>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java" style="page-break-inside: unset;"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation" style="">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPID &nbsp; </span></span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appId</span> <span class="cm-operator">=</span> <span class="cm-string">""</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPKEY</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appKey</span> <span class="cm-operator">=</span> <span class="cm-string">""</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">webUrls</span> <span class="cm-operator">=</span> <span class="cm-string">""</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">title</span> <span class="cm-operator">=</span> <span class="cm-string">""</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">remark</span> <span class="cm-operator">=</span> <span class="cm-string">""</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">int</span> <span class="cm-variable">duration</span> <span class="cm-operator">=</span> <span class="cm-number">100</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZxlConnectionConfig</span> <span class="cm-variable">config</span> <span class="cm-operator">=</span> <span class="cm-variable">ZxlConnectionConfig</span>.<span class="cm-variable">builder</span>()</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">host</span>(<span class="cm-string">"https://sdk.zxinchain.com"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">build</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 实例化sdk对象</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZXLSDK</span> <span class="cm-variable">sdk</span> <span class="cm-operator">=</span> <span class="cm-keyword">new</span> <span class="cm-variable">ZXLSDK</span>(<span class="cm-variable">appId</span>,<span class="cm-variable">appKey</span>,<span class="cm-variable">config</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 视频取证</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">orderNo</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">evidenceObtainVideo</span>(<span class="cm-variable">webUrls</span>, <span class="cm-variable">title</span>, <span class="cm-variable">remark</span>, <span class="cm-variable">duration</span>);</span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 330px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 330px;"></div>
    </div>
    </div>
    </pre>
    <p>&nbsp;</p>
    <h3 id='获取取证任务状态及结果-2'><span>获取取证任务状态及结果</span></h3>
    <p><span>用户根据 orderNo 查询之前调用取证接口的结果</span></p>
    <h4 id='方法原型-9'><span>方法原型</span></h4>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">EvIdData</span> <span class="cm-def">getEvidenceStatus</span>(<span class="cm-variable-3">String</span> <span class="cm-variable">orderNo</span>)</span></pre>
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
    </div>
    </div>
    </pre>
    <h4 id='参数说明-11'><span>参数说明</span></h4>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>orderNo</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>订单编号(由视频/图片取证产生)</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h4 id='返回数据-10'><span>返回数据</span></h4>
    <p><span>返回</span><code>EvIdData</code><span>对象，对象说明如下：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>status</span></td>
                    <td><span>int</span></td>
                    <td><span>当前任务状态[0:执行中&gt;&gt;2成功&gt;&gt;10失败]</span></td>
                </tr>
                <tr>
                    <td><span>evidUrl</span></td>
                    <td><span>String</span></td>
                    <td><span>成功状态下，取证证据下载地址</span></td>
                </tr>
                <tr>
                    <td><span>voucherUrl</span></td>
                    <td><span>String</span></td>
                    <td><span>成功状态下，取证证书下载地址</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h4 id='示例-12'><span>示例</span></h4>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation" style="">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPID &nbsp; </span></span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appId</span> <span class="cm-operator">=</span> <span class="cm-string">"200515000110001"</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPKEY</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appKey</span> <span class="cm-operator">=</span> <span class="cm-string">"0e4bce1b0ef8471fb9140b849e776f48"</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZxlConnectionConfig</span> <span class="cm-variable">config</span> <span class="cm-operator">=</span> <span class="cm-variable">ZxlConnectionConfig</span>.<span class="cm-variable">builder</span>()</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">host</span>(<span class="cm-string">"https://sdk.zxinchain.com"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">build</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 实例化sdk对象</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZXLSDK</span> <span class="cm-variable">sdk</span> <span class="cm-operator">=</span> <span class="cm-keyword">new</span> <span class="cm-variable">ZXLSDK</span>(<span class="cm-variable">appId</span>,<span class="cm-variable">appKey</span>,<span class="cm-variable">config</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 获取取证证书及任务状态结果 &gt;&gt; 当前结果中返回：1、证书任务状态|2、取证证据下载地址|3、取证证书下载地址</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 其中只有当任务状态为1(即成功)时,才会有对应的证据/证书下载地址</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">EvIdData</span> <span class="cm-variable">evIdData</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">getEvidenceStatus</span>(<span class="cm-variable">orderNo</span>);</span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 286px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 286px;"></div>
    </div>
    </div>
    </pre>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <h1 id='侵权监控服务'><span>侵权监控服务</span></h1>
    <h2 id='创建侵权监控任务'><span>创建侵权监控任务</span></h2>
    <h3 id='方法原型-10'><span>方法原型</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">createTortTask</span>(<span class="cm-variable-3">String</span> <span class="cm-variable">url</span>, <span class="cm-variable">TypeEnum</span> <span class="cm-variable">type</span>, <span class="cm-variable">SourceEnum</span> <span class="cm-variable">source</span>, <span class="cm-variable">LocalDate</span> <span class="cm-variable">startDate</span>, <span class="cm-variable">LocalDate</span> <span class="cm-variable">endDate</span>)</span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 66px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 66px;"></div>
    </div>
    </div>
    </pre>
    <h3 id='参数说明-12'><span>参数说明</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>url</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>资源url</span></td>
                </tr>
                <tr>
                    <td><span>type</span></td>
                    <td><span>TypeEnum</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>资源类型</span></td>
                </tr>
                <tr>
                    <td><span>source</span></td>
                    <td><span>SourceEnum</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>数据源头</span></td>
                </tr>
                <tr>
                    <td><span>startDate</span></td>
                    <td><span>LocalDate</span></td>
                    <td><span>无</span></td>
                    <td><span>否</span></td>
                    <td><span>监控开始时间，若不填则从当前日期开始。</span></td>
                </tr>
                <tr>
                    <td><span>endDate</span></td>
                    <td><span>LocalDate</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>监控结束时间</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <p><span>枚举类型说明</span></p>
    <p><span>资源类型：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>编码</span></th>
                    <th><span>常量</span></th>
                    <th><span>备注</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>1</span></td>
                    <td><span>MATERIAL_PHOTO</span></td>
                    <td><span>图片类型</span></td>
                </tr>
                <tr>
                    <td><span>3</span></td>
                    <td><span>MATERIAL_VIDEO</span></td>
                    <td><span>视频类型</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <p><span>数据来源：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>编码</span></th>
                    <th><span>常量</span></th>
                    <th><span>备注</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>1</span></td>
                    <td><span>PROPERTY</span></td>
                    <td><span>房产类监测，type为图片时可用</span></td>
                </tr>
                <tr>
                    <td><span>2</span></td>
                    <td><span>ANY</span></td>
                    <td><span>原创类监测，type为图片时可用</span></td>
                </tr>
                <tr>
                    <td><span>21</span></td>
                    <td><span>SHORT_VIDEO</span></td>
                    <td><span>短视频监测，type为视频时可用</span></td>
                </tr>
                <tr>
                    <td><span>22</span></td>
                    <td><span>LONG_VIDEO</span></td>
                    <td><span>长视频监测，type为视频时可用</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='返回数据-11'><span>返回数据</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>taskId</span></td>
                    <td><span>String</span></td>
                    <td><span>任务 Id</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='示例-13'><span>示例</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation" style="">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">tortTask</span> <span class="cm-operator">=</span> <span class="cm-variable">zxlsdk</span>.<span class="cm-variable">createTortTask</span>(</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-string">"https://ruidetest-1302317679.cos.ap-nanjing.myqcloud.com/%E6%A1%8C%E9%9D%A2.png"</span>,</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">TypeEnum</span>.<span class="cm-variable">MATERIAL_PHOTO</span>,</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">SourceEnum</span>.<span class="cm-variable">PROPERTY</span>,</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">LocalDate</span>.<span class="cm-variable">now</span>(),</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">LocalDate</span>.<span class="cm-variable">now</span>().<span class="cm-variable">plusDays</span>(<span class="cm-number">2</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 242px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 242px;"></div>
    </div>
    </div>
    </pre>
    <p>&nbsp;</p>
    <h2 id='查看监控任务结果'><span>查看监控任务结果</span></h2>
    <h3 id='方法原型-11'><span>方法原型</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">queryTortTaskResult</span>(<span class="cm-variable-3">String</span> <span class="cm-variable">taskId</span>)</span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 44px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 44px;"></div>
    </div>
    </div>
    </pre>
    <h3 id='参数说明-13'><span>参数说明</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>taskId</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>任务Id(创建任务接口的返回值)</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='返回数据-12'><span>返回数据</span></h3>
    <p><span>返回</span><code>ClueData</code><span>对象集合，</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>List</span>
                       &lt;ClueData&gt;
                    </td>
                    <td><span>List</span></td>
                    <td><span>侵权线索对象集合</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <p><code>ClueData</code><span>对象说明：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>clueId</span></td>
                    <td><span>long</span></td>
                    <td><span>线索唯一Id</span></td>
                </tr>
                <tr>
                    <td><span>playUrl</span></td>
                    <td><span>String</span></td>
                    <td><span>侵权链接</span></td>
                </tr>
                <tr>
                    <td><span>title</span></td>
                    <td><span>String</span></td>
                    <td><span>侵权标题</span></td>
                </tr>
                <tr>
                    <td><span>pubtime</span></td>
                    <td><span>String</span></td>
                    <td><span>发布时间</span></td>
                </tr>
                <tr>
                    <td><span>platform</span></td>
                    <td><span>String</span></td>
                    <td><span>侵权平台</span></td>
                </tr>
                <tr>
                    <td><span>author</span></td>
                    <td><span>String</span></td>
                    <td><span>侵权作者</span></td>
                </tr>
                <tr>
                    <td><span>findTime</span></td>
                    <td><span>String</span></td>
                    <td><span>发现侵权时间</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='示例-14'><span>示例</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">List</span><span class="cm-operator">&lt;</span><span class="cm-variable">ClueData</span><span class="cm-operator">&gt;</span> <span class="cm-variable">clueData</span> <span class="cm-operator">=</span> <span class="cm-variable">zxlsdk</span>.<span class="cm-variable">queryTortTaskResult</span>(<span class="cm-string">""</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">System</span>.<span class="cm-variable">out</span>.<span class="cm-variable">println</span>(<span class="cm-variable">clueData</span>.<span class="cm-variable">toString</span>());</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 88px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 88px;"></div>
    </div>
    </div>
    </pre>
    <h2 id='创建侵权监控任务（新版）'><span>创建侵权监控任务（新版）</span></h2>
    <h3 id='方法原型-12'><span>方法原型</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-keyword">public</span> <span class="cm-variable">TortResp</span> <span class="cm-def">submitTortTask</span>(<span class="cm-variable">Tort</span> <span class="cm-variable">tort</span>)</span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 44px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 44px;"></div>
    </div>
    </div>
    </pre>
    <h3 id='参数说明-14'><span>参数说明</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>Tort</span></td>
                    <td><span>Tort</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>侵权监控参数</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <p><span>Tort 结构如下：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>url</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>资源url</span></td>
                </tr>
                <tr>
                    <td><span>title</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>标题</span></td>
                </tr>
                <tr>
                    <td><span>keyword</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>否</span></td>
                    <td><span>关键字列表，半角分号（;）分割</span></td>
                </tr>
                <tr>
                    <td><span>type</span></td>
                    <td><span>TypeEnum</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>资源类型</span></td>
                </tr>
                <tr>
                    <td><span>source</span></td>
                    <td><span>SourceEnum</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>数据源头</span></td>
                </tr>
                <tr>
                    <td><span>startDate</span></td>
                    <td><span>LocalDate</span></td>
                    <td><span>无</span></td>
                    <td><span>否</span></td>
                    <td><span>监控开始时间，若不填则从当前日期开始。</span></td>
                </tr>
                <tr>
                    <td><span>endDate</span></td>
                    <td><span>LocalDate</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>监控结束时间</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <p><span>枚举类型说明</span></p>
    <p><span>资源类型：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>编码</span></th>
                    <th><span>常量</span></th>
                    <th><span>备注</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>1</span></td>
                    <td><span>MATERIAL_PHOTO</span></td>
                    <td><span>图片类型</span></td>
                </tr>
                <tr>
                    <td><span>2</span></td>
                    <td><span>MATERIAL_TEXT</span></td>
                    <td><span>文字类型</span></td>
                </tr>
                <tr>
                    <td><span>3</span></td>
                    <td><span>MATERIAL_VIDEO</span></td>
                    <td><span>视频类型</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <p><span>数据来源：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>编码</span></th>
                    <th><span>常量</span></th>
                    <th><span>备注</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>1</span></td>
                    <td><span>PROPERTY</span></td>
                    <td><span>房产类监测，type为图片时可用</span></td>
                </tr>
                <tr>
                    <td><span>2</span></td>
                    <td><span>ANY</span></td>
                    <td><span>原创类监测，type为图片时可用</span></td>
                </tr>
                <tr>
                    <td><span>21</span></td>
                    <td><span>SHORT_VIDEO</span></td>
                    <td><span>短视频监测，type为视频时可用</span></td>
                </tr>
                <tr>
                    <td><span>22</span></td>
                    <td><span>LONG_VIDEO</span></td>
                    <td><span>长对短监测:监测范围和短视频一样，type为视频时可用</span></td>
                </tr>
                <tr>
                    <td><span>23</span></td>
                    <td><span>LONG_TO_LONG_VIDEO</span></td>
                    <td><span>长对长监测:全网视频小网站，type为视频时可用</span></td>
                </tr>
                <tr>
                    <td><span>41</span></td>
                    <td><span>NEWS_TEXT</span></td>
                    <td><span>新闻咨询类，监测范围全网</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='返回数据-13'><span>返回数据</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>taskId</span></td>
                    <td><span>String</span></td>
                    <td><span>任务 Id</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='示例-15'><span>示例</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation" style="">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">Tort</span> <span class="cm-variable">tort</span> <span class="cm-operator">=</span> <span class="cm-variable">Tort</span>.<span class="cm-variable">builder</span>().<span class="cm-variable">url</span>(<span class="cm-string">"https://inews.gtimg.com/newsapp_bt/0/5001rcns97nr04er/1000?appid=ee22ce76657290e1"</span>)</span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">title</span>(<span class="cm-string">"测试图片"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">keyword</span>(<span class="cm-string">"月亮;太空"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">type</span>(<span class="cm-variable">TypeEnum</span>.<span class="cm-variable">MATERIAL_PHOTO</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">source</span>(<span class="cm-variable">SourceEnum</span>.<span class="cm-variable">ANY</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">startDate</span>(<span class="cm-variable">LocalDate</span>.<span class="cm-variable">now</span>())</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">endDate</span>(<span class="cm-variable">LocalDate</span>.<span class="cm-variable">now</span>().<span class="cm-variable">plusDays</span>(<span class="cm-number">10</span>))</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">build</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">TortResp</span> <span class="cm-variable">resp</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">submitTortTask</span>(<span class="cm-variable">tort</span>);</span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 264px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 264px;"></div>
    </div>
    </div>
    </pre>
    <h2 id='查看监控任务结果（新版）'><span>查看监控任务结果（新版）</span></h2>
    <h3 id='方法原型-13'><span>方法原型</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-keyword">public</span> <span class="cm-variable">TortQueryResp</span> <span class="cm-def">queryTortTaskResult</span>(<span class="cm-variable">TortQuery</span> <span class="cm-variable">tortQuery</span>)</span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 44px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 44px;"></div>
    </div>
    </div>
    </pre>
    <h3 id='参数说明-15'><span>参数说明</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>tortQuery</span></td>
                    <td><span>TortQuery</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>监控任务结果查询参数</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <p><span>TortQuery 结构如下：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>taskId</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>任务Id(创建任务接口的返回值)</span></td>
                </tr>
                <tr>
                    <td><span>offset</span></td>
                    <td><span>int</span></td>
                    <td><span>0</span></td>
                    <td><span>否</span></td>
                    <td><span>侵权线索列表起始位置，默认为 0</span></td>
                </tr>
                <tr>
                    <td><span>limit</span></td>
                    <td><span>int</span></td>
                    <td><span>无</span></td>
                    <td><span>否</span></td>
                    <td><span>每次返回侵权线索列表数量，不填则表示返回全部</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='返回数据-14'><span>返回数据</span></h3>
    <p><span>返回</span><code>ClueData</code><span>对象集合:</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>clueList</span></td>
                    <td><span>List</span>
                       &lt;ClueData&gt;
                    </td>
                    <td><span>侵权线索对象集合</span></td>
                </tr>
                <tr>
                    <td><span>count</span></td>
                    <td><span>int</span></td>
                    <td><span>监测线索总数量</span></td>
                </tr>
                <tr>
                    <td><span>status</span></td>
                    <td><span>int</span></td>
                    <td><span>1：未开始，2：监测中 10: 已停止</span></td>
                </tr>
                <tr>
                    <td><span>requestId</span></td>
                    <td><span>string</span></td>
                    <td><span>请求Id</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <p><code>ClueData</code><span>对象说明：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>clueId</span></td>
                    <td><span>long</span></td>
                    <td><span>线索唯一Id</span></td>
                </tr>
                <tr>
                    <td><span>playUrl</span></td>
                    <td><span>String</span></td>
                    <td><span>侵权链接</span></td>
                </tr>
                <tr>
                    <td><span>title</span></td>
                    <td><span>String</span></td>
                    <td><span>侵权标题</span></td>
                </tr>
                <tr>
                    <td><span>pubtime</span></td>
                    <td><span>String</span></td>
                    <td><span>发布时间</span></td>
                </tr>
                <tr>
                    <td><span>platform</span></td>
                    <td><span>String</span></td>
                    <td><span>侵权平台</span></td>
                </tr>
                <tr>
                    <td><span>author</span></td>
                    <td><span>String</span></td>
                    <td><span>侵权作者</span></td>
                </tr>
                <tr>
                    <td><span>findTime</span></td>
                    <td><span>String</span></td>
                    <td><span>发现侵权时间</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='示例-16'><span>示例</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation" style="">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">TortQuery</span> <span class="cm-variable">query</span> <span class="cm-operator">=</span> <span class="cm-variable">TortQuery</span>.<span class="cm-variable">builder</span>()</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">taskId</span>(<span class="cm-string">""</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">build</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">TortQueryResp</span> <span class="cm-variable">resp</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">queryTortTaskResult</span>(<span class="cm-variable">query</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 154px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 154px;"></div>
    </div>
    </div>
    </pre>
    <p>&nbsp;</p>
    <h1 id='存证任务'><span>存证任务</span></h1>
    <h2 id='发起数字版权存证请求'><span>发起数字版权存证请求</span></h2>
    <h3 id='方法原型-14'><span>方法原型</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">DciClaimResp</span> <span class="cm-def">submitDciClaim</span>(<span class="cm-variable">DciClaim</span> <span class="cm-variable">dciClaim</span>)</span></pre>
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
    </div>
    </div>
    </pre>
    <h3 id='参数说明-16'><span>参数说明</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>dciClaim</span></td>
                    <td><span>DciClaim</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>存证信息</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <p>&nbsp;</p>
    <p><span>dciClaim 字段如下：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段名</span></th>
                    <th><span>类型</span></th>
                    <th><span>默认值</span></th>
                    <th><span>是否必填</span></th>
                    <th><span>备注</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>dciName</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>作品名字</span></td>
                </tr>
                <tr>
                    <td><span>proposerEmail</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>申请人邮箱</span></td>
                </tr>
                <tr>
                    <td><span>proposerSk</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>申请人私钥</span></td>
                </tr>
                <tr>
                    <td><span>dciType</span></td>
                    <td><span>DciTypeEnum</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>作品类型（见下文 DciTypeEnum）</span></td>
                </tr>
                <tr>
                    <td><span>dciCreateProperty</span></td>
                    <td><span>DciCreatePropertyEnum</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>作品创作属性 （见下文 DciCreatePropertyEnum）</span></td>
                </tr>
                <tr>
                    <td><span>dciUrl</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>作品url</span></td>
                </tr>
                <tr>
                    <td><span>authorList</span></td>
                    <td><span>ArrayList</span>
                        &lt;DciAuthor&gt;
                    </td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>作品作者信息</span></td>
                </tr>
                <tr>
                    <td><span>rightList</span></td>
                    <td><span>ArrayList</span>
                        &lt;DciRight&gt;
                    </td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>权利人信息列表</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <p>&nbsp;</p>
    <p><span>authorList 中结构如下：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>默认值</span></th>
                    <th><span>是否必填</span></th>
                    <th><span>备注</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>authorType</span></td>
                    <td><span>AuthorTypeEnum</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>作者类型（见下文 AuthorTypeEnum）</span></td>
                </tr>
                <tr>
                    <td><span>authorName</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>作者名字</span></td>
                </tr>
                <tr>
                    <td><span>authorIdCard</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>作者证件号</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <p>&nbsp;</p>
    <p><span>rightList 中结构如下：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段名</span></th>
                    <th><span>类型</span></th>
                    <th><span>默认值</span></th>
                    <th><span>是否必填</span></th>
                    <th><span>备注</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>type</span></td>
                    <td><span>RightTypeEnum</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>权利类型（见下文 RightTypeEnum）</span></td>
                </tr>
                <tr>
                    <td><span>righterInfoList</span></td>
                    <td><span>ArrayList</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>权利人列表</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <p>&nbsp;</p>
    <p><span>righterInfoList 中结构如下：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段名</span></th>
                    <th><span>类型</span></th>
                    <th><span>默认值</span></th>
                    <th><span>是否必填</span></th>
                    <th><span>备注</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>righterEmail</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>权利人邮箱</span></td>
                </tr>
                <tr>
                    <td><span>righterType</span></td>
                    <td><span>RighterTypeEnum</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>权利人类型（见下文 RighterTypeEnum）</span></td>
                </tr>
                <tr>
                    <td><span>righterName</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>权利人名字</span></td>
                </tr>
                <tr>
                    <td><span>righterIdCard</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>权利人证件号</span></td>
                </tr>
                <tr>
                    <td><span>righterGainedWay</span></td>
                    <td><span>GainedWayEnum</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>权利获取途径（见下文 GainedWayEnum）</span></td>
                </tr>
                <tr>
                    <td><span>righterSk</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>权利人的私钥</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <p>&nbsp;</p>
    <ul>
        <li>
            <p><span>枚举类型说明</span></p>
            <p><span>DciTypeEnum（作品类型）：</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>编码</span></th>
                            <th><span>常量</span></th>
                            <th><span>备注</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>1</span></td>
                            <td><span>DCI_TYPE_TEXT</span></td>
                            <td><span>文字作品</span></td>
                        </tr>
                        <tr>
                            <td><span>2</span></td>
                            <td><span>DCI_TYPE_MUSIC</span></td>
                            <td><span>音乐作品</span></td>
                        </tr>
                        <tr>
                            <td><span>3</span></td>
                            <td><span>DCI_TYPE_ART_ARCH</span></td>
                            <td><span>美术或建筑作品</span></td>
                        </tr>
                        <tr>
                            <td><span>4</span></td>
                            <td><span>DCI_TYPE_FILMING</span></td>
                            <td><span>摄影作品</span></td>
                        </tr>
                        <tr>
                            <td><span>5</span></td>
                            <td><span>DCI_TYPE_AUDIOVISUAL</span></td>
                            <td><span>视听作品</span></td>
                        </tr>
                        <tr>
                            <td><span>6</span></td>
                            <td><span>DCI_TYPE_PIC_MODEL</span></td>
                            <td><span>设计图或示意图等图形模型作品</span></td>
                        </tr>
                        <tr>
                            <td><span>7</span></td>
                            <td><span>DCI_TYPE_SOFTWARE</span></td>
                            <td><span>计算机软件</span></td>
                        </tr>
                        <tr>
                            <td><span>8</span></td>
                            <td><span>DCI_TYPE_OTHER</span></td>
                            <td><span>符合作品特征的其他智力成果</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
            <p><span>GainedWayEnum（权利获取途径）：</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>编码</span></th>
                            <th><span>常量</span></th>
                            <th><span>备注</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>1</span></td>
                            <td><span>GAINED_WAY_ORIGINAL</span></td>
                            <td><span>原始</span></td>
                        </tr>
                        <tr>
                            <td><span>2</span></td>
                            <td><span>GAINED_WAY_ACCEPT</span></td>
                            <td><span>承受</span></td>
                        </tr>
                        <tr>
                            <td><span>3</span></td>
                            <td><span>GAINED_WAY_INHERIT</span></td>
                            <td><span>继承</span></td>
                        </tr>
                        <tr>
                            <td><span>4</span></td>
                            <td><span>GAINED_WAY_OTHER</span></td>
                            <td><span>其他</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
            <p><span>DciCreatePropertyEnum（作品创作属性）：</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>编码</span></th>
                            <th><span>常量</span></th>
                            <th><span>备注</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>1</span></td>
                            <td><span>DCI_CREATE_PROPERTY_ORIGINAL</span></td>
                            <td><span>原创</span></td>
                        </tr>
                        <tr>
                            <td><span>2</span></td>
                            <td><span>DCI_CREATE_PROPERTY_ADAPT</span></td>
                            <td><span>改编</span></td>
                        </tr>
                        <tr>
                            <td><span>3</span></td>
                            <td><span>DCI_CREATE_PROPERTY_TRANSLATE</span></td>
                            <td><span>翻译</span></td>
                        </tr>
                        <tr>
                            <td><span>4</span></td>
                            <td><span>DCI_CREATE_PROPERTY_COLLECTION</span></td>
                            <td><span>汇编</span></td>
                        </tr>
                        <tr>
                            <td><span>5</span></td>
                            <td><span>DCI_CREATE_PROPERTY_COMMENT</span></td>
                            <td><span>注释</span></td>
                        </tr>
                        <tr>
                            <td><span>6</span></td>
                            <td><span>DCI_CREATE_PROPERTY_TIDY</span></td>
                            <td><span>整理</span></td>
                        </tr>
                        <tr>
                            <td><span>7</span></td>
                            <td><span>DCI_CREATE_PROPERTY_OTHER</span></td>
                            <td><span>其他</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
            <p><span>RightTypeEnum（权利类型）：</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>编码</span></th>
                            <th><span>常量</span></th>
                            <th><span>备注</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>1</span></td>
                            <td><span>RIGHT_TYPE_PUBLIC</span></td>
                            <td><span>发表权</span></td>
                        </tr>
                        <tr>
                            <td><span>2</span></td>
                            <td><span>RIGHT_TYPE_SIGN</span></td>
                            <td><span>署名权</span></td>
                        </tr>
                        <tr>
                            <td><span>3</span></td>
                            <td><span>RIGHT_TYPE_MODIFY</span></td>
                            <td><span>修改权</span></td>
                        </tr>
                        <tr>
                            <td><span>4</span></td>
                            <td><span>RIGHT_TYPE_FULL_PROTECT</span></td>
                            <td><span>保护权</span></td>
                        </tr>
                        <tr>
                            <td><span>5</span></td>
                            <td><span>RIGHT_TYPE_COPY</span></td>
                            <td><span>复制权</span></td>
                        </tr>
                        <tr>
                            <td><span>6</span></td>
                            <td><span>RIGHT_TYPE_DISTRIBUTION</span></td>
                            <td><span>发行权</span></td>
                        </tr>
                        <tr>
                            <td><span>7</span></td>
                            <td><span>RIGHT_TYPE_RENT</span></td>
                            <td><span>出租权</span></td>
                        </tr>
                        <tr>
                            <td><span>8</span></td>
                            <td><span>RIGHT_TYPE_DISPLAY</span></td>
                            <td><span>展览权</span></td>
                        </tr>
                        <tr>
                            <td><span>9</span></td>
                            <td><span>RIGHT_TYPE_SHOW</span></td>
                            <td><span>表演权</span></td>
                        </tr>
                        <tr>
                            <td><span>10</span></td>
                            <td><span>RIGHT_TYPE_PLAY</span></td>
                            <td><span>放映权</span></td>
                        </tr>
                        <tr>
                            <td><span>11</span></td>
                            <td><span>RIGHT_TYPE_BROADCAST</span></td>
                            <td><span>广播权</span></td>
                        </tr>
                        <tr>
                            <td><span>12</span></td>
                            <td><span>RIGHT_TYPE_NET_TRANS</span></td>
                            <td><span>信息网络传播权</span></td>
                        </tr>
                        <tr>
                            <td><span>13</span></td>
                            <td><span>RIGHT_TYPE_FILMING</span></td>
                            <td><span>摄制权</span></td>
                        </tr>
                        <tr>
                            <td><span>14</span></td>
                            <td><span>RIGHT_TYPE_ADAPT</span></td>
                            <td><span>改编权</span></td>
                        </tr>
                        <tr>
                            <td><span>15</span></td>
                            <td><span>RIGHT_TYPE_TRANSLATE</span></td>
                            <td><span>翻译权</span></td>
                        </tr>
                        <tr>
                            <td><span>16</span></td>
                            <td><span>RIGHT_TYPE_COLLECTION</span></td>
                            <td><span>汇编权</span></td>
                        </tr>
                        <tr>
                            <td><span>17</span></td>
                            <td><span>RIGHT_TYPE_OTHER</span></td>
                            <td><span>其他权利</span></td>
                        </tr>
                        <tr>
                            <td><span>18</span></td>
                            <td><span>RIGHT_TYPE_ALL</span></td>
                            <td><span>所有</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
            <blockquote>
                <p><span>注意：当前只支持编号 18 的</span><strong><span>所有</span></strong><span>项，其他权利会在后续支持</span></p>
            </blockquote>
            <p><span>RighterTypeEnum（权利人类型）：</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>编码</span></th>
                            <th><span>常量</span></th>
                            <th><span>备注</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>1</span></td>
                            <td><span>RIGHTER_TYPE_NATURAL</span></td>
                            <td><span>自然人</span></td>
                        </tr>
                        <tr>
                            <td><span>2</span></td>
                            <td><span>RIGHTER_TYPE_LEGAL</span></td>
                            <td><span>法人</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
            <p><span>AuthorTypeEnum（作者类型）：</span></p>
            <figure>
                <table>
                    <thead>
                        <tr>
                            <th><span>编码</span></th>
                            <th><span>常量</span></th>
                            <th><span>备注</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span>1</span></td>
                            <td><span>AUTHOR_TYPE_NATURAL</span></td>
                            <td><span>自然人</span></td>
                        </tr>
                        <tr>
                            <td><span>2</span></td>
                            <td><span>AUTHOR_TYPE_LEGAL</span></td>
                            <td><span>法人</span></td>
                        </tr>
                    </tbody>
                </table>
            </figure>
        </li>
    </ul>
    <p>&nbsp;</p>
    <blockquote>
        <p><span>DciClaim 的结构比较复杂，除了填充作品信息之外，还需要填充</span><strong><span>权利项</span></strong><span>（DciRight）和</span><strong><span>作者</span></strong><span>信息（DciAuthor），权利项中又包含</span><strong><span>权利人</span></strong><span>（DciRighter） 信息，具体用法看示例代码。</span></p>
        <p><span>另外需要注意：</span></p>
        <ol start=''>
            <li><span>目前 DciRight 的 type 只能使用 RIGHT_TYPE_ALL 值</span></li>
            <li><span>sk 是 dciClaim 中 proposerEmail 所属账户的私钥</span></li>
        </ol>
    </blockquote>
    <p>&nbsp;</p>
    <h3 id='返回数据dciclaimresp）'><span>返回数据（DciClaimResp）</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>taskId</span></td>
                    <td><span>String</span></td>
                    <td><span>存证任务Id</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='示例-17'><span>示例</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java" style="page-break-inside: unset;"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation" style="">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 创建权利人</span></span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">DciRighter</span> <span class="cm-variable">dciRighter</span> <span class="cm-operator">=</span> <span class="cm-variable">DciRighter</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">  .<span class="cm-variable">builder</span>()</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">  .<span class="cm-variable">righterName</span>(<span class="cm-string">""</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">  .<span class="cm-variable">righterIdCard</span>(<span class="cm-string">""</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">  .<span class="cm-variable">righterEmail</span>(<span class="cm-string">""</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">  .<span class="cm-variable">righterGainedWay</span>(<span class="cm-variable">GainedWayEnum</span>.<span class="cm-variable">GAINED_WAY_ORIGINAL</span>.<span class="cm-variable">getDesc</span>())</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">  .<span class="cm-variable">righterSk</span>(<span class="cm-variable">sk</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">  .<span class="cm-variable">righterType</span>(<span class="cm-variable">RighterTypeEnum</span>.<span class="cm-variable">RIGHTER_TYPE_LEGAL</span>.<span class="cm-variable">getDesc</span>())</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">  .<span class="cm-variable">build</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">DciRight</span> <span class="cm-variable">dciRight</span> <span class="cm-operator">=</span> <span class="cm-variable">DciRight</span>.<span class="cm-variable">builder</span>()</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">  .<span class="cm-variable">type</span>(<span class="cm-variable">RightTypeEnum</span>.<span class="cm-variable">RIGHT_TYPE_ALL</span>.<span class="cm-variable">getDesc</span>()) <span class="cm-comment">// 目前只能选择这个类型</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">  .<span class="cm-variable">righterInfoList</span>(<span class="cm-variable">Arrays</span>.<span class="cm-variable">asList</span>(<span class="cm-variable">dciRighter</span>))</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">  .<span class="cm-variable">build</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">DciAuthor</span> <span class="cm-variable">author</span> <span class="cm-operator">=</span> <span class="cm-variable">DciAuthor</span>.<span class="cm-variable">builder</span>()</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">  .<span class="cm-variable">authorName</span>(<span class="cm-string">""</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">  .<span class="cm-variable">authorIdCard</span>(<span class="cm-string">""</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">  .<span class="cm-variable">authorType</span>(<span class="cm-variable">AuthorTypeEnum</span>.<span class="cm-variable">AUTHOR_TYPE_LEGAL</span>.<span class="cm-variable">getDesc</span>())</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">  .<span class="cm-variable">build</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">DciClaim</span> <span class="cm-variable">dciClaim</span> <span class="cm-operator">=</span> <span class="cm-variable">DciClaim</span>.<span class="cm-variable">builder</span>()</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">  .<span class="cm-variable">dciName</span>(<span class="cm-string">"图片作品"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">  .<span class="cm-variable">dciUrl</span>(<span class="cm-string">"https://news.sina.com.cn/c/2021-02-14/doc-ikftssap5744759.shtml"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">  .<span class="cm-variable">proposerEmail</span>(<span class="cm-string">""</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">  .<span class="cm-variable">proposerSk</span>(<span class="cm-variable">sk</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">  .<span class="cm-variable">dciType</span>(<span class="cm-variable">DciTypeEnum</span>.<span class="cm-variable">DCI_TYPE_SOFTWARE</span>.<span class="cm-variable">getDesc</span>())</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">  .<span class="cm-variable">dciCreateProperty</span>(<span class="cm-variable">DciCreatePropertyEnum</span>.<span class="cm-variable">DCI_CREATE_PROPERTY_OTHER</span>.<span class="cm-variable">getDesc</span>())</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">  .<span class="cm-variable">authorList</span>(<span class="cm-variable">Arrays</span>.<span class="cm-variable">asList</span>(<span class="cm-variable">author</span>))</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">  .<span class="cm-variable">rightInfoList</span>(<span class="cm-variable">Arrays</span>.<span class="cm-variable">asList</span>(<span class="cm-variable">dciRight</span>))</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">  .<span class="cm-variable">build</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">DciClaimResp</span> <span class="cm-variable">dciClaimResp</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">submitDciClaim</span>(<span class="cm-variable">dciClaim</span>);</span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 814px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 814px;"></div>
    </div>
    </div>
    </pre>
    <p>&nbsp;</p>
    <h2 id='用户查询存证结果'><span>用户查询存证结果</span></h2>
    <h3 id='方法原型-15'><span>方法原型</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">DciClaimQueryResp</span> <span class="cm-def">queryDciClaimResult</span>(<span class="cm-variable">DciClaimQuery</span> <span class="cm-variable">dciClaimQuery</span>)</span></pre>
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
    </div>
    </div>
    </pre>
    <h3 id='参数说明dciclaimquery）'><span>参数说明（DciClaimQuery）</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>taskId</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>存证任务ID</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <p>&nbsp;</p>
    <h3 id='返回数据dciclaimqueryresp）'><span>返回数据（DciClaimQueryResp）</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>status</span></td>
                    <td><span>Integer</span></td>
                    <td><span>存证任务状态值（2：处理中，4：搜索出来的图片url，疑似侵权中止流程 7：成功, 10：失败）</span></td>
                </tr>
                <tr>
                    <td><span>dciId</span></td>
                    <td><span>String</span></td>
                    <td><span>存证 id</span></td>
                </tr>
                <tr>
                    <td><span>url</span></td>
                    <td><span>String</span></td>
                    <td><span>证书地址</span></td>
                </tr>
                <tr>
                    <td><span>tortSearchList</span></td>
                    <td><span>List</span>
                        &lt;DciTortSearch&gt;
                    </td>
                    <td><span>如果作品发生侵权，就会返回已经被存证的作品地址</span></td>
                </tr>
                <tr>
                    <td><span>recordTimestamp</span></td>
                    <td><span>Long</span></td>
                    <td><span>存证时间戳</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <p><span>DciTortSearch 说明： </span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>url</span></td>
                    <td><span>String</span></td>
                    <td><span>被存证作品的 url</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='示例-18'><span>示例</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation" style="">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">DciClaimQuery</span> <span class="cm-variable">dciClaimQuery</span> <span class="cm-operator">=</span> <span class="cm-variable">DciClaimQuery</span>.<span class="cm-variable">builder</span>()</span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">  .<span class="cm-variable">taskId</span>(<span class="cm-string">"a1d3549d-15d1-43d3-8d1c-d652037a7551_5"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">  .<span class="cm-variable">build</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">DciClaimQueryResp</span> <span class="cm-variable">resp</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">queryDciClaimResult</span>(<span class="cm-variable">dciClaimQuery</span>);</span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 110px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 110px;"></div>
    </div>
    </div>
    </pre>
    <h1 id='Kv 服务'><span>Kv 服务</span></h1>
    <h2 id='Kv 存储'><span>Kv 存储</span></h2>
    <h3 id='方法原型-16'><span>方法原型</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">KvSaveResp</span> <span class="cm-def">kvSave</span>(<span class="cm-variable">KvSaveReq</span> <span class="cm-variable">req</span>)</span></pre>
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
    </div>
    </div>
    </pre>
    <h3 id='参数说明-17'><span>参数说明</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>req</span></td>
                    <td><span>KvSaveReq</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>kv 存储请求</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <p><span>KvSaveReq 结构如下：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>kvKey</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>kv 数据的 key</span></td>
                </tr>
                <tr>
                    <td><span>kvValue</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>kv 数据的 value</span></td>
                </tr>
                <tr>
                    <td><span>sk</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>存储人的私钥（如果是代理用户，就是代理用户的私钥）</span></td>
                </tr>
                <tr>
                    <td><span>representAppId</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>否</span></td>
                    <td><span>代理用户 appId</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <blockquote>
        <p><span>注：kvKey 要使用 appId: 开始，如果是代理用户，就使用 representAppId: 开头，</span>
            <span>例如 201107000400001:xxxxx</span></p>
    </blockquote>
    <h3 id='返回数据-15'><span>返回数据</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>resp</span></td>
                    <td><span>KvSaveResp</span></td>
                    <td><span>Kv 存储结果</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <p><span>KvSaveResp 结构如下：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>kvKey</span></td>
                    <td><span>String</span></td>
                    <td><span>kv 的 key</span></td>
                </tr>
                <tr>
                    <td><span>requestId</span></td>
                    <td><span>String</span></td>
                    <td><span>请求的 requestId</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='示例-19'><span>示例</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation" style="">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">KvSaveReq</span> <span class="cm-variable">req</span> <span class="cm-operator">=</span> <span class="cm-keyword">new</span> <span class="cm-variable">KvSaveReq</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp;<span class="cm-variable-3">String</span> <span class="cm-variable">key</span> <span class="cm-operator">=</span> <span class="cm-variable">appId</span> <span class="cm-operator">+</span><span class="cm-string">":"</span> <span class="cm-operator">+</span> <span class="cm-string">""</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp;<span class="cm-variable">req</span>.<span class="cm-variable">setKvKey</span>(<span class="cm-variable">key</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp;<span class="cm-variable">req</span>.<span class="cm-variable">setKvValue</span>(<span class="cm-string">""</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp;<span class="cm-variable">req</span>.<span class="cm-variable">setSk</span>(<span class="cm-variable">sk</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">KvSaveResp</span> <span class="cm-variable">resp</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">kvSave</span>(<span class="cm-variable">req</span>);</span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 154px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 154px;"></div>
    </div>
    </div>
    </pre>
    <h2 id='查询 Kv'><span>查询 Kv</span></h2>
    <h3 id='方法原型-17'><span>方法原型</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">KvQueryResp</span> <span class="cm-def">kvQuery</span>(<span class="cm-variable">KvQueryReq</span> <span class="cm-variable">req</span>)</span></pre>
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
    </div>
    </div>
    </pre>
    <h3 id='参数说明-18'><span>参数说明</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>req</span></td>
                    <td><span>KvQueryReq</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>Kv 查询请求</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <p><span>KvQueryReq 结构如下：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>kvKey</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>kv 数据的 key</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='返回数据-16'><span>返回数据</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>resp</span></td>
                    <td><span>KvQueryResp</span></td>
                    <td><span>kv 数据查询结果</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <p><span>KvQueryResp 结构如下：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>kvKey</span></td>
                    <td><span>String</span></td>
                    <td><span>kv 数据的 key</span></td>
                </tr>
                <tr>
                    <td><span>kvValue</span></td>
                    <td><span>String</span></td>
                    <td><span>kv 数据的 value</span></td>
                </tr>
                <tr>
                    <td><span>createTime</span></td>
                    <td><span>String</span></td>
                    <td><span>kv 数据上链时间</span></td>
                </tr>
                <tr>
                    <td><span>status</span></td>
                    <td><span>Integer</span></td>
                    <td><span>kv 数据状态（1：未上链，3：上链成功，4：上链失败）</span></td>
                </tr>
                <tr>
                    <td><span>requestId</span></td>
                    <td><span>String</span></td>
                    <td><span>请求 requestId</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='示例-20'><span>示例</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation" style="">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">KvQueryReq</span> <span class="cm-variable">req</span> <span class="cm-operator">=</span> <span class="cm-keyword">new</span> <span class="cm-variable">KvQueryReq</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">req</span>.<span class="cm-variable">setKvKey</span>(<span class="cm-string">""</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">KvQueryResp</span> <span class="cm-variable">resp</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">kvQuery</span>(<span class="cm-variable">req</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span cm-text="" cm-zwsp="">
    </span></span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 132px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 132px;"></div>
    </div>
    </div>
    </pre>
    <p>&nbsp;</p>
    <h1 id='代理商服务'><span>代理商服务</span></h1>
    <h2 id='法人代理用户注册'><span>法人代理用户注册</span></h2>
    <p><span>在代理用户注册前，需要接入方在web端(至信链代理商注册页面)自行注册成为代理商，待审核通过后方可进行法人代理</span>
        <span>用户注册，在代理用户注册时需要传入代理商</span><code>AppId</code><span>等代理商的属性</span></p>
    <h3 id='方法原型-18'><span>方法原型</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">boolean</span> <span class="cm-def">registerUser</span>(<span class="cm-variable">AgentUser</span> <span class="cm-variable">agentUser</span>)</span></pre>
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
    </div>
    </div>
    </pre>
    <h3 id='参数说明-19'><span>参数说明</span></h3>
    <p><span>接口参数传入</span><code>AgentUser</code><span>类型对象：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>representEmail</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>代理企业注册邮箱</span></td>
                </tr>
                <tr>
                    <td><span>pwd</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>代理企业注册密码</span></td>
                </tr>
                <tr>
                    <td><span>epName</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>代理企业名称</span></td>
                </tr>
                <tr>
                    <td><span>creditCode</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>代理企业信用代码</span></td>
                </tr>
                <tr>
                    <td><span>licenseFile</span></td>
                    <td><span>File</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>代理企业营业执照图片</span></td>
                </tr>
                <tr>
                    <td><span>representative</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>代理企业法人代表姓名</span></td>
                </tr>
                <tr>
                    <td><span>letterFile</span></td>
                    <td><span>File</span></td>
                    <td><span>无</span></td>
                    <td><span>否</span></td>
                    <td><span>至信链委托公函图片</span></td>
                </tr>
                <tr>
                    <td><span>category</span></td>
                    <td><span>CategoryID</span></td>
                    <td><span>无</span></td>
                    <td><span>否</span></td>
                    <td><span>代理企业行业信息，枚举</span></td>
                </tr>
                <tr>
                    <td><span>contact</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>联系人姓名</span></td>
                </tr>
                <tr>
                    <td><span>title</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>否</span></td>
                    <td><span>联系人职位</span></td>
                </tr>
                <tr>
                    <td><span>mobile</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>联系人手机号</span></td>
                </tr>
                <tr>
                    <td><span>idcard</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>联系人身份证号码</span></td>
                </tr>
                <tr>
                    <td><span>cardFrontFile</span></td>
                    <td><span>File</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>联系人身份证正面图片</span></td>
                </tr>
                <tr>
                    <td><span>cardBackendFile</span></td>
                    <td><span>File</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>联系人身份证反面图片</span></td>
                </tr>
                <tr>
                    <td><span>userType</span></td>
                    <td><span>Integer</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>用户类型，填入：1（法人)</span></td>
                </tr>
                <tr>
                    <td><span>platformName</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>否</span></td>
                    <td><span>接入平台类型</span></td>
                </tr>
                <tr>
                    <td><span>platformUrl</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>否</span></td>
                    <td><span>接入平台地址</span></td>
                </tr>
                <tr>
                    <td><span>businessType</span></td>
                    <td><span>Integer</span></td>
                    <td><span>4</span></td>
                    <td><span>否</span></td>
                    <td><span>平台业务类型（1:金融类 2:版权类 3:其他类 4:未填写），默认是未填写</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='返回数据-17'><span>返回数据</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>boolean</span></td>
                    <td><span>boolean</span></td>
                    <td><span>注册成功与否，成功true，失败抛出对应异常信息</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='示例-21'><span>示例</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java" style="page-break-inside: unset;"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation" style="">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-comment">// 企业APPID &nbsp; </span></span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable-3">String</span> <span class="cm-variable">appId</span> <span class="cm-operator">=</span> <span class="cm-string">""</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-comment">// 企业APPKEY</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable-3">String</span> <span class="cm-variable">appKey</span> <span class="cm-operator">=</span> <span class="cm-string">""</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">ZxlConnectionConfig</span> <span class="cm-variable">config</span> <span class="cm-operator">=</span> <span class="cm-variable">ZxlConnectionConfig</span>.<span class="cm-variable">builder</span>()</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp;  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">host</span>(<span class="cm-string">"https://sdk.zxinchain.com"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">  <span class="cm-tab" role="presentation" cm-text="	">  </span>  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">build</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-comment">// 实例化sdk对象</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">ZXLSDK</span> <span class="cm-variable">sdk</span> <span class="cm-operator">=</span> <span class="cm-keyword">new</span> <span class="cm-variable">ZXLSDK</span>(<span class="cm-variable">appId</span>,<span class="cm-variable">appKey</span>,<span class="cm-variable">config</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-comment">// 企业用户注册</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; <span class="cm-variable">AgentUser</span> <span class="cm-variable">user</span> <span class="cm-operator">=</span> <span class="cm-variable">AgentUser</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">builder</span>()</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">epName</span>(<span class="cm-string">"XX集团有限公司"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">pwd</span>(<span class="cm-string">"123456aaa"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">representEmail</span>(<span class="cm-string">"zxin2@admin.com"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">representative</span>(<span class="cm-string">"XXX"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">creditCode</span>(<span class="cm-string">"914400002311176XXX"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">contact</span>(<span class="cm-string">"XXX"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">idcard</span>(<span class="cm-string">"342401198910028XXX"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">mobile</span>(<span class="cm-string">"18600213XXX"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">platformName</span>(<span class="cm-string">"小说平台"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">platformUrl</span>(<span class="cm-string">"https://www.xiaoshuo.com"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">businessType</span>(<span class="cm-variable">BusinessTypeEnum</span>.<span class="cm-variable">BUSINESS_COPYRIGHT</span>.<span class="cm-variable">getType</span>())</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">userType</span>(<span class="cm-variable">UserTypeEnum</span>.<span class="cm-variable">USER_LEGAL_PERSON</span>.<span class="cm-variable">getType</span>())</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">cardFrontFile</span>(<span class="cm-keyword">new</span> <span class="cm-variable">File</span>(<span class="cm-string">"/root/workspace/zx-demo/idcard_front.jpeg"</span>))</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">cardBackendFile</span>(<span class="cm-keyword">new</span> <span class="cm-variable">File</span>(<span class="cm-string">"/root/ray/workspace/zx-demo/idcard_front.jpeg"</span>))</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">licenseFile</span>(<span class="cm-keyword">new</span> <span class="cm-variable">File</span>(<span class="cm-string">"/root/ray/workspace/zx-demo/license.jpeg"</span>))</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">build</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-comment">// 构建数据完毕后进行注册,根据注解接口的返回进行判断是否注册成功（注册成功后进入后台管理员审核状态）</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable-3">boolean</span> <span class="cm-variable">registFlag</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">registerUser</span>(<span class="cm-variable">user</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;</span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 836px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 836px;"></div>
    </div>
    </div>
    </pre>
    <p>&nbsp;</p>
    <h2 id='自然人代理用户注册'><span>自然人代理用户注册</span></h2>
    <p><span>在代理用户注册前，需要接入方在web端(至信链代理商注册页面)自行注册成为代理商，待审核通过后方可进行自然人代理</span>
        <span>用户注册，在代理用户注册时需要传入代理商</span><code>AppId</code><span>等代理商的属性</span></p>
    <h3 id='方法原型-19'><span>方法原型</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">boolean</span> <span class="cm-def">registerUser</span>(<span class="cm-variable">AgentUser</span> <span class="cm-variable">agentUser</span>)</span></pre>
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
    </div>
    </div>
    </pre>
    <h3 id='参数说明-20'><span>参数说明</span></h3>
    <p><span>接口参数传入</span><code>AgentUser</code><span>类型对象：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>representEmail</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>代理用户注册邮箱</span></td>
                </tr>
                <tr>
                    <td><span>pwd</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>代理用户注册密码</span></td>
                </tr>
                <tr>
                    <td><span>personName</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>代理用户姓名</span></td>
                </tr>
                <tr>
                    <td><span>mobile</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>联系人手机号</span></td>
                </tr>
                <tr>
                    <td><span>idcard</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>联系人身份证号码</span></td>
                </tr>
                <tr>
                    <td><span>cardFrontFile</span></td>
                    <td><span>File</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>联系人身份证正面图片</span></td>
                </tr>
                <tr>
                    <td><span>cardBackendFile</span></td>
                    <td><span>File</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>联系人身份证反面图片</span></td>
                </tr>
                <tr>
                    <td><span>userType</span></td>
                    <td><span>Integer</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>用户类型，填入：2（ 自然人）</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='返回数据-18'><span>返回数据</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>boolean</span></td>
                    <td><span>boolean</span></td>
                    <td><span>注册成功与否，成功true，失败抛出对应异常信息</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='示例-22'><span>示例</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java" style="page-break-inside: unset;"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation" style="">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-comment">// 企业APPID &nbsp; </span></span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable-3">String</span> <span class="cm-variable">appId</span> <span class="cm-operator">=</span> <span class="cm-string">""</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-comment">// 企业APPKEY</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable-3">String</span> <span class="cm-variable">appKey</span> <span class="cm-operator">=</span> <span class="cm-string">""</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">ZxlConnectionConfig</span> <span class="cm-variable">config</span> <span class="cm-operator">=</span> <span class="cm-variable">ZxlConnectionConfig</span>.<span class="cm-variable">builder</span>()</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp;  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">host</span>(<span class="cm-string">"https://sdk.zxinchain.com"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">  <span class="cm-tab" role="presentation" cm-text="	">  </span>  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">build</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-comment">// 实例化sdk对象</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">ZXLSDK</span> <span class="cm-variable">sdk</span> <span class="cm-operator">=</span> <span class="cm-keyword">new</span> <span class="cm-variable">ZXLSDK</span>(<span class="cm-variable">appId</span>,<span class="cm-variable">appKey</span>,<span class="cm-variable">config</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-comment">// 自然人注册</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">AgentUser</span> <span class="cm-variable">user</span> <span class="cm-operator">=</span> <span class="cm-variable">AgentUser</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">builder</span>()</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">personName</span>(<span class="cm-string">"XXX"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">pwd</span>(<span class="cm-string">"123456aaa"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">idcard</span>(<span class="cm-string">"5002221989101281XXX"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">mobile</span>(<span class="cm-string">"18600213XXX"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">representEmail</span>(<span class="cm-string">"zxin_person2@admin.com"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">userType</span>(<span class="cm-variable">UserTypeEnum</span>.<span class="cm-variable">USER_NATURAL_PERSON</span>.<span class="cm-variable">getType</span>())</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">cardFrontFile</span>(<span class="cm-keyword">new</span> <span class="cm-variable">File</span>(<span class="cm-string">"idcard_front.jpeg"</span>))</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">cardBackendFile</span>(<span class="cm-keyword">new</span> <span class="cm-variable">File</span>(<span class="cm-string">"idcard_front.jpeg"</span>))</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  .<span class="cm-variable">build</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">result</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">registerUser</span>(<span class="cm-variable">user</span>);</span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 528px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 528px;"></div>
    </div>
    </div>
    </pre>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <h2 id='查询代理用户审核结果'><span>查询代理用户审核结果</span></h2>
    <h3 id='方法原型-20'><span>方法原型</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ReviewData</span> <span class="cm-def">selectEpInfo</span>(<span class="cm-variable-3">String</span> <span class="cm-variable">email</span>)</span></pre>
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
    </div>
    </div>
    </pre>
    <h3 id='参数说明-21'><span>参数说明</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>email</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>代理用户注册账户的邮箱账号</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='返回数据-19'><span>返回数据</span></h3>
    <p><span>返回</span><code>ReviewData</code><span>类型对象：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>appId</span></td>
                    <td><span>String</span></td>
                    <td><span>应用Id,当前字段的意义取决于state字段</span></td>
                </tr>
                <tr>
                    <td><span>appKey</span></td>
                    <td><span>String</span></td>
                    <td><span>应用key</span></td>
                </tr>
                <tr>
                    <td><span>state</span></td>
                    <td><span>int</span></td>
                    <td><span>认证是否通过：[1：待审核</span><span>|</span><span>2：认证通过</span><span>|</span><span>3：认证不通过]，状态值为2时appId字段才有意义</span></td>
                </tr>
                <tr>
                    <td><span>reason</span></td>
                    <td><span>String</span></td>
                    <td><span>认证不通过的原因，若通过则为空串</span></td>
                </tr>
                <tr>
                    <td><span>agentCode</span></td>
                    <td><span>String</span></td>
                    <td><span>代理商代码，仅在用户是代理商时才有意义</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='示例-23'><span>示例</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation" style="">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPID &nbsp; </span></span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appId</span> <span class="cm-operator">=</span> <span class="cm-string">"200515000110001"</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPKEY</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appKey</span> <span class="cm-operator">=</span> <span class="cm-string">"0e4bce1b0ef8471fb9140b849e776f48"</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZxlConnectionConfig</span> <span class="cm-variable">config</span> <span class="cm-operator">=</span> <span class="cm-variable">ZxlConnectionConfig</span>.<span class="cm-variable">builder</span>()</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">host</span>(<span class="cm-string">"https://sdk.zxinchain.com"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">build</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 实例化sdk对象</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZXLSDK</span> <span class="cm-variable">sdk</span> <span class="cm-operator">=</span> <span class="cm-keyword">new</span> <span class="cm-variable">ZXLSDK</span>(<span class="cm-variable">appId</span>,<span class="cm-variable">appKey</span>,<span class="cm-variable">config</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">//根据代理用户的注册时的邮箱进行查询</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">//根据rData.getState()的状态来进行判断，若为2则认证通过（会有对应的代理用户的appId），若为3则不通过</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ReviewData</span> <span class="cm-variable">rData</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">selectEpInfo</span>(<span class="cm-string">"xxxx.@qq.com"</span>);</span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 286px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 286px;"></div>
    </div>
    </div>
    </pre>
    <p>&nbsp;</p>
    <h2 id='上传代理用户的证书'><span>上传代理用户的证书</span></h2>
    <h3 id='方法原型-21'><span>方法原型</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">boolean</span> <span class="cm-def">bindRepresentUserCert</span>(<span class="cm-variable-3">String</span> <span class="cm-variable">representAppId</span>,<span class="cm-variable-3">String</span> <span class="cm-variable">representAppKey</span>,<span class="cm-variable-3">String</span> <span class="cm-variable">representPk</span>, <span class="cm-variable-3">String</span> <span class="cm-variable">representSk</span>)</span></pre>
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 44px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 44px;"></div>
    </div>
    </div>
    </pre>
    <h3 id='参数说明-22'><span>参数说明</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>representAppId</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>代理用户appId</span></td>
                </tr>
                <tr>
                    <td><span>representAppKey</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>代理用户appKey</span></td>
                </tr>
                <tr>
                    <td><span>representPk</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>代理用户生成的公钥</span></td>
                </tr>
                <tr>
                    <td><span>representSk</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>代理用户生成的私钥</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='返回数据-20'><span>返回数据</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>boolean</span></td>
                    <td><span>boolean</span></td>
                    <td><span>上传结果，成功true，失败false</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='示例-24'><span>示例</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java" style="page-break-inside: unset;"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation" style="">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPID &nbsp; </span></span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appId</span> <span class="cm-operator">=</span> <span class="cm-string">"200515000110001"</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPKEY</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appKey</span> <span class="cm-operator">=</span> <span class="cm-string">"0e4bce1b0ef8471fb9140b849e776f48"</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZxlConnectionConfig</span> <span class="cm-variable">config</span> <span class="cm-operator">=</span> <span class="cm-variable">ZxlConnectionConfig</span>.<span class="cm-variable">builder</span>()</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">host</span>(<span class="cm-string">"https://sdk.zxinchain.com"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">build</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 实例化sdk对象</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZXLSDK</span> <span class="cm-variable">sdk</span> <span class="cm-operator">=</span> <span class="cm-keyword">new</span> <span class="cm-variable">ZXLSDK</span>(<span class="cm-variable">appId</span>,<span class="cm-variable">appKey</span>,<span class="cm-variable">config</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">//生成公私钥</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">KeyPair</span> <span class="cm-variable">keyPair</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">generateKeyPair</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 公钥</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">representPk</span> <span class="cm-operator">=</span> <span class="cm-variable">keyPair</span>.<span class="cm-variable">getPublicKey</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 私钥</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">representSk</span> <span class="cm-operator">=</span> <span class="cm-variable">keyPair</span>.<span class="cm-variable">getPrivateKey</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 代理用户appId</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">representAppId</span> <span class="cm-operator">=</span> <span class="cm-string">""</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 代理用户appKey</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">representAppKey</span> <span class="cm-operator">=</span> <span class="cm-string">""</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 上传代理用户的证书(绑定公私钥)</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">boolean</span> <span class="cm-variable">uploadFlag</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">bindRepresentUserCert</span>(<span class="cm-variable">representAppId</span>,<span class="cm-variable">representAppKey</span>,<span class="cm-variable">representPk</span>,<span class="cm-variable">representSk</span>));</span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 506px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 506px;"></div>
    </div>
    </div>
    </pre>
    <p>&nbsp;</p>
    <h2 id='更新代理用户证书'><span>更新代理用户证书</span></h2>
    <h3 id='方法原型-22'><span>方法原型</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">boolean</span> <span class="cm-def">updateRepresentUserCert</span>(<span class="cm-variable-3">String</span> <span class="cm-variable">representAppId</span>,<span class="cm-variable-3">String</span> <span class="cm-variable">representAppKey</span>, <span class="cm-variable-3">String</span> <span class="cm-variable">representPk</span>, <span class="cm-variable-3">String</span> <span class="cm-variable">representSk</span>)</span></pre>
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 44px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 44px;"></div>
    </div>
    </div>
    </pre>
    <h3 id='参数说明-23'><span>参数说明</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>representAppId</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>代理用户appId</span></td>
                </tr>
                <tr>
                    <td><span>representAppKey</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>代理用户appKey</span></td>
                </tr>
                <tr>
                    <td><span>representPk</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>代理用户生成的公钥</span></td>
                </tr>
                <tr>
                    <td><span>representSk</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>代理用户生成的私钥</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='返回数据-21'><span>返回数据</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>boolean</span></td>
                    <td><span>boolean</span></td>
                    <td><span>更新结果，成功true，失败false</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='示例-25'><span>示例</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java" style="page-break-inside: unset;"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation" style="">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPID &nbsp; </span></span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appId</span> <span class="cm-operator">=</span> <span class="cm-string">"200515000110001"</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPKEY</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appKey</span> <span class="cm-operator">=</span> <span class="cm-string">"0e4bce1b0ef8471fb9140b849e776f48"</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZxlConnectionConfig</span> <span class="cm-variable">config</span> <span class="cm-operator">=</span> <span class="cm-variable">ZxlConnectionConfig</span>.<span class="cm-variable">builder</span>()</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">host</span>(<span class="cm-string">"https://sdk.zxinchain.com"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">build</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 实例化sdk对象</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZXLSDK</span> <span class="cm-variable">sdk</span> <span class="cm-operator">=</span> <span class="cm-keyword">new</span> <span class="cm-variable">ZXLSDK</span>(<span class="cm-variable">appId</span>,<span class="cm-variable">appKey</span>,<span class="cm-variable">config</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">//生成公私钥</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">KeyPair</span> <span class="cm-variable">keyPair</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">generateKeyPair</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 公钥</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">representPk</span> <span class="cm-operator">=</span> <span class="cm-variable">keyPair</span>.<span class="cm-variable">getPublicKey</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 私钥</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">representSk</span> <span class="cm-operator">=</span> <span class="cm-variable">keyPair</span>.<span class="cm-variable">getPrivateKey</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 代理用户appId</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">representAppId</span> <span class="cm-operator">=</span> <span class="cm-string">""</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 代理用户appKey</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">representAppKey</span> <span class="cm-operator">=</span> <span class="cm-string">""</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 更新代理用户的证书(绑定公私钥)</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">boolean</span> <span class="cm-variable">uploadFlag</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">updateRepresentUserCert</span>(<span class="cm-variable">representAppId</span>,<span class="cm-variable">representAppKey</span>,<span class="cm-variable">representPk</span>,<span class="cm-variable">representSk</span>));</span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 506px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 506px;"></div>
    </div>
    </div>
    </pre>
    <p>&nbsp;</p>
    <h2 id='代理商模式用户存证'><span>代理商模式用户存证</span></h2>
    <h3 id='方法原型-23'><span>方法原型</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">SaveEvData</span> <span class="cm-def">representSave</span>(<span class="cm-variable-3">String</span> <span class="cm-variable">evHash</span>, <span class="cm-variable-3">String</span> <span class="cm-variable">extendInfo</span>, <span class="cm-variable-3">String</span> <span class="cm-variable">representSk</span>, <span class="cm-variable-3">String</span> <span class="cm-variable">representPk</span>,<span class="cm-variable-3">String</span> <span class="cm-variable">representAppId</span>)</span></pre>
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 44px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 44px;"></div>
    </div>
    </div>
    </pre>
    <h3 id='参数说明-24'><span>参数说明</span></h3>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>evHash</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>存证hash</span></td>
                </tr>
                <tr>
                    <td><span>extendInfo</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>拓展信息</span></td>
                </tr>
                <tr>
                    <td><span>representSk</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>代理用户私钥（用于对上链数据签名）</span></td>
                </tr>
                <tr>
                    <td><span>representPk</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>代理用户公钥</span></td>
                </tr>
                <tr>
                    <td><span>representAppId</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>代理用户的应用id</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='返回数据-22'><span>返回数据</span></h3>
    <p><span>返回</span><code>SaveEvData</code><span>类型对象，</span><code>SaveEvData</code><span>对象说明：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>evId</span></td>
                    <td><span>String</span></td>
                    <td><span>存证Id</span></td>
                </tr>
                <tr>
                    <td><span>txHash</span></td>
                    <td><span>String</span></td>
                    <td><span>交易hash</span></td>
                </tr>
                <tr>
                    <td><span>blockHeight</span></td>
                    <td><span>String</span></td>
                    <td><span>区块高度</span></td>
                </tr>
                <tr>
                    <td><span>createTime</span></td>
                    <td><span>String</span></td>
                    <td><span>创建时间</span></td>
                </tr>
                <tr>
                    <td><span>ext</span></td>
                    <td><span>String</span></td>
                    <td><span>扩展信息</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h3 id='示例-26'><span>示例</span></h3>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java" style="page-break-inside: unset;"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation" style="">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPID &nbsp; </span></span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appId</span> <span class="cm-operator">=</span> <span class="cm-string">"200515000110001"</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPKEY</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appKey</span> <span class="cm-operator">=</span> <span class="cm-string">"0e4bce1b0ef8471fb9140b849e776f48"</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZxlConnectionConfig</span> <span class="cm-variable">config</span> <span class="cm-operator">=</span> <span class="cm-variable">ZxlConnectionConfig</span>.<span class="cm-variable">builder</span>()</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">host</span>(<span class="cm-string">"https://sdk.zxinchain.com"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">build</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 实例化sdk对象</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZXLSDK</span> <span class="cm-variable">sdk</span> <span class="cm-operator">=</span> <span class="cm-keyword">new</span> <span class="cm-variable">ZXLSDK</span>(<span class="cm-variable">appId</span>,<span class="cm-variable">appKey</span>,<span class="cm-variable">config</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">//上链前需先对存证文件进行hash计算</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">evidencePath</span> <span class="cm-operator">=</span> <span class="cm-string">"D:\work\sm2sm3chaincode.txt"</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">evHash</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">calculateFileHash</span>(<span class="cm-variable">evidencePath</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">extendInfo</span> <span class="cm-operator">=</span> <span class="cm-string">"用户的扩展信息"</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">//存证成功后会返回对应的信息(详细请见上图--SaveEvData定义)</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">SaveEvData</span> <span class="cm-variable">data</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">representSave</span>(<span class="cm-variable">evHash</span>,<span class="cm-variable">extendInfo</span>,<span class="cm-variable">representSk</span>,<span class="cm-variable">representPk</span>,<span class="cm-string">"认证通过后的appId"</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">evId</span> <span class="cm-operator">=</span> <span class="cm-variable">data</span>.<span class="cm-variable">getEvId</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">txHash</span> <span class="cm-operator">=</span> <span class="cm-variable">data</span>.<span class="cm-variable">getTxHash</span>();</span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 418px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 418px;"></div>
    </div>
    </div>
    </pre>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <h2 id='代理商模式截图取证服务'><span>代理商模式截图取证服务</span></h2>
    <h3 id='代理商模式图片取证'><span>代理商模式图片取证</span></h3>
    <h4 id='方法原型-24'><span>方法原型</span></h4>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> <span class="cm-variable-3">String</span> <span class="cm-def">evidenceObtainPic</span>(<span class="cm-variable-3">String</span> <span class="cm-variable">webUrls</span>, <span class="cm-variable-3">String</span> <span class="cm-variable">title</span>, <span class="cm-variable-3">String</span> <span class="cm-variable">remark</span>, <span class="cm-variable-3">String</span> <span class="cm-variable">representAppId</span>)</span></pre>
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 44px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 44px;"></div>
    </div>
    </div>
    </pre>
    <h4 id='参数说明-25'><span>参数说明</span></h4>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>webUrls</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>取证地址</span></td>
                </tr>
                <tr>
                    <td><span>title</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>证据标题</span></td>
                </tr>
                <tr>
                    <td><span>remark</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>否(可为null)</span></td>
                    <td><span>证据备注</span></td>
                </tr>
                <tr>
                    <td><span>representAppId</span></td>
                    <td><span>string</span></td>
                    <td><span>无</span></td>
                    <td><span>否</span></td>
                    <td><span>代理用户 AppId（当代理商本身发起的取证，该字段传 null）</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h4 id='返回数据-23'><span>返回数据</span></h4>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>string</span></td>
                    <td><span>String</span></td>
                    <td><span>订单号,根据订单号可查询取证结果</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h4 id='示例-27'><span>示例</span></h4>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java" style="page-break-inside: unset;"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation" style="">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> <span class="cm-comment">// 企业APPID &nbsp; </span></span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable-3">String</span> <span class="cm-variable">appId</span> <span class="cm-operator">=</span> <span class="cm-string">"200515000110001"</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-comment">// 代理用户 APPID</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable-3">String</span> <span class="cm-variable">representAppId</span> <span class="cm-operator">=</span> <span class="cm-string">""</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-comment">// 企业APPKEY</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable-3">String</span> <span class="cm-variable">appKey</span> <span class="cm-operator">=</span> <span class="cm-string">"0e4bce1b0ef8471fb9140b849e776f48"</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable-3">String</span> <span class="cm-variable">webUrls</span> <span class="cm-operator">=</span> <span class="cm-string">""</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable-3">String</span> <span class="cm-variable">title</span> <span class="cm-operator">=</span> <span class="cm-string">""</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable-3">String</span> <span class="cm-variable">reamarks</span> <span class="cm-operator">=</span> <span class="cm-string">""</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">ZxlConnectionConfig</span> <span class="cm-variable">config</span> <span class="cm-operator">=</span> <span class="cm-variable">ZxlConnectionConfig</span>.<span class="cm-variable">builder</span>()</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp; &nbsp;  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">host</span>(<span class="cm-string">"https://sdk.zxinchain.com"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;">  <span class="cm-tab" role="presentation" cm-text="	">  </span>  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">build</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-comment">// 实例化sdk对象</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable">ZXLSDK</span> <span class="cm-variable">sdk</span> <span class="cm-operator">=</span> <span class="cm-keyword">new</span> <span class="cm-variable">ZXLSDK</span>(<span class="cm-variable">appId</span>,<span class="cm-variable">appKey</span>,<span class="cm-variable">config</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-comment">// 图片取证接口</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;<span class="cm-variable-3">String</span> <span class="cm-variable">orderNo</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">evidenceObtainPic</span>(<span class="cm-variable">webUrls</span>, <span class="cm-variable">title</span>, <span class="cm-variable">remark</span>, <span class="cm-variable">representAppId</span>);</span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 374px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 374px;"></div>
    </div>
    </div>
    </pre>
    <h3 id='获取取证任务状态及结果-3'><span>获取取证任务状态及结果</span></h3>
    <p><span>用户通过 orderNo 查询前期调用取证接口的结果</span></p>
    <h4 id='方法原型-25'><span>方法原型</span></h4>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">EvIdData</span> <span class="cm-def">getEvidenceStatus</span>(<span class="cm-variable-3">String</span> <span class="cm-variable">orderNo</span>)</span></pre>
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
    </div>
    </div>
    </pre>
    <h4 id='参数说明-26'><span>参数说明</span></h4>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>orderNo</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>订单编号(由视频/图片取证产生)</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h4 id='返回数据-24'><span>返回数据</span></h4>
    <p><span>返回</span><code>EvIdData</code><span>对象，对象说明如下：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>status</span></td>
                    <td><span>int</span></td>
                    <td><span>当前任务状态[0:执行中&gt;&gt;2成功&gt;&gt;10失败]</span></td>
                </tr>
                <tr>
                    <td><span>evidUrl</span></td>
                    <td><span>String</span></td>
                    <td><span>成功状态下，取证证据下载地址</span></td>
                </tr>
                <tr>
                    <td><span>voucherUrl</span></td>
                    <td><span>String</span></td>
                    <td><span>成功状态下，取证证书下载地址</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h4 id='示例-28'><span>示例</span></h4>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation" style="">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPID &nbsp; </span></span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appId</span> <span class="cm-operator">=</span> <span class="cm-string">"200515000110001"</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPKEY</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appKey</span> <span class="cm-operator">=</span> <span class="cm-string">"0e4bce1b0ef8471fb9140b849e776f48"</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZxlConnectionConfig</span> <span class="cm-variable">config</span> <span class="cm-operator">=</span> <span class="cm-variable">ZxlConnectionConfig</span>.<span class="cm-variable">builder</span>()</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">host</span>(<span class="cm-string">"https://sdk.zxinchain.com"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">build</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 实例化sdk对象</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZXLSDK</span> <span class="cm-variable">sdk</span> <span class="cm-operator">=</span> <span class="cm-keyword">new</span> <span class="cm-variable">ZXLSDK</span>(<span class="cm-variable">appId</span>,<span class="cm-variable">appKey</span>,<span class="cm-variable">config</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 获取取证证书及任务状态结果 &gt;&gt; 当前结果中返回：1、证书任务状态|2、取证证据下载地址|3、取证证书下载地址</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 其中只有当任务状态为1(即成功)时,才会有对应的证据/证书下载地址</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">EvIdData</span> <span class="cm-variable">evIdData</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">getEvidenceStatus</span>(<span class="cm-variable">orderNo</span>);</span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 286px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 286px;"></div>
    </div>
    </div>
    </pre>
    <p>&nbsp;</p>
    <h2 id='代理商模式视频取证服务'><span>代理商模式视频取证服务</span></h2>
    <h3 id='代理商模式视频取证'><span>代理商模式视频取证</span></h3>
    <h4 id='方法原型-26'><span>方法原型</span></h4>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-def">evidenceObtainVideo</span>(<span class="cm-variable-3">String</span> <span class="cm-variable">webUrls</span>, <span class="cm-variable-3">String</span> <span class="cm-variable">title</span>, <span class="cm-variable-3">String</span> <span class="cm-variable">remark</span>, <span class="cm-variable-3">String</span> <span class="cm-variable">representAppId</span>, <span class="cm-variable-3">int</span> <span class="cm-variable">duration</span>)</span></pre>
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 44px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 44px;"></div>
    </div>
    </div>
    </pre>
    <h4 id='参数说明-27'><span>参数说明</span></h4>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>webUrls</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>取证地址</span></td>
                </tr>
                <tr>
                    <td><span>title</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>证据标题</span></td>
                </tr>
                <tr>
                    <td><span>remark</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>否(可为null)</span></td>
                    <td><span>证据备注</span></td>
                </tr>
                <tr>
                    <td><span>representAppId</span></td>
                    <td><span>string</span></td>
                    <td><span>无</span></td>
                    <td><span>否</span></td>
                    <td><span>代理用户 AppId（当代理商本身发起的取证，该字段传 null）</span></td>
                </tr>
                <tr>
                    <td><span>duration</span></td>
                    <td><span>int</span></td>
                    <td><span>无</span></td>
                    <td><span>否(可为null)</span></td>
                    <td><span>录屏时长 单位:秒</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h4 id='返回数据-25'><span>返回数据</span></h4>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>string</span></td>
                    <td><span>String</span></td>
                    <td><span>订单号,根据订单号可查询取证结果</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h4 id='示例-29'><span>示例</span></h4>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java" style="page-break-inside: unset;"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation" style="">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPID &nbsp; </span></span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appId</span> <span class="cm-operator">=</span> <span class="cm-string">""</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 代理用户 APPID</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">representAppId</span> <span class="cm-operator">=</span> <span class="cm-string">""</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPKEY</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appKey</span> <span class="cm-operator">=</span> <span class="cm-string">""</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">webUrls</span> <span class="cm-operator">=</span> <span class="cm-string">""</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">title</span> <span class="cm-operator">=</span> <span class="cm-string">""</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">reamarks</span> <span class="cm-operator">=</span> <span class="cm-string">""</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">int</span> <span class="cm-variable">duration</span> <span class="cm-operator">=</span> <span class="cm-number">100</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZxlConnectionConfig</span> <span class="cm-variable">config</span> <span class="cm-operator">=</span> <span class="cm-variable">ZxlConnectionConfig</span>.<span class="cm-variable">builder</span>()</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">host</span>(<span class="cm-string">"https://sdk.zxinchain.com"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">build</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 实例化sdk对象</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZXLSDK</span> <span class="cm-variable">sdk</span> <span class="cm-operator">=</span> <span class="cm-keyword">new</span> <span class="cm-variable">ZXLSDK</span>(<span class="cm-variable">appId</span>,<span class="cm-variable">appKey</span>,<span class="cm-variable">config</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 视频取证</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">orderNo</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">evidenceObtainVideo</span>(<span class="cm-variable">webUrls</span>, <span class="cm-variable">title</span>, <span class="cm-variable">remark</span>, <span class="cm-variable">representAppId</span>, <span class="cm-variable">duration</span>);</span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 396px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 396px;"></div>
    </div>
    </div>
    </pre>
    <p>&nbsp;</p>
    <h3 id='获取取证任务状态及结果-4'><span>获取取证任务状态及结果</span></h3>
    <p><span>用户根据 orderNo 查询之前调用取证接口的结果</span></p>
    <h4 id='方法原型-27'><span>方法原型</span></h4>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">EvIdData</span> <span class="cm-def">getEvidenceStatus</span>(<span class="cm-variable-3">String</span> <span class="cm-variable">orderNo</span>)</span></pre>
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 22px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 22px;"></div>
    </div>
    </div>
    </pre>
    <h4 id='参数说明-28'><span>参数说明</span></h4>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><em><span>参数名</span></em></th>
                    <th><em><span>参数类型</span></em></th>
                    <th><em><span>默认值</span></em></th>
                    <th><em><span>是否必填</span></em></th>
                    <th><em><span>参数描述</span></em></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>orderNo</span></td>
                    <td><span>String</span></td>
                    <td><span>无</span></td>
                    <td><span>是</span></td>
                    <td><span>订单编号(由视频/图片取证产生)</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h4 id='返回数据-26'><span>返回数据</span></h4>
    <p><span>返回</span><code>EvIdData</code><span>对象，对象说明如下：</span></p>
    <figure>
        <table>
            <thead>
                <tr>
                    <th><span>字段</span></th>
                    <th><span>类型</span></th>
                    <th><span>描述</span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span>status</span></td>
                    <td><span>int</span></td>
                    <td><span>当前任务状态[0:执行中&gt;&gt;2成功&gt;&gt;10失败]</span></td>
                </tr>
                <tr>
                    <td><span>evidUrl</span></td>
                    <td><span>String</span></td>
                    <td><span>成功状态下，取证证据下载地址</span></td>
                </tr>
                <tr>
                    <td><span>voucherUrl</span></td>
                    <td><span>String</span></td>
                    <td><span>成功状态下，取证证书下载地址</span></td>
                </tr>
            </tbody>
        </table>
    </figure>
    <h4 id='示例-30'><span>示例</span></h4>
    <pre class="md-fences md-end-block ty-contain-cm modeLoaded" spellcheck="false" lang="java"><div class="CodeMirror cm-s-inner cm-s-native-scroll CodeMirror-wrap" lang="java"><div style="overflow: hidden; position: relative; width: 3px; height: 0px; top: 9px; left: 8px;"><textarea autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="0" style="position: absolute; bottom: -1em; padding: 0px; width: 1000px; height: 1em; outline: none;"></textarea></div><div class="CodeMirror-scrollbar-filler" cm-not-content="true"></div><div class="CodeMirror-gutter-filler" cm-not-content="true"></div><div class="CodeMirror-scroll" tabindex="-1"><div class="CodeMirror-sizer" style="margin-left: 0px; margin-bottom: 0px; border-right-width: 0px; padding-right: 0px; padding-bottom: 0px;"><div style="position: relative; top: 0px;"><div class="CodeMirror-lines" role="presentation"><div role="presentation" style="position: relative; outline: none;"><div class="CodeMirror-measure"><pre><span>xxxxxxxxxx</span></pre>
    </div>
    <div class="CodeMirror-measure"></div>
    <div style="position: relative; z-index: 1;"></div>
    <div class="CodeMirror-code" role="presentation" style="">
        <div class="CodeMirror-activeline" style="position: relative;">
            <div class="CodeMirror-activeline-background CodeMirror-linebackground"></div>
            <div class="CodeMirror-gutter-background CodeMirror-activeline-gutter" style="left: 0px; width: 0px;"></div>
            <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPID &nbsp; </span></span></pre>
        </div>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appId</span> <span class="cm-operator">=</span> <span class="cm-string">""</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 企业APPKEY</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable-3">String</span> <span class="cm-variable">appKey</span> <span class="cm-operator">=</span> <span class="cm-string">""</span>;</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZxlConnectionConfig</span> <span class="cm-variable">config</span> <span class="cm-operator">=</span> <span class="cm-variable">ZxlConnectionConfig</span>.<span class="cm-variable">builder</span>()</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"> &nbsp;  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">host</span>(<span class="cm-string">"https://sdk.zxinchain.com"</span>)</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-tab" role="presentation" cm-text="	">  </span>  <span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span><span class="cm-tab" role="presentation" cm-text="	">  </span>.<span class="cm-variable">build</span>();</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 实例化sdk对象</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">ZXLSDK</span> <span class="cm-variable">sdk</span> <span class="cm-operator">=</span> <span class="cm-keyword">new</span> <span class="cm-variable">ZXLSDK</span>(<span class="cm-variable">appId</span>,<span class="cm-variable">appKey</span>,<span class="cm-variable">config</span>);</span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 获取取证证书及任务状态结果 &gt;&gt; 当前结果中返回：1、证书任务状态|2、取证证据下载地址|3、取证证书下载地址</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-comment">// 其中只有当任务状态为1(即成功)时,才会有对应的证据/证书下载地址</span></span></pre>
        <pre class=" CodeMirror-line " role="presentation"><span role="presentation" style="padding-right: 0.1px;"><span class="cm-variable">EvIdData</span> <span class="cm-variable">evIdData</span> <span class="cm-operator">=</span> <span class="cm-variable">sdk</span>.<span class="cm-variable">getEvidenceStatus</span>(<span class="cm-variable">orderNo</span>);</span></pre>
    </div>
    </div>
    </div>
    </div>
    </div>
    <div style="position: absolute; height: 0px; width: 1px; border-bottom-width: 0px; border-bottom-style: solid; border-bottom-color: transparent; top: 286px;"></div>
    <div class="CodeMirror-gutters" style="display: none; height: 286px;"></div>
    </div>
    </div>
    </pre>
    <p>&nbsp;</p>
    </div>
    </div>
    </div>
</template>

<script>
export default {
    props: {
        navMenuArr: {
            type: Array
        }
    },
    components: {},
    data() {
        return {
            box: '',
        }
    },
    computed: {},
    mounted() {

    },
    activated() {
        this.$nextTick(() => {
            this.box = this.$refs.searchBar
            console.log("this.box", this.box)
            this.box.onscroll = this.scrollToTop
            console.log("navMenuArr", this.navMenuArr)
        })


    },
    methods: {
        scrollToTop() {
            let that = this
            let menuAnchor = []
            // 遍历数据，找到id name
            this.navMenuArr.forEach((item, index) => {
                menuAnchor.push({
                    name: item.name,
                    id: item.id
                })
                if (item.children && item.children.length) {
                    item.children.forEach((innerItem, innerIndex) => {
                        menuAnchor.push({
                            name: innerItem.name,
                            id: innerItem.id
                        })
                    })
                }
            })
            var Height = $(window).height()
            $('.go-sdk').find('.md-header-anchor').each(function(ind) {
                var Top = $(this).offset().top + 100 //元素距离顶部距离
                var scroll = $(document).scrollTop() //滚动高度
                if (Top - Height - scroll <= 0) {
                    let _name = $(this).attr("name")
                    let findArr = menuAnchor.filter((item) => {
                        return item.name == _name
                    })
                    if (findArr.length > 0) {
                        that.$emit("getActiveId", findArr[0].id)
                    }
                }
            })
        }
    }
}
</script>

<style lang='scss' scoped>
html {
    overflow-x: initial !important;
}

:root {
    --bg-color: #ffffff;
    --text-color: #333333;
    --select-text-bg-color: #B5D6FC;
    --select-text-font-color: auto;
    --monospace: "Lucida Console", Consolas, "Courier", monospace;
}

html {
    font-size: 14px;
    background-color: var(--bg-color);
    color: var(--text-color);
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
}

body {
    margin: 0px;
    padding: 0px;
    height: auto;
    bottom: 0px;
    top: 0px;
    left: 0px;
    right: 0px;
    font-size: 1rem;
    line-height: 1.42857143;
    overflow-x: hidden;
    background-image: inherit;
    background-size: inherit;
    background-attachment: inherit;
    background-origin: inherit;
    background-clip: inherit;
    background-color: inherit;
    tab-size: 4;
    background-position: inherit inherit;
    background-repeat: inherit inherit;
}

iframe {
    margin: auto;
}

a.url {
    word-break: break-all;
}

a:active,
a:hover {
    outline: 0px;
}

.in-text-selection,
::selection {
    // text-shadow: none;
    // background: var(--select-text-bg-color);
    // color: var(--select-text-font-color);
}

#write {
    margin: 0px auto;
    height: auto;
    width: inherit;
    word-break: normal;
    word-wrap: break-word;
    position: relative;
    white-space: normal;
    overflow-x: visible;
    padding-top: 40px;
}

#write.first-line-indent p {
    text-indent: 2em;
}

#write.first-line-indent li p,
#write.first-line-indent p * {
    text-indent: 0px;
}

#write.first-line-indent li {
    margin-left: 2em;
}

.for-image #write {
    padding-left: 8px;
    padding-right: 8px;
}

body.typora-export {
    padding-left: 30px;
    padding-right: 30px;
}

.typora-export .footnote-line,
.typora-export li,
.typora-export p {
    white-space: pre-wrap;
}

.typora-export .task-list-item input {
    pointer-events: none;
}

@media screen and (max-width: 500px) {
    body.typora-export {
        padding-left: 0px;
        padding-right: 0px;
    }
    #write {
        padding-left: 20px;
        padding-right: 20px;
    }
    .CodeMirror-sizer {
        margin-left: 0px !important;
    }
    .CodeMirror-gutters {
        display: none !important;
    }
}

#write li>figure:last-child {
    margin-bottom: 0.5rem;
}

#write ol,
#write ul {
    position: relative;
}

img {
    max-width: 100%;
    vertical-align: middle;
}

button,
input,
select,
textarea {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-variant-caps: inherit;
    font-weight: inherit;
    font-stretch: inherit;
    line-height: inherit;
}

input[type="checkbox"],
input[type="radio"] {
    line-height: normal;
    padding: 0px;
}

*,
::after,
::before {
    box-sizing: border-box;
}

#write h1,
#write h2,
#write h3,
#write h4,
#write h5,
#write h6,
#write p,
#write pre {
    width: inherit;
}

#write h1,
#write h2,
#write h3,
#write h4,
#write h5,
#write h6,
#write p {
    position: relative;
}

p {
    line-height: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    break-after: avoid-page;
    break-inside: avoid;
    orphans: 4;
}

p {
    orphans: 4;
}

h1 {
    font-size: 2rem;
}

h2 {
    font-size: 1.8rem;
}

h3 {
    font-size: 1.6rem;
}

h4 {
    font-size: 1.4rem;
}

h5 {
    font-size: 1.2rem;
}

h6 {
    font-size: 1rem;
}

.md-math-block,
.md-rawblock,
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.hidden {
    display: none;
}

.md-blockmeta {
    color: rgb(204, 204, 204);
    font-weight: 700;
    font-style: italic;
}

a {
    cursor: pointer;
}

sup.md-footnote {
    padding: 2px 4px;
    background-color: rgba(238, 238, 238, 0.701961);
    color: rgb(85, 85, 85);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    cursor: pointer;
}

sup.md-footnote a,
sup.md-footnote a:hover {
    color: inherit;
    text-transform: inherit;
    text-decoration: inherit;
}

#write input[type="checkbox"] {
    cursor: pointer;
    width: inherit;
    height: inherit;
}

figure {
    overflow-x: auto;
    margin: 1.2em 0px;
    max-width: calc(100% + 16px);
    padding: 0px;
}

figure>table {
    margin: 0px;
}

tr {
    break-inside: avoid;
    break-after: auto;
}

thead {
    display: table-header-group;
}

table {
    border-collapse: collapse;
    border-spacing: 0px;
    width: 100%;
    overflow: auto;
    break-inside: auto;
    text-align: left;
}

table.md-table td {
    min-width: 32px;
}

.CodeMirror-gutters {
    border-right-width: 0px;
    background-color: inherit;
}

.CodeMirror-linenumber {}

.CodeMirror {
    text-align: left;
}

.CodeMirror-placeholder {
    opacity: 0.3;
}

.CodeMirror pre {
    padding: 0px 4px;
}

.CodeMirror-lines {
    padding: 0px;
}

div.hr:focus {
    cursor: none;
}

#write pre {
    white-space: pre-wrap;
}

#write.fences-no-line-wrapping pre {
    white-space: pre;
}

#write pre.ty-contain-cm {
    white-space: normal;
}

.CodeMirror-gutters {
    margin-right: 4px;
}

.md-fences {
    font-size: 0.9rem;
    display: block;
    break-inside: avoid;
    text-align: left;
    overflow: visible;
    white-space: pre;
    background-image: inherit;
    background-size: inherit;
    background-attachment: inherit;
    background-origin: inherit;
    background-clip: inherit;
    background-color: inherit;
    position: relative !important;
    background-position: inherit inherit;
    background-repeat: inherit inherit;
}

.md-diagram-panel {
    width: 100%;
    margin-top: 10px;
    text-align: center;
    padding-top: 0px;
    padding-bottom: 8px;
    overflow-x: auto;
}

#write .md-fences.mock-cm {
    white-space: pre-wrap;
}

.md-fences.md-fences-with-lineno {
    padding-left: 0px;
}

#write.fences-no-line-wrapping .md-fences.mock-cm {
    white-space: pre;
    overflow-x: auto;
}

.md-fences.mock-cm.md-fences-with-lineno {
    padding-left: 8px;
}

.CodeMirror-line,
twitterwidget {
    break-inside: avoid;
}

.footnotes {
    opacity: 0.8;
    font-size: 0.9rem;
    margin-top: 1em;
    margin-bottom: 1em;
}

.footnotes+.footnotes {
    margin-top: 0px;
}

.md-reset {
    margin: 0px;
    padding: 0px;
    border: 0px;
    outline: 0px;
    vertical-align: top;
    text-decoration: none;
    text-shadow: none;
    float: none;
    position: static;
    width: auto;
    height: auto;
    white-space: nowrap;
    cursor: inherit;
    line-height: normal;
    font-weight: 400;
    text-align: left;
    box-sizing: content-box;
    direction: ltr;
    background-position: 0px 0px;
    background-repeat: initial initial;
}

li div {
    padding-top: 0px;
}

blockquote {
    margin: 1rem 0px;
}

li .mathjax-block,
li p {
    margin: 0.5rem 0px;
}

li {
    margin: 0px;
    position: relative;
}

blockquote> :last-child {
    margin-bottom: 0px;
}

blockquote> :first-child,
li> :first-child {
    margin-top: 0px;
}

.footnotes-area {
    color: rgb(136, 136, 136);
    margin-top: 0.714rem;
    padding-bottom: 0.143rem;
    white-space: normal;
}

#write .footnote-line {
    white-space: pre-wrap;
}

@media print {
    body,
    html {
        border: 1px solid transparent;
        height: 99%;
        break-after: avoid-page;
        break-before: avoid-page;
        font-variant-ligatures: no-common-ligatures;
    }
    #write {
        margin-top: 0px;
        padding-top: 0px;
        border-color: transparent !important;
    }
    .typora-export * {
        -webkit-print-color-adjust: exact;
    }
    html.blink-to-pdf {
        font-size: 13px;
    }
    .typora-export #write {
        break-after: avoid-page;
    }
    .typora-export #write::after {
        height: 0px;
    }
    .is-mac table {
        break-inside: avoid;
    }
}

.footnote-line {
    margin-top: 0.714em;
    font-size: 0.7em;
}

a img,
img a {
    cursor: pointer;
}

pre.md-meta-block {
    font-size: 0.8rem;
    min-height: 0.8rem;
    white-space: pre-wrap;
    background-color: rgb(204, 204, 204);
    display: block;
    overflow-x: hidden;
    background-position: initial initial;
    background-repeat: initial initial;
}

p>.md-image:only-child:not(.md-img-error) img,
p>img:only-child {
    display: block;
    margin: auto;
}

#write.first-line-indent p>.md-image:only-child:not(.md-img-error) img {
    left: -2em;
    position: relative;
}

p>.md-image:only-child {
    display: inline-block;
    width: 100%;
}

#write .MathJax_Display {
    margin: 0.8em 0px 0px;
}

.md-math-block {
    width: 100%;
}

.md-math-block:not(:empty)::after {
    display: none;
}

[contenteditable="true"]:active,
[contenteditable="true"]:focus,
[contenteditable="false"]:active,
[contenteditable="false"]:focus {
    outline: 0px;
    box-shadow: none;
}

.md-task-list-item {
    position: relative;
    list-style-type: none;
}

.task-list-item.md-task-list-item {
    padding-left: 0px;
}

.md-task-list-item>input {
    position: absolute;
    top: 0px;
    left: 0px;
    margin-left: -1.2em;
    margin-top: calc(1em - 10px);
    border: none;
}

.math {
    font-size: 1rem;
}

.md-toc {
    min-height: 3.58rem;
    position: relative;
    font-size: 0.9rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.md-toc-content {
    position: relative;
    margin-left: 0px;
}

.md-toc-content::after,
.md-toc::after {
    display: none;
}

.md-toc-item {
    display: block;
    color: rgb(65, 131, 196);
}

.md-toc-item a {
    text-decoration: none;
}

.md-toc-inner:hover {
    text-decoration: underline;
}

.md-toc-inner {
    display: inline-block;
    cursor: pointer;
}

.md-toc-h1 .md-toc-inner {
    margin-left: 0px;
    font-weight: 700;
}

.md-toc-h2 .md-toc-inner {
    margin-left: 2em;
}

.md-toc-h3 .md-toc-inner {
    margin-left: 4em;
}

.md-toc-h4 .md-toc-inner {
    margin-left: 6em;
}

.md-toc-h5 .md-toc-inner {
    margin-left: 8em;
}

.md-toc-h6 .md-toc-inner {
    margin-left: 10em;
}

@media screen and (max-width: 48em) {
    .md-toc-h3 .md-toc-inner {
        margin-left: 3.5em;
    }
    .md-toc-h4 .md-toc-inner {
        margin-left: 5em;
    }
    .md-toc-h5 .md-toc-inner {
        margin-left: 6.5em;
    }
    .md-toc-h6 .md-toc-inner {
        margin-left: 8em;
    }
}

a.md-toc-inner {
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
}

.footnote-line a:not(.reversefootnote) {
    color: inherit;
}

.md-attr {
    display: none;
}

.md-fn-count::after {
    content: ".";
}

code,
pre,
samp,
tt {
    font-family: var(--monospace);
}

kbd {
    margin: 0px 0.1em;
    padding: 0.1em 0.6em;
    font-size: 0.8em;
    color: rgb(36, 39, 41);
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(173, 179, 185);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    box-shadow: rgba(12, 13, 14, 0.2) 0px 1px 0px, rgb(255, 255, 255) 0px 0px 0px 2px inset;
    white-space: nowrap;
    vertical-align: middle;
    background-position: initial initial;
    background-repeat: initial initial;
}

.md-comment {
    color: rgb(162, 127, 3);
    opacity: 0.8;
    font-family: var(--monospace);
}

code {
    text-align: left;
}

a.md-print-anchor {
    white-space: pre !important;
    border: none !important;
    display: inline-block !important;
    position: absolute !important;
    width: 1px !important;
    right: 0px !important;
    outline: 0px !important;
    text-shadow: initial !important;
    background-position: 0px 0px !important;
    background-repeat: initial initial !important;
}

.md-inline-math .MathJax_SVG .noError {
    display: none !important;
}

.html-for-mac .inline-math-svg .MathJax_SVG {
    vertical-align: 0.2px;
}

.md-math-block .MathJax_SVG_Display {
    text-align: center;
    margin: 0px;
    position: relative;
    text-indent: 0px;
    max-width: none;
    max-height: none;
    min-height: 0px;
    min-width: 100%;
    width: auto;
    overflow-y: hidden;
    display: block !important;
}

.MathJax_SVG_Display,
.md-inline-math .MathJax_SVG_Display {
    width: auto;
    margin: inherit;
    display: inline-block !important;
}

.MathJax_SVG .MJX-monospace {
    font-family: var(--monospace);
}

.MathJax_SVG .MJX-sans-serif {
    font-family: sans-serif;
}

.MathJax_SVG {
    display: inline;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    zoom: 90%;
    text-indent: 0px;
    text-align: left;
    text-transform: none;
    letter-spacing: normal;
    word-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    float: none;
    direction: ltr;
    max-width: none;
    max-height: none;
    min-width: 0px;
    min-height: 0px;
    border: 0px;
    padding: 0px;
    margin: 0px;
}

.MathJax_SVG * {
    transition: none;
}

.MathJax_SVG_Display svg {
    vertical-align: middle !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}

.os-windows.monocolor-emoji .md-emoji {
    font-family: "Segoe UI Symbol", sans-serif;
}

.md-diagram-panel>svg {
    max-width: 100%;
}

[lang="flow"] svg,
[lang="mermaid"] svg {
    max-width: 100%;
    height: auto;
}

[lang="mermaid"] .node text {
    font-size: 1rem;
}

table tr th {
    border-bottom-width: 0px;
}

video {
    max-width: 100%;
    display: block;
    margin: 0px auto;
}

iframe {
    max-width: 100%;
    width: 100%;
    border: none;
}

.highlight td,
.highlight tr {
    border: 0px;
}

svg[id^="mermaidChart"] {
    line-height: 1em;
}

mark {
    background-color: rgb(255, 255, 0);
    color: rgb(0, 0, 0);
    background-position: initial initial;
    background-repeat: initial initial;
}

.md-html-inline .md-plain,
.md-html-inline strong,
mark .md-inline-math,
mark strong {
    color: inherit;
}

mark .md-meta {
    color: rgb(0, 0, 0);
    opacity: 0.3 !important;
}

@media print {
    .typora-export h1,
    .typora-export h2,
    .typora-export h3,
    .typora-export h4,
    .typora-export h5,
    .typora-export h6 {
        break-inside: avoid;
    }
}

.CodeMirror {
    height: auto;
}

.CodeMirror.cm-s-inner {
    background-image: inherit;
    background-size: inherit;
    background-attachment: inherit;
    background-origin: inherit;
    background-clip: inherit;
    background-color: inherit;
    background-position: inherit inherit;
    background-repeat: inherit inherit;
}

.CodeMirror-scroll {
    overflow-y: hidden;
    overflow-x: auto;
    z-index: 3;
}

.CodeMirror-gutter-filler,
.CodeMirror-scrollbar-filler {
    background-color: rgb(255, 255, 255);
}

.CodeMirror-gutters {
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: rgb(221, 221, 221);
    background-image: inherit;
    background-size: inherit;
    background-attachment: inherit;
    background-origin: inherit;
    background-clip: inherit;
    background-color: inherit;
    white-space: nowrap;
    background-position: inherit inherit;
    background-repeat: inherit inherit;
}

.CodeMirror-linenumber {
    padding: 0px 3px 0px 5px;
    text-align: right;
    color: rgb(153, 153, 153);
}

.cm-s-inner .cm-keyword {
    color: rgb(119, 0, 136);
}

.cm-s-inner .cm-atom,
.cm-s-inner.cm-atom {
    color: rgb(34, 17, 153);
}

.cm-s-inner .cm-number {
    color: rgb(17, 102, 68);
}

.cm-s-inner .cm-def {
    color: rgb(0, 0, 255);
}

.cm-s-inner .cm-variable {
    color: rgb(0, 0, 0);
}

.cm-s-inner .cm-variable-2 {
    color: rgb(0, 85, 170);
}

.cm-s-inner .cm-variable-3 {
    color: rgb(0, 136, 85);
}

.cm-s-inner .cm-string {
    color: rgb(170, 17, 17);
}

.cm-s-inner .cm-property {
    color: rgb(0, 0, 0);
}

.cm-s-inner .cm-operator {
    color: rgb(152, 26, 26);
}

.cm-s-inner .cm-comment,
.cm-s-inner.cm-comment {
    color: rgb(170, 85, 0);
}

.cm-s-inner .cm-string-2 {
    color: rgb(255, 85, 0);
}

.cm-s-inner .cm-meta {
    color: rgb(85, 85, 85);
}

.cm-s-inner .cm-qualifier {
    color: rgb(85, 85, 85);
}

.cm-s-inner .cm-builtin {
    color: rgb(51, 0, 170);
}

.cm-s-inner .cm-bracket {
    color: rgb(153, 153, 119);
}

.cm-s-inner .cm-tag {
    color: rgb(17, 119, 0);
}

.cm-s-inner .cm-attribute {
    color: rgb(0, 0, 204);
}

.cm-s-inner .cm-header,
.cm-s-inner.cm-header {
    color: rgb(0, 0, 255);
}

.cm-s-inner .cm-quote,
.cm-s-inner.cm-quote {
    color: rgb(0, 153, 0);
}

.cm-s-inner .cm-hr,
.cm-s-inner.cm-hr {
    color: rgb(153, 153, 153);
}

.cm-s-inner .cm-link,
.cm-s-inner.cm-link {
    color: rgb(0, 0, 204);
}

.cm-negative {
    color: rgb(221, 68, 68);
}

.cm-positive {
    color: rgb(34, 153, 34);
}

.cm-header,
.cm-strong {
    font-weight: 700;
}

.cm-del {
    text-decoration: line-through;
}

.cm-em {
    font-style: italic;
}

.cm-link {
    text-decoration: underline;
}

.cm-error {
    color: red;
}

.cm-invalidchar {
    color: red;
}

.cm-constant {
    color: rgb(38, 139, 210);
}

.cm-defined {
    color: rgb(181, 137, 0);
}

div.CodeMirror span.CodeMirror-matchingbracket {
    color: rgb(0, 255, 0);
}

div.CodeMirror span.CodeMirror-nonmatchingbracket {
    color: rgb(255, 34, 34);
}

.cm-s-inner .CodeMirror-activeline-background {
    background-image: inherit;
    background-size: inherit;
    background-attachment: inherit;
    background-origin: inherit;
    background-clip: inherit;
    background-color: inherit;
    background-position: inherit inherit;
    background-repeat: inherit inherit;
}

.CodeMirror {
    position: relative;
    overflow: hidden;
}

.CodeMirror-scroll {
    height: 100%;
    outline: 0px;
    position: relative;
    box-sizing: content-box;
    background-image: inherit;
    background-size: inherit;
    background-attachment: inherit;
    background-origin: inherit;
    background-clip: inherit;
    background-color: inherit;
    background-position: inherit inherit;
    background-repeat: inherit inherit;
}

.CodeMirror-sizer {
    position: relative;
}

.CodeMirror-gutter-filler,
.CodeMirror-hscrollbar,
.CodeMirror-scrollbar-filler,
.CodeMirror-vscrollbar {
    position: absolute;
    z-index: 6;
    display: none;
}

.CodeMirror-vscrollbar {
    right: 0px;
    top: 0px;
    overflow: hidden;
}

.CodeMirror-hscrollbar {
    bottom: 0px;
    left: 0px;
    overflow: hidden;
}

.CodeMirror-scrollbar-filler {
    right: 0px;
    bottom: 0px;
}

.CodeMirror-gutter-filler {
    left: 0px;
    bottom: 0px;
}

.CodeMirror-gutters {
    position: absolute;
    left: 0px;
    top: 0px;
    padding-bottom: 30px;
    z-index: 3;
}

.CodeMirror-gutter {
    white-space: normal;
    height: 100%;
    box-sizing: content-box;
    padding-bottom: 30px;
    margin-bottom: -32px;
    display: inline-block;
}

.CodeMirror-gutter-wrapper {
    position: absolute;
    z-index: 4;
    border: none !important;
    background-position: 0px 0px !important;
    background-repeat: initial initial !important;
}

.CodeMirror-gutter-background {
    position: absolute;
    top: 0px;
    bottom: 0px;
    z-index: 4;
}

.CodeMirror-gutter-elt {
    position: absolute;
    cursor: default;
    z-index: 4;
}

.CodeMirror-lines {
    cursor: text;
}

.CodeMirror pre {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-width: 0px;
    font-family: inherit;
    font-size: inherit;
    margin: 0px;
    white-space: pre;
    word-wrap: normal;
    color: inherit;
    z-index: 2;
    position: relative;
    overflow: visible;
    background-position: 0px 0px;
    background-repeat: initial initial;
}

.CodeMirror-wrap pre {
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: normal;
}

.CodeMirror-code pre {
    border-right-width: 30px;
    border-right-style: solid;
    border-right-color: transparent;
    width: fit-content;
}

.CodeMirror-wrap .CodeMirror-code pre {
    border-right-style: none;
    width: auto;
}

.CodeMirror-linebackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    z-index: 0;
}

.CodeMirror-linewidget {
    position: relative;
    z-index: 2;
    overflow: auto;
}

.CodeMirror-wrap .CodeMirror-scroll {
    overflow-x: hidden;
}

.CodeMirror-measure {
    position: absolute;
    width: 100%;
    height: 0px;
    overflow: hidden;
    visibility: hidden;
}

.CodeMirror-measure pre {
    position: static;
}

.CodeMirror div.CodeMirror-cursor {
    position: absolute;
    visibility: hidden;
    border-right-style: none;
    width: 0px;
}

.CodeMirror div.CodeMirror-cursor {
    visibility: hidden;
}

.CodeMirror-focused div.CodeMirror-cursor {
    visibility: inherit;
}

.cm-searching {
    background-color: rgba(255, 255, 0, 0.4);
    background-position: initial initial;
    background-repeat: initial initial;
}

@media print {
    .CodeMirror div.CodeMirror-cursor {
        visibility: hidden;
    }
}

:root {
    --side-bar-bg-color: #fafafa;
    --control-text-color: #777;
}

@include-when-export url(https://fonts.loli.net/css?family=Open+Sans:400italic,700italic,700,400&subset=latin,latin-ext);
/* open-sans-regular - latin-ext_latin */

/* open-sans-italic - latin-ext_latin */

/* open-sans-700 - latin-ext_latin */

/* open-sans-700italic - latin-ext_latin */

html {
    font-size: 16px;
}

body {
    font-family: "Open Sans", "Clear Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: rgb(51, 51, 51);
    line-height: 1.6;
}

#write {
    max-width: 860px;
    margin: 0 auto;
    padding: 30px;
    padding-bottom: 100px;
}

@media only screen and (min-width: 1400px) {
    #write {
        max-width: 1024px;
    }
}

@media only screen and (min-width: 1800px) {
    #write {
        max-width: 1200px;
    }
}

#write>ul:first-child,
#write>ol:first-child {
    margin-top: 30px;
}

a {
    color: #4183C4;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    position: relative;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: bold;
    line-height: 1.4;
    cursor: text;
}

h1:hover a.anchor,
h2:hover a.anchor,
h3:hover a.anchor,
h4:hover a.anchor,
h5:hover a.anchor,
h6:hover a.anchor {
    text-decoration: none;
}

h1 tt,
h1 code {
    font-size: inherit;
}

h2 tt,
h2 code {
    font-size: inherit;
}

h3 tt,
h3 code {
    font-size: inherit;
}

h4 tt,
h4 code {
    font-size: inherit;
}

h5 tt,
h5 code {
    font-size: inherit;
}

h6 tt,
h6 code {
    font-size: inherit;
}

h1 {
    font-size: 2.25em;
    line-height: 1.2;
    border-bottom: 1px solid #eee;
}

h2 {
    font-size: 1.75em;
    line-height: 1.225;
    border-bottom: 1px solid #eee;
}

/*@media print {
    .typora-export h1,
    .typora-export h2 {
        border-bottom: none;
        padding-bottom: initial;
    }

    .typora-export h1::after,
    .typora-export h2::after {
        content: "";
        display: block;
        height: 100px;
        margin-top: -96px;
        border-top: 1px solid #eee;
    }
}*/

h3 {
    font-size: 1.5em;
    line-height: 1.43;
}

h4 {
    font-size: 1.25em;
}

h5 {
    font-size: 1em;
}

h6 {
    font-size: 1em;
    color: #777;
}

p,
blockquote,
ul,
ol,
dl,
table {
    margin: 0.8em 0;
}

li>ol,
li>ul {
    margin: 0 0;
}

hr {
    height: 2px;
    padding: 0;
    margin: 16px 0;
    background-color: #e7e7e7;
    border: 0 none;
    overflow: hidden;
    box-sizing: content-box;
}

li p.first {
    display: inline-block;
}

ul,
ol {
    padding-left: 30px;
}

ul:first-child,
ol:first-child {
    margin-top: 0;
}

ul:last-child,
ol:last-child {
    margin-bottom: 0;
}

blockquote {
    border-left: 4px solid #dfe2e5;
    padding: 0 15px;
    color: #777777;
}

blockquote blockquote {
    padding-right: 0;
}

table {
    padding: 0;
    word-break: initial;
}

table tr {
    border-top: 1px solid #dfe2e5;
    margin: 0;
    padding: 0;
}

table tr:nth-child(2n),
thead {
    background-color: #f8f8f8;
}

table tr th {
    font-weight: bold;
    border: 1px solid #dfe2e5;
    border-bottom: 0;
    margin: 0;
    padding: 6px 13px;
}

table tr td {
    border: 1px solid #dfe2e5;
    margin: 0;
    padding: 6px 13px;
}

table tr th:first-child,
table tr td:first-child {
    margin-top: 0;
}

table tr th:last-child,
table tr td:last-child {
    margin-bottom: 0;
}

.CodeMirror-lines {
    padding-left: 4px;
}

.code-tooltip {
    box-shadow: 0 1px 1px 0 rgba(0, 28, 36, .3);
    border-top: 1px solid #eef2f2;
}

.md-fences,
code,
tt {
    border: 1px solid #e7eaed;
    background-color: #f8f8f8;
    border-radius: 3px;
    padding: 0;
    padding: 2px 4px 0px 4px;
    font-size: 0.9em;
}

code {
    background-color: #f3f4f4;
    padding: 0 2px 0 2px;
}

.md-fences {
    margin-bottom: 15px;
    margin-top: 15px;
    padding-top: 8px;
    padding-bottom: 6px;
}

.md-task-list-item>input {
    margin-left: -1.3em;
}

@media print {
    html {
        font-size: 13px;
    }
    table,
    pre {
        page-break-inside: avoid;
    }
    pre {
        word-wrap: break-word;
    }
}

.md-fences {
    background-color: #f8f8f8;
}

#write pre.md-meta-block {
    padding: 1rem;
    font-size: 85%;
    line-height: 1.45;
    background-color: #f7f7f7;
    border: 0;
    border-radius: 3px;
    color: #777777;
    margin-top: 0 !important;
}

.mathjax-block>.code-tooltip {
    bottom: .375rem;
}

.md-mathjax-midline {
    background: #fafafa;
}

#write>h3.md-focus:before {
    left: -1.5625rem;
    top: .375rem;
}

#write>h4.md-focus:before {
    left: -1.5625rem;
    top: .285714286rem;
}

#write>h5.md-focus:before {
    left: -1.5625rem;
    top: .285714286rem;
}

#write>h6.md-focus:before {
    left: -1.5625rem;
    top: .285714286rem;
}

.md-image>.md-meta {
    /*border: 1px solid #ddd;*/
    border-radius: 3px;
    padding: 2px 0px 0px 4px;
    font-size: 0.9em;
    color: inherit;
}

.md-tag {
    color: #a7a7a7;
    opacity: 1;
}

.md-toc {
    margin-top: 20px;
    padding-bottom: 20px;
}

.sidebar-tabs {
    border-bottom: none;
}

#typora-quick-open {
    border: 1px solid #ddd;
    background-color: #f8f8f8;
}

#typora-quick-open-item {
    background-color: #FAFAFA;
    border-color: #FEFEFE #e5e5e5 #e5e5e5 #eee;
    border-style: solid;
    border-width: 1px;
}

/** focus mode */

.on-focus-mode blockquote {
    border-left-color: rgba(85, 85, 85, 0.12);
}

header,
.context-menu,
.megamenu-content,
footer {
    font-family: "Segoe UI", "Arial", sans-serif;
}

.file-node-content:hover .file-node-icon,
.file-node-content:hover .file-node-open-state {
    visibility: visible;
}

.mac-seamless-mode #typora-sidebar {
    background-color: #fafafa;
    background-color: var(--side-bar-bg-color);
}

.md-lang {
    color: #b4654d;
}

.html-for-mac .context-menu {
    --item-hover-bg-color: #E6F0FE;
}

#md-notification .btn {
    border: 0;
}

.dropdown-menu .divider {
    border-color: #e5e5e5;
}

.ty-preferences .window-content {
    background-color: #fafafa;
}

.ty-preferences .nav-group-item.active {
    color: white;
    background: #999;
}

/* go-sdk 自定义样式  go-sdk 外出嵌套一层divs */

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 16px 0px;
}

#write {
    margin: 0 auto !important;
    padding: 30px !important;
    max-width: inherit !important;
}

li p {
    margin-bottom: 8px;
}
</style>
