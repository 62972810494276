<template>
    <div class="progress">
        <div class="content">
            <p class="title">开发工具包下载</p>
            <p class="subTitle">为您提供方便快捷的开发工具包下载</p>
            <div class="progressImg">
                <a style="text-decoration: none;color: #354657;" href="/downloads/至信链公私钥生成工具.zip" download="至信链公私钥生成工具.zip">
                    <div class="step">
                        <div style="clear:both"></div>
                        <div class="img">
                            <img src="./pintu.png" alt="">
                            <p>至信链公私钥生成工具</p>
                        </div>
                        <p class="cardText">便捷解决区块链身份的密钥问题。</p>
                    </div>
                </a>
                  <a style="text-decoration: none;color: #354657;" href="/downloads/至信链批量上链工具.zip" download="至信链批量上链工具.zip">
                    <div class="step">
                        <div style="clear:both"></div>
                        <div class="img">
                            <img src="./icon_chain.png" alt="">
                            <p>批量存证客户端</p>
                        </div>
                        <p class="cardText">手动批量存证电子证据，帮助暂未开同API用户完成司法存证</p>
                    </div>
                </a>
                <a style="text-decoration: none;color: #354657;" href="/downloads/zhixin-sdk-java.zip" download="JAVA-SDK.zip">
                    <div class="step">
                        <div style="clear:both"></div>
                        <div class="img">
                            <img src="./duobianxing.png" alt="">
                            <p>JAVA-SDK</p>
                        </div>
                        <p class="cardText">JAVA-SDK,帮助开发者通过SDK便捷接入至信链司法存证服务。</p>
                    </div>
                </a>
                <!-- <a style="text-decoration: none;color: #354657;" href="/downloads/GO-SDK.zip" download="GO-SDK.zip">
                    <div class="step">
                        <div style="clear:both"></div>
                        <div class="img">
                            <div style="width:71px;height:71px;border-radius:50%;background:#0736a2;display: flex;
                     align-items: center;
                     justify-content: center;
                     margin: 0 auto;">
                                <img src="./gosdk.svg" style="height: 40px;line-height:40px;" alt="">
                            </div>
                            <p>GO-SDK</p>
                        </div>
                        <p class="cardText">GO-SDK,帮助开发者通过SDK便捷接入至信链司法存证服务。</p>
                    </div>
                </a> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {

        }
    },
    computed: {},
    mounted() {},
    methods: {

    }
}
</script>

<style lang='scss' scoped>
.progress {
    height: 100%;
    background-size: 100% 100%;
}

.content {
    min-height: calc(100% - 207px);
    min-height: -moz-calc(100% - 207px);
    min-height: -webkit-calc(100% - 207px);
}

.title {
    width: 100%;
    text-align: center;
    font-size: 26px;
    color: #233349;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    /* margin-top: 86px; */
    margin-bottom: 0;
}

.subTitle {
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #233349;
    font-weight: 100;
    margin-top: 18px;
    margin-bottom: 0;
}

.progressImg {
    height: 340px;
    width: 780px;
    margin: 68px auto 0;
    display: flex;
    justify-content: space-between;
}

.step {
    width: 240px;
    height: 340px;
    padding-top: 1px;
    background: #fff;
    box-shadow: 0px 2px 30px 0px rgba(181, 183, 194, 0.16);
}

.circle {
    float: right;
    width: 27px;
    height: 27px;
    border-radius: 27px;
    background: #d6ddf1;
    color: #6682d1;
    line-height: 27px;
    text-align: center;
    margin: 13px 13px 0 0;
}

.img {
    text-align: center;
    margin-top: 60px;
}

img {
    height: 71px;
}

p {
    font-size: 18px;
}

.cardText {
    padding: 0 24px;
    margin: 32px 0 0 0;
    color: #354657;
    font-size: 16px;
    text-align: center;
}

a {
    color: #4066d0;
}

.arrow {
    width: 100px;
    height: 340px;
}
</style>
