<template>
  <div class="accessDocuments">
    <!-- <Breadcrumb :breadcrumbdata="breadcrumbdata"></Breadcrumb> -->
    <div class="conent">

    <div class="el-menu-vertical-demo">
      <div class="tiip">
         <span>接入语言</span>
         <span class="tip-btn" :class="this.curDocumentType == 'JAVA' ? 'tab-active' : ''" @click="changeTab('JAVA')">JAVA</span>
         <span class="tip-btn"  :class="this.curDocumentType == 'GO' ? 'tab-active' : ''" @click="changeTab('GO')">GO</span>
      </div>
      <el-menu
        :default-active="activeId"
        :unique-opened="true"
      >
        <el-submenu v-for="item in menuArr" :key="item.id" :index="item.id">
          <template slot="title">
            <i class="iconfont" :class="item.icon"></i>
            <span>{{ item.name }}</span>
          </template>
          <template v-if="item.children && item.children.length > 0">
            <el-menu-item
              v-for="subset in item.children"
              :key="subset.id"
              :index="subset.id"
              @click="selectDocument(subset)"
              >

              <a v-if="!subset.path" class="menu-a" :href="`#${subset.name}`">{{ subset.name }}</a>
              <span v-if="subset.path">{{ subset.name }}</span>
              </el-menu-item
            >
          </template>
        </el-submenu>
      </el-menu>
     </div>
      <div class="conent-right">
         <section class="my-secion">
          <router-link to="/">首页</router-link>
          &nbsp;/&nbsp;{{ info.title }}
          <span>
                <a v-if="curComponent == 'Guidesdk'" style="text-decoration: none;color:#1E4499;font-size:14px;margin-left:10px"
                href="/downloads/接入指南.pdf"
                download="接入指南.pdf">
                    <i class="el-icon-download"></i>下载该文档
                </a>
                 <a v-if="curComponent == 'JAVAsdk'" style="text-decoration: none;color:#1E4499;font-size:14px;margin-left:10px"
                href="/downloads/至信链JAVA-SDK接入说明.pdf"
                download="至信链JAVA-SDK接入说明.pdf">
                    <i class="el-icon-download"></i>下载该文档
                </a>
                  <a v-if="curComponent == 'GOsdk'" style="text-decoration: none;color:#1E4499;font-size:14px;margin-left:10px"
                href="/downloads/至信链GO-SDK接入说明.pdf"
                download="至信链GO-SDK接入说明.pdf">
                    <i class="el-icon-download"></i>下载该文档
                </a>
          </span>
        </section>
        <!-- <router-view></router-view> -->
          <keep-alive>
           <component :is="curComponent" :navMenuArr="componentArr" @getActiveId="getActiveId"></component>
        </keep-alive>
      </div>
    </div>
  </div>
</template>
<script>
// import Breadcrumb from "@/components/common/Breadcrumb";
import JAVAsdk from "@/views/sdk/Javasdk.vue"  // java sdk
import GOsdk from "@/views/sdk/Gosdk.vue" // go sdk
import Guidesdk from "@/views/sdk/Guide.vue" // 接入指南
import Toolsdk from "@/views/sdk/Tool.vue" // 开发工具包

export default {
  data() {
    return {
      componentArr:[], // 传递动态组件的导航
      curComponent:'Guidesdk',
      curDocumentType:'', // JAVA GO
      timer:null, // 定时器
      javaMenuArr:[], // JAVA-sdk 文档
      goMenuArr: [ // GO-sdk 文档
              {
                  id: '9',
                  name: '接入指南',
                  children: [
                    {
                      id: '9-1',
                      name: '使用手册-接入指南',
                      path:'/accessDocuments/Guide',
                      routername:'Guide',
                    },
                  ],
              },
               {
                  id: '10',
                  name: '文档描述',
                  children: [
                    {
                      routername:'GO',
                      id: '10-1',
                      name: '文档历史'
                    },
                     {
                       routername:'GO',
                      id: '10-2',
                      name: '阅读对象'
                    },
                     {
                       routername:'GO',
                      id: '10-3',
                      name: '协议规则'
                    },
                    {
                       routername:'GO',
                      id: '10-4',
                      name: '接入流程'
                    },
                  ],
              },
              {
                  id: '11',
                  name: '鉴权绑定',
                  children: [
                    {
                      routername:'GO',
                      id: '11-1',
                      name: '获取 SDK'
                    },
                     {
                      routername:'GO',
                      id: '11-2',
                      name: '创建 SDK 实例'
                    },
                     {
                      routername:'GO',
                      id: '11-3',
                      name: '证书绑定'
                    },
                  ],
              },

               {
                  id: '12',
                  name: 'hash 存证',
                  children: [
                    {
                       routername:'GO',
                      id: '12-1',
                      name: '计算文件 HASH'
                    },
                     {
                        routername:'GO',
                      id: '12-2',
                      name: '数据上链存证'
                    },
                    {
                       routername:'GO',
                      id: '12-3',
                      name: '链上信息查询'
                    },
                  ],
              },
              //  {
              //     id: '13',
              //     name: '下发录屏/截屏任务',
              //     children: [
              //       {
              //         routername:'GO',
              //         id: '13-1',
              //         name: '下发录屏/截屏任务到取证工具服务'
              //       },
              //        {
              //         routername:'GO',
              //         id: '13-2',
              //         name: '查询录屏/截屏任务状态及结果'
              //       }
              //     ],
              // },

               {
                  id: '15',
                  name: '视频/图片取证',
                  children: [
                    {
                      routername:'GO',
                      id: '15-1',
                      name: '取证服务'
                    },
                     {
                      routername:'GO',
                      id: '15-2',
                      name: '查询取证结果'
                    }
                  ],
              },

               {
                  id: '155',
                  name: '侵权监控服务',
                  children: [
                    {
                      routername:'GO',
                      id: '155-1',
                      name: '创建侵权监控任务'
                    },
                     {
                      routername:'GO',
                      id: '155-2',
                      name: '查看监控任务结果'
                    }
                  ],
              },





                {
                  id: '17',
                  name: '确权任务',
                  children: [
                    // {
                    //   routername:'GO',
                    //   id: '17-1',
                    //   name: '代理商取证服务'
                    // },
                    //  {
                    //   routername:'GO',
                    //   id: '17-2',
                    //   name: '代理商查询取证结果'
                    // },
                      {
                      routername:'GO',
                      id: '17-3',
                      name: '发起数字版权确权请求'
                    },
                    {
                      routername:'GO',
                      id: '17-4',
                      name: '用户查询确权结果'
                    }
                  ],
               },

                {
                    id: '177',
                      name: 'Kv 服务',
                      children: [
                        {
                          id: '177-1',
                          name: 'Kv 存储',
                          routername:'GO',
                        },
                        {
                          id: '177-2',
                          name: '查询 Kv',
                          routername:'GO',
                        }
                      ],
                 },

                {
                  id: '16',
                  name: '代理商服务',
                  children: [
                    {
                      routername:'GO',
                      id: '16-1',
                      name: '法人代理用户注册'
                    },
                     {
                      routername:'GO',
                      id: '16-2',
                      name: '自然人代理用户注册'
                    },
                      {
                      routername:'GO',
                      id: '16-3',
                      name: '查询代理用户审核结果'
                    },
                     {
                      routername:'GO',
                      id: '16-4',
                      name: '上传代理用户的证书'
                    },
                      {
                      routername:'GO',
                      id: '16-5',
                      name: '更新代理用户证书'
                    },
                      {
                      routername:'GO',
                      id: '16-6',
                      name: '代理商模式下的用户存证'
                    },
                    //  {
                    //   routername:'GO',
                    //   id: '16-6',
                    //   name: '用户提交确权请求'
                    // },
                    // {
                    //   routername:'GO',
                    //   id: '16-7',
                    //   name: '用户查询确权结果'
                    // },

                  ],
              },

                 {
                  id: '18',
                  name: '代理商模式下视频/图片取证',
                  children: [
                      {
                      routername:'GO',
                      id: '18-1',
                      name: '代理商取证服务'
                    },
                    {
                      routername:'GO',
                      id: '18-2',
                      name: '代理商查询取证结果'
                    }
                  ],
               },




               {
                id: '14',
                name: '开发工具下载',
                children: [
                  {
                    id: '14-1',
                    name: '开发工具包',
                    routername:'Tool',
                    //  path:'/accessDocuments/Tool'
                  },
                ],
              },
      ],

    // 菜单数组 默认是JAVA-SDK
      menuArr: [
             {
                id: '1',
                name: '接入指南',
                children: [
                  {
                    id: '1-1',
                    name: '使用手册-接入指南',
                    routername:'Guide',
                  },
                ],
             },
             {
                id: '2',
                name: '接口文档介绍',
                children: [
                  {
                    id: '2-1',
                    name: '文档概述',
                    routername:'JAVA',
                  },
                   {
                    id: '2-2',
                    name: '变更历史',
                    routername:'JAVA',
                  },
                   {
                    id: '2-3',
                    name: '接入流程',
                    routername:'JAVA',
                  },
                ],
             },
              {
                id: '3',
                name: '鉴权绑定',
                children: [
                  {
                    id: '3-1',
                    name: '创建ZXLSDK对象',
                    routername:'JAVA',
                  },
                  {
                    id:'3-2',
                    name:'生成公私钥对',
                    routername:'JAVA',
                  },
                   {
                    id: '3-3',
                    name: '证书绑定',
                    routername:'JAVA',
                  },
                  {
                    id:'3-4',
                    name:'证书更新',
                    routername:'JAVA',
                  }
                ]
             },

             {
                id: '4',
                name: 'hash存证',
                children: [
                  {
                    id: '4-1',
                    name: '文件hash计算',
                    routername:'JAVA',
                  },
                   {
                    id: '4-2',
                    name: '数据上链存证',
                    routername:'JAVA',
                  },
                   {
                    id: '4-3',
                    name: '链上信息查询',
                    routername:'JAVA',
                  }
                ],
             },

              {
                id: '5',
                name: '取证服务',
                children: [
                  {
                    id: '5-1',
                    name: '取证服务流程',
                    routername:'JAVA',
                  },
                   {
                    id: '5-2',
                    name: '截图取证服务',
                    routername:'JAVA',
                  },
                  {
                    id: '5-3',
                    name: '视频取证服务',
                    routername:'JAVA',
                  }
                ],
             },

             {
                id: '6',
                name: '侵权监控服务',
                children: [
                  {
                    id: '6-1',
                    name: '创建侵权监控任务',
                    routername:'JAVA',
                  },
                   {
                    id: '6-2',
                    name: '查看监控任务结果',
                    routername:'JAVA',
                  },
                  {
                    id:'6-3',
                    name:'创建侵权监控任务（新版）',
                    routername:'JAVA',
                  },
                   {
                    id:'6-4',
                    name:'查看监控任务结果（新版）',
                    routername:'JAVA',
                  }
                ],
             },
             {
                id: '62',
                name: 'Kv 服务',
                children: [
                  {
                    id: '62-1',
                    name: 'Kv 存储',
                    routername:'JAVA',
                  },
                   {
                    id: '62-2',
                    name: '查询 Kv',
                    routername:'JAVA',
                  }
                ],
             },

             {
                id: '7',
                name: '确权任务',
                children: [
                  {
                    id: '7-1',
                    name: '发起数字版权确权请求',
                    routername:'JAVA',
                  },
                   {
                    id: '7-2',
                    name: '用户查询确权结果',
                    routername:'JAVA',
                  }
                ],
             },
              {
                id: '9',
                name: '代理商服务',
                children: [
                  {
                    id: '9-1',
                    name: '法人代理用户注册',
                    routername:'JAVA',
                  },
                   {
                    id: '9-2',
                    name: '自然人代理用户注册',
                    routername:'JAVA',
                  },
                   {
                    id: '9-3',
                    name: '查询代理用户审核结果',
                    routername:'JAVA',
                  },
                   {
                    id: '9-4',
                    name: '上传代理用户的证书',
                    routername:'JAVA',
                  },
                   {
                    id: '9-5',
                    name: '更新代理用户证书',
                    routername:'JAVA',
                   },
                    {
                    id: '9-6',
                    name: '代理商模式用户存证',
                    routername:'JAVA',
                   }
                ],
             },
              {
                id: '91',
                name: '代理商模式视频取证服务',
                children: [
                  {
                    id: '91-1',
                    name: '代理商模式视频取证',
                    routername:'JAVA',
                  },
                   {
                    id: '91-2',
                    name: '获取取证任务状态及结果',
                    routername:'JAVA',
                  }
                ],
             },
             {
                id: '8',
                name: '开发工具下载',
                children: [
                  {
                    id: '8-1',
                    name: '开发工具包',
                    routername:'Tool',
                    //  path:'/accessDocuments/Tool'
                  },
                ],
              },
          ],
      // 路由是否带参数
      menuId: this.$route.query.menuId ? this.$route.query.menuId : '',
      // 默认选中菜单项
      activeId: '',
      // 文档数组
      conentArr: [
        {
          id: '1-1',
          title: '使用手册-接入指南',
          url: '/接入指南.html',
        },
        {
          id: '2-1',
          title: '开放平台接入说明',
          url: '/JAVA-SDK接入说明.html',
        },
        {
          id: '2-2',
          title: 'GO-SDK 接入说明',
          url: '/GO-SDK接入说明.html',
        },
        {
          id: '3-1',
          title: '开发工具包',
          url: '/开发工具包.html',
        },
      ],
      // 当前展示文档
      info: {
        title: '使用手册-接入指南',
        content: '',
        url: '',
        id: '',
        newPage: false,
      },
    }
  },
  components: {
    // Breadcrumb,
    JAVAsdk,
    GOsdk,
    Guidesdk,
    Toolsdk
    },
  mounted() {
    this.activeId = this.menuId ? this.menuId : this.menuArr[0].children[0].id
    // 默认有参数时，根据参数查询文档
    this.javaMenuArr = JSON.parse(JSON.stringify(this.menuArr))
     this.componentArr = this.menuArr
  },
   beforeDestroy() {
        //清除定时器
        clearInterval(this.timer)
    },
  methods: {
     // 动态改变激活状态
    getActiveId(id){
      this.activeId = id
    },
      // 切换语言
      changeTab(type){
        this.curDocumentType = type
        // 根据点击的不同显示不同的菜单
        const menuObj = {
          'JAVA':this.javaMenuArr,
           'GO': this.goMenuArr,
        }

       if(type == 'JAVA'){
          this.componentArr = this.menuArr
        }else if(type == 'GO'){
            this.componentArr = this.goMenuArr
        }

      this.menuArr = menuObj[type]
      this.curComponent = `${type}sdk`
      this.info.title = `${type}-SDK 接入说明`
    },

     reinitIframe(){
            var iframe = document.getElementById("menuFrame")
            try{
                var bHeight = iframe.contentWindow.document.body.scrollHeight
                var dHeight = iframe.contentWindow.document.documentElement.scrollHeight
                var height = Math.min(bHeight, dHeight)
                iframe.height = height+50
            }catch (ex){}
        },
    // 点击菜单 查询对应文档
    selectDocument(subset) {
      if(subset.path){ // 是接入指南
          this.info.title = subset.name
      }
      if(subset.routername){
        this.info.title = `${subset.routername}-SDK 接入说明`
        this.curDocumentType = subset.routername
        if(subset.routername == 'Guide' || subset.routername == 'Tool'){
          this.info.title = subset.name
        }
      }

       this.curComponent = `${subset.routername}sdk`
    },
    downFile(info) {
      // 链接的
      let url
      switch (info.id) {
        case '1-1':
          url =
            window.location.protocol +
            '//' +
            window.location.host +
            '/downloads/接入指南.pdf'
          break
        case '2-1':
          url =
            window.location.protocol +
            '//' +
            window.location.host +
            '/downloads/至信链JAVA-SDK接入说明.pdf'
          break
        case '2-2':
          url =
            window.location.protocol +
            '//' +
            window.location.host +
            '/downloads/至信链GO-SDK接入说明.pdf'
          break
        default:
          url =
            window.location.protocol +
            '//' +
            window.location.host +
            '/downloads/至信链接入说明.pdf'
          break
      }
      window.open(url, '_blank')
    },
    downFile2(content, filename) {
      // 流的
      // 创建隐藏的可下载链接
      var eleLink = document.createElement('a')
      eleLink.download = filename
      eleLink.style.display = 'none'
      // 字符内容转变成blob地址
      var blob = new Blob([content])
      eleLink.href = URL.createObjectURL(blob)
      // 触发点击
      document.body.appendChild(eleLink)
      eleLink.click()
      // 然后移除
      document.body.removeChild(eleLink)
    },
  },
}
</script>

<style lang="scss" scoped>
.accessDocuments {
  color: #24344d;
  overflow: hidden;
  background-color: #eff1f4;
  .nav {
    background-color: #182d69;
  }
  .conent {
    // padding: 75px 90px 15px;
    display: flex;
    height: calc(100vh - 100px);
    .el-menu-vertical-demo {
      width: 20%;
      min-width: 255px;
      border-right: none;
       ::v-deep .el-menu{
        max-height: calc(100vh - 156px);
        overflow: auto
      }

       ::v-deep .el-menu--inline{
         overflow: hidden;
        }

       ::v-deep .el-submenu {
         border-right: 1px solid #eceff3;
        .el-submenu__title {
          font-size: 16px;
          color: #24344d;
          font-weight: bold;
          height: 45px;
          line-height: 45px;
           background-color: #e9eef7 !important;
          .iconfont {
            margin-right: 10px;
            font-size: 16px;
            color: #25344d;
          }
          .el-icon-arrow-down:before {
            font-size: 150%;
            color: #8a99b1;
            content: "\e790";
          }
        }
        .el-menu-item:hover,
        .el-menu-item.is-active {
          color: #24344d;
          background: rgba(247, 248, 250, 1);
         // font-weight: bold;
        }
        .el-menu {
          border-bottom: 1px solid #eceff3;
        }
        .el-submenu__title {
          font-size: 16px;
          color: #24344d;
          font-weight: bold;
          height: 45px;
          line-height: 45px;
          .iconfont {
            margin-right: 10px;
            font-size: 16px;
            color: #25344d;
          }
          .el-icon-arrow-down:before {
            font-size: 150%;
            color: #8a99b1;
            content: '\e790';
          }
        }
        .el-menu-item:hover,
        .el-menu-item.is-active {
          color: #24344d;
          background: rgba(247, 248, 250, 1);
        }
      }
    }
    .tiip{
      font-size: 18px;
      color: #24344d;
      margin-bottom: 10px;
      background: #fff;
      padding: 10px;
      color: #24344d;
      background: #f7f8fa;
      .tip-btn{
            border: 1px solid #ddd;
            cursor: pointer;
            margin-left: 10px;
            padding: 3px 10px;
            border-radius: 4px;
            height: 40px;
             background: #fff;
            font-size: 14px;
      }
      .tab-active{
          background: linear-gradient(to right, #3969bd 0%, #0041be 100%);
          color: #ffffff;
          border: none;
      }
    }
    .conent-right {
      flex: 1;
      width: 80%;
      min-height: calc(100vh - 200px);
      box-sizing: border-box;
      background-color: #fff;
      margin-left: 15px;
      > section {
        a {
          color: #24344d;
          text-decoration: none;
        }
        font-size: 14px;
        height: 45px;
        line-height: 45px;
        padding-left: 25px;
        background: rgba(247, 248, 250, 1);
      }
      > div {
        height: calc(100vh - 207px);
        overflow-y: scroll;
        .title {
          height: 80px;
          line-height: 80px;
          font-size: 18px;
          font-weight: bold;
          text-align: center;
        }
        font-size: 14px;
        line-height: 30px;
        // padding: 0 33px 33px;
        pre {
          white-space: pre-wrap;
          word-wrap: break-word;
          text-align: center;
          img {
            width: 100%;
          }
        }
        .el-button.el-button--text {
          color: #395bbd;
        }
      }
    }
  }
}
.menu-a{
  text-decoration:none;
  color: #24344d;
  display: inline-block;
  width: 100%;
}
.my-secion{

}
</style>

<style>
/* java-sdk 自定义样式 */
 h1,h2,h3,h4,h5,h6{
    margin: 16px 0px;
}

/* ::selection 在代码中注释掉 */

/* go-sdk 自定义样式  go-sdk 外出嵌套一层divs */
h1,h2,h3,h4,h5,h6 {
    margin: 16px 0px;
}
#write{
    margin: 0 auto !important;
    padding: 30px !important;
    max-width: inherit!important;
}
li p{
    margin-bottom: 8px;
}

</style>
